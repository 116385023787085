import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { http } from "../../http/http";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { handlePermissionData, handlePermissionIdsData } from '../../http/help';
const Reccuring_Preview = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [inVoicePreview, setInvoicePreview] = useState();
    console.log(inVoicePreview, "inVoicePreview")
    const [inVoicePaymentModal, setInVoicePaymentModal] = useState(false);
    const [permission, setPermission] = useState([]);
    const [permissionIds, setPermissionIds] = useState([]);
    const userID = JSON.parse(localStorage.getItem('__assist_erp'));

    // Function to check if user has the permission to add tasks
    const canEditRecurringInvoice = () => {
        const hasAddPermission = permission?.permissions?.includes("invoice_update");
        const hasFullAccess = permissionIds?.every(id => id.id !== userID?.user?.id);
        return hasAddPermission || hasFullAccess;
    };

    const senInvoiceHandle = () => {
        http(
            {
                method: "GET",
                url: `invoice/generate-invoice/${id}`,
                isSecure: true,
            },
            (res) => {
                toast.success(res?.message);
                setInVoicePaymentModal(true);
                // navigate("/admin/invoice_detail")
            },
            (err) => { },
        );
    }
    const invioceReccuringPreviewHandle = () => {
        http(
            {
                method: "GET",
                url: `invoice/reccuring/${id}/view`,
                isSecure: true,
            },
            (res) => {
                setInvoicePreview(res?.data?.invoice, "dtata");
            },
            (err) => { },
        );
    }
    useEffect(() => {
        invioceReccuringPreviewHandle();

    }, []);



    // onClick handler for the "Edit" button
    const handleEditRecurringInvoiceClick = () => {
        if (canEditRecurringInvoice()) {
            // Navigate to edit invoice page if the user has permission
            navigate(`/admin/invoice/edit_reccuring_invoice/${inVoicePreview?.id}`)
        } else {
            // Show toast message if the user does not have permission
            toast.error("You are not authorized to access the resource.");
        }
    };

    useEffect(() => {
        // Fetch permission data when the component mounts
        handlePermissionData(setPermission);
        handlePermissionIdsData(setPermissionIds)
    }, []);

    return (

        <div class="right_section font-poppins invoicing">
            <div class="row">
                <div class="col-md-8 offset-md-2 invoice-prev mt-4 bg-white rounded-4 shadow-main p-4">
                    <div class="d-flex align-items-center justify-content-between">
                        <h2 class="inv-title"> Reccuring Invoice Preview</h2>
                        <div class="prev-btns d-flex align-items-center gap-2">
                            <button class="gray-btn" onClick={handleEditRecurringInvoiceClick}>
                                <svg class="me-2" xmlns="http://www.w3.org/2000/svg" width="13.999" height="13.998" viewBox="0 0 13.999 13.998">
                                    <g id="write" transform="translate(-7.998 -8)">
                                        <path id="Path_3528" data-name="Path 3528" d="M8.3,26.037,8,28.468a.731.731,0,0,0,.728.827.718.718,0,0,0,.093-.006L11.257,29a2.317,2.317,0,0,0,1.35-.663l6.3-6.3a.292.292,0,0,0,0-.412l-3.232-3.235a.292.292,0,0,0-.412,0l-6.3,6.3a2.317,2.317,0,0,0-.663,1.35Z" transform="translate(0 -7.297)" fill="#202529" />
                                        <path id="Path_3529" data-name="Path 3529" d="M41.979,9.951,40.471,8.443a1.512,1.512,0,0,0-2.138,0L36.918,9.858a.292.292,0,0,0,0,.412L40.151,13.5a.292.292,0,0,0,.412,0l1.416-1.416A1.512,1.512,0,0,0,41.979,9.951Z" transform="translate(-20.425 0)" fill="#202529" />
                                        <path id="Path_3530" data-name="Path 3530" d="M36.874,52.583A.583.583,0,0,0,36.291,52H29.583a.583.583,0,0,0,0,1.167h6.707A.583.583,0,0,0,36.874,52.583Z" transform="translate(-14.877 -31.168)" fill="#202529" />
                                    </g>
                                </svg>
                                Edit</button>
                            <button class="add-new" onClick={senInvoiceHandle}>Send Invoice</button>
                        </div>
                    </div>

                    <div class="pdf-preview-area mt-4 mt-4 mx-auto shadow--main">
                        <table>
                            <tr>
                                <td class="py-2 px-3">
                                    <img src={require("../../../assets/images/assist-pdf.png")} />
                                </td>
                                <td class="py-2 px-3" align="right">
                                    <h4>INVOICE</h4>
                                    <h6>{inVoicePreview?.recurring_invoice?.recurring_number}</h6>
                                </td>
                            </tr>
                            <tr>
                                <td class="py-2 px-3">
                                    <h2>AssistERP</h2>
                                    <h3>{inVoicePreview?.company_profile?.address}</h3>
                                </td>
                                <td class="py-2 px-3" align="right">
                                    <p>Bill To :</p>
                                    {
                                        inVoicePreview?.client_type === "1" ? <h5>{inVoicePreview?.customer?.first_name}</h5> : <h5>{inVoicePreview?.customer?.address}</h5>

                                    }

                                    <h3>Shawna Common 8550 The Causeway Port Nola Mauritania, Brampton, Ontario, Canada</h3>
                                </td>
                            </tr>
                            <tr>
                                <td class="py-2 px-3"><h5>Due Date: {inVoicePreview?.due_date}</h5></td>
                            </tr>
                        </table>

                        <table class="item-table">
                            <thead>
                                <tr>
                                    <th>Item Name</th>
                                    <th>Item Description</th>
                                    <th>Price</th>
                                    <th>Qty.</th>
                                    <th>Discount</th>
                                    <th>Tax</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    inVoicePreview && inVoicePreview?.invoice_items?.map((itemList, index) => (
                                        <tr>
                                            {console.log(itemList, "itemList")}
                                            <td>{itemList?.name}</td>
                                            <td>{itemList?.description}</td>
                                            <td>{itemList?.price}</td>
                                            <td>{itemList?.quantity}</td>
                                            <td>{itemList?.item_discount_price}</td>
                                            <td>{itemList?.tax_price}</td>
                                            <td>${itemList?.item_total}</td>
                                        </tr>
                                    ))
                                }


                                <tr>
                                    <td class="py-2 px-0" colspan="7">
                                        <hr />
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0" align="right" colspan="5"><h5>Sub Total:</h5></td>
                                    <td class="p-0" colspan="2" align="right">${inVoicePreview?.total_price}</td>
                                </tr>
                                <tr>
                                    <td class="p-0" align="right" colspan="5"><h5>Total Tax:</h5></td>
                                    <td class="p-0" colspan="2" align="right">${inVoicePreview?.total_tax_price}</td>
                                </tr>
                                <tr>
                                    <td class="p-0" align="right" colspan="5"><h5>Discount:</h5></td>
                                    <td class="p-0" colspan="2" align="right">${inVoicePreview?.total_discount_price}</td>
                                </tr>
                                <tr>
                                    <td class="py-2 px-0" colspan="7"><hr /></td>
                                </tr>
                                <tr>
                                    <td class="p-0" align="right" colspan="5"><h2>Total:</h2></td>
                                    <td class="p-0" colspan="2" align="right"><h2>${inVoicePreview?.total_price}</h2></td>
                                </tr>
                                <tr>
                                    <td class="py-2 px-0" colspan="7"><hr /></td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="footer">
                            <table>
                                <tr>
                                    <td class="py-2 px-3">
                                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, instead of using 'Content here, content here', making it look like readable English.</p>
                                    </td>
                                </tr>
                                <tr class="bg-black">
                                    <td class="p-2">
                                        <p class="text-center text-white">Company Address | Company Email | Company Phone No.</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                </div>

            </div>

            <Modal
                show={inVoicePaymentModal}
                onHide={setInVoicePaymentModal}
                centered

                contentClassName="mx-auto"
                className="modal"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-content font-poppins px-4 py-3">
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>

                <div className="pop-form mt-2 text-center">

                    <img src={require("../../../assets/images/invoice-sent.png")} alt="" />
                    <h4 class="fs-20 mt-3 mb-2">Invoice Sent Successfully</h4>
                    <p class="fs-14 text-secondary">The invoice has been successfully dispatched to the customer via
                        email.</p>
                    <h6 class="fs-14 fw-semibold">Make Payment</h6>
                    <div class="pay-link d-flex align-items-center justify-content-center gap-2 mt-3">
                        <span class="fs-24 text-blue"></span>
                        <a onClick={() => navigate(`/admin/invoice/payment`, { state: { price: inVoicePreview?.total_price, id: inVoicePreview?.id } })} class="fs-12 text-blue lh-base">Customers will also get a make
                            <br />payment link in the invoice email.</a>
                        <span class="fs-24 text-blue"></span>
                    </div>

                </div>
            </Modal>
        </div >

    )
}
export default Reccuring_Preview;




