import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { http } from "../../http/http";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Marital_Status, Gender, Residency_Status, Client_Category, Occupation_Category, Refer_by_Client } from "../../../helpers/staticList";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const AddIndividualClient = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const [picture, setPicture] = useState(null);
    console.log(picture, "picture")
    const [IdPicture, setIdPicture] = useState(null);
    const [fileType, setFileType] = useState("");
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [customFormList, setCustomFormList] = useState([]);
    const [address, setAddress] = useState('');
    const MAX_FILE_SIZE = 100 * 1024 * 1024;

    const handleChange = (value) => {
        setAddress(value);
    };

    const handleSelect = async (value) => {
        setAddress(value);
        try {
            const results = await geocodeByAddress(value);
            if (results && results[0]) {
                const formattedAddress = results[0].formatted_address;

                // Set the selected address in the form
                setValue('office_location', formattedAddress);

            } else {
                console.warn('No results found for the selected address.');
            }
        } catch (error) {
            console.error('Error selecting place:', error?.message || 'No message', error?.stack || 'No stack trace');
        }
    };




    // const onChangePicture = (e) => {
    //     const file = e.target.files[0];

    //     if (file) {
    //         setPicture(file);
    //         const mimeType = file.type;
    //         setFileType(mimeType.split("/")[1]); // Get the file extension from MIME type
    //     } else {
    //         setPicture(null);
    //         setFileType("");
    //     }
    // };
    // const onChangeIdProof = (e) => {
    //     const file = e.target.files[0];
    //     if (file) {
    //         setIdPicture(file);
    //     } else {
    //         setIdPicture(null);
    //     }
    // };

    const onChangePicture = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > MAX_FILE_SIZE) {
                alert("Please reduce file size. The maximum allowed size is 10 MB.");
                setPicture(null); // Clear the file if it's too large
                setFileType("");
                return;
            }
            setPicture(file);
            const mimeType = file.type;
            setFileType(mimeType.split("/")[1]); // Get the file extension from MIME type
        } else {
            setPicture(null);
            setFileType("");
        }
    };

    const onChangeIdProof = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > MAX_FILE_SIZE) {
                alert("Please reduce file size. The maximum allowed size is 10 MB.");
                setIdPicture(null); // Clear the file if it's too large
                return;
            }
            setIdPicture(file);
        } else {
            setIdPicture(null);
        }
    };

    const handleCheckboxCustomForm = (event) => {
        const value = parseInt(event.target.value, 10); // Parse the value as an integer

        setSelectedOptions((prevSelectedOptions) => {
            if (prevSelectedOptions.includes(value)) {
                return prevSelectedOptions.filter((option) => option !== value);
            } else {
                return [...prevSelectedOptions, value];
            }
        });
    };

    const handleCustomFormList = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `form/listing`,
                    isSecure: true,
                },
                (res) => {
                    setCustomFormList(res?.data?.data);
                },
            )
        } catch (error) {

        }
    };
    useEffect(() => {
        handleCustomFormList();
    }, []);



    const handleIndividualSubmit = async () => {
        const formData = new FormData();
        console.log(formData, "formData")

        if (picture) {
            formData.append("profile_picture", picture);
        }
        if (IdPicture) {
            formData.append("id_proof", IdPicture);
        }
        // Append form IDs from selectedOptions state
        selectedOptions.forEach(id => {
            formData.append('form_id[]', id);
        });


        // Append other form data fields
        Object.entries(formValues).forEach(([key, value]) => {
            formData.append(key, value);
        });

        http(
            {
                method: "POST",
                url: "add-individual",
                isSecure: true,
                contentType: "multipart/form-data",
                body: formData
            },
            (res) => {
                toast.success("Individual Client Add Successfully")
                reset();
                navigate(`/admin/client/individual_profile/${res?.data[0]?.id}`);
            },
            (err) => {

            }
        );

    };

    // const handleUserList = async () => {
    //     try {
    //         http(
    //             {
    //                 method: "GET",
    //                 url: `adminusers`,
    //                 isSecure: true,
    //             },
    //             (res) => {
    //                 setUserList(res?.data);
    //             },
    //         )
    //     }
    //     catch (error) {
    //         console.error('Error fetching staff data:', error);
    //     }
    // };
    // useEffect(() => {

    //     handleUserList();
    // }, []);


    return (
        <div className="right_section font-poppins">
            <div className="individual-form-area py-3">
                <div className="row individual-head align-items-center">
                    <div className="col-md-12 my-2">
                        <h4 className="fs-20">Add Individual Client</h4>
                    </div>
                    <div className="col-md-12">
                        <div className="main-form-area p-4 mt-3 bg-white shadow-lg">
                            <form
                                onSubmit={handleSubmit(handleIndividualSubmit)}
                            >
                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <h6 className="fs-20">Basic Details</h6>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="firstname">
                                            First Name <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="first_name"
                                            id="first_name"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register("first_name", {
                                                required: true,
                                            })}
                                        />
                                        {errors.first_name?.type === "required" && (
                                            <p
                                                role="alert"
                                                className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                            >
                                                Required
                                            </p>
                                        )}
                                        {errors.first_name &&
                                            errors.first_name.type === "pattern" && (
                                                <p
                                                    role="alert"
                                                    className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                                >
                                                    At least add one alphabetic
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="firstname">
                                            Middle Name
                                        </label>
                                        <input
                                            type="text"
                                            name="middle_name"
                                            id="middle_name"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register("middle_name", {
                                            })}
                                        />
                                        {errors.middle_name?.type === "required" && (
                                            <p
                                                role="alert"
                                                className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                            >
                                                Required
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="middlename">
                                            Last Name <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="last_name"
                                            id="last_name"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register("last_name", {
                                                required: true,
                                            })}
                                        />
                                        {errors.last_name?.type === "required" && (
                                            <p
                                                role="alert"
                                                className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                            >
                                                Required
                                            </p>
                                        )}
                                        {errors.last_name &&
                                            errors.last_name.type === "pattern" && (
                                                <p
                                                    role="alert"
                                                    className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                                >
                                                    At least add one alphabetic
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="middlename">SIN </label>
                                        <input
                                            type="password"
                                            name="sin"
                                            id="sin"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            maxLength={9} // Limit to 9 digits
                                            inputMode="numeric" // Display numeric keyboard on mobile devices
                                            {...register("sin", {
                                                pattern: {
                                                    value: /^\d+$/, // Allow only numbers

                                                },
                                            })}
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Replace non-numeric characters
                                            }}
                                        />

                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label for="email">Email Address <span className="text-danger">*</span></label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register("email", {
                                                required: true,
                                                pattern: /\S+@\S+\.\S+/,
                                            })}
                                        />
                                        {errors.email?.type === "required" && (
                                            <p
                                                role="alert"
                                                className="text-danger mb-0 pt-1-fs-7 ms-1"
                                            >
                                                Required
                                            </p>
                                        )}
                                        {errors.email?.type === "pattern" && (
                                            <p
                                                role="alert"
                                                className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                            >
                                                Incorrect email format
                                            </p>
                                        )}
                                    </div>

                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="fristname">
                                            Date of Birth
                                        </label>
                                        <input
                                            type="date"
                                            data-date-format="DD-MM-YYYY" id="date_of_birth"
                                            name="date_of_birth"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register("date_of_birth", {

                                            })}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="fristname">
                                            Date of Landing
                                        </label>
                                        <input
                                            type="date"
                                            id="date_of_landing"
                                            name="date_of_landing"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register("date_of_landing", {

                                            })}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="firstname">
                                            Marital Status
                                        </label>
                                        <select
                                            name="marital_status"
                                            id="marital_status"
                                            className="form-input mt-2"
                                            {...register("marital_status", {

                                            })}
                                        >
                                            <option value=""
                                                selected
                                                disabled="disabled">Select Marital Status</option>
                                            {Marital_Status && Marital_Status.getList().map((data) => (
                                                <option key={data.key} value={data.key}>
                                                    {data.value}
                                                </option>
                                            ))}
                                        </select>

                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="fristname">
                                            Marital Status Change Date
                                        </label>
                                        <input
                                            type="date"
                                            id="marital_status_change_date"
                                            name="marital_status_change_date"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register(
                                                "marital_status_change_date", {

                                            })}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="firstname">
                                            Office Location
                                        </label>
                                        {/* <select
                                            name="office_location"
                                            id="office_location"
                                            className="form-input mt-2"
                                            {...register("office_location", {

                                            })}
                                        >
                                            <option value=""
                                                selected
                                                disabled="disabled">Select Gender</option>
                                            {Gender && Gender.getList().map((data) => (
                                                <option key={data.key} value={data.key}>
                                                    {data.value}
                                                </option>
                                            ))}
                                        </select> */}
                                        <PlacesAutocomplete
                                            value={address}
                                            onChange={handleChange}  // Handle input change here
                                            onSelect={handleSelect}  // Handle address selection here
                                            searchOptions={{
                                                componentRestrictions: { country: ['us', 'ca'] },
                                            }}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <input
                                                        {...getInputProps({
                                                            placeholder: 'Type address here...',
                                                            className: 'form-input mt-2 google-address',
                                                        })}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map((suggestion) => {
                                                            const className = suggestion.active
                                                                ? 'suggestion-item--active'
                                                                : 'suggestion-item';
                                                            return (
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className,
                                                                    })}
                                                                    key={suggestion.placeId}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="fristname">
                                            Primary Contact Person
                                        </label>
                                        <input
                                            type="number"
                                            id="primary_contact_person"
                                            name="primary_contact_person"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register(
                                                "primary_contact_person", {



                                            })}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label for="fristname">
                                            Family Head
                                        </label>
                                        <input
                                            type="text"
                                            id="family_head"
                                            name="family_head"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register("family_head", {

                                            })}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="firstname">
                                            Residency Status
                                        </label>
                                        <select
                                            name="residency_status"
                                            id="residency_status"
                                            className="form-input mt-2"
                                            {...register("residency_status", {

                                            })}
                                        >
                                            <option value=""
                                                selected
                                                disabled="disabled">Select</option>
                                            {Residency_Status && Residency_Status.getList().map((data) => (
                                                <option key={data.key} value={data.key}>
                                                    {data.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="firstname">
                                            Referred By Client/Other
                                        </label>
                                        <select
                                            name="refer_by_client"
                                            id="refer_by_client"
                                            className="form-input mt-2"
                                            {...register("refer_by_client", {

                                            })}
                                        >
                                            <option value=""
                                                selected
                                                disabled="disabled">Select</option>
                                            {Refer_by_Client && Refer_by_Client.getList().map((data) => (
                                                <option key={data.key} value={data.key}>
                                                    {data.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="firstname">
                                            Personal Account Manager
                                        </label>
                                        <select
                                            name="account_manager"
                                            id="account_manager"
                                            className="form-input mt-2"
                                            {...register("account_manager", {

                                            })}
                                        >
                                            <option
                                                value=""
                                                selected
                                                disabled="disabled"
                                            >
                                                Select
                                            </option>

                                            <option value="1">Admin admin</option>
                                            <option value="2">johantest@elarafy.com</option>
                                            <option value="3">userstafftest@elarafy.com</option>
                                            <option value="4">testskt skt</option>
                                            <option value="5">prince@savetax.com</option>
                                            <option value="6">test  staff40</option>
                                            <option value="7">Staff35 Prince (PP) </option>
                                            <option value="8">sanjeevstaff elarafy</option>
                                            <option value="9">demotest@elarafy.com</option>
                                            <option value="10">thudemostaff@elarafy.com</option>
                                            <option value="11">checkingstaff@elarafy.com</option>
                                            <option value="12">ddd@elarafy.com</option>
                                            <option value="13">dalvir@elarafy.com</option>
                                            <option value="14">testing2@gmail.com</option>

                                        </select>
                                    </div>
                                    {/* <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="firstname">
                                            Refer Staff
                                        </label>
                                        <select
                                            name="refer_by_staff"
                                            id="refer_by_staff"
                                            className="form-input mt-2"
                                            {...register("refer_by_staff", {

                                            })}
                                        >
                                            <option
                                                value=""
                                                selected
                                                disabled="disabled"
                                            >
                                                Select
                                            </option>

                                            <option value="1">johantest@elarafy.com</option>
                                            <option value="2">userstafftest@elarafy.com</option>
                                            <option value="3">testskt skt</option>
                                            <option value="4">prince@savetax.com</option>
                                            <option value="5">test  staff40</option>
                                            <option value="6">Staff35 Prince (PP) </option>
                                            <option value="7">sanjeevstaff elarafy</option>
                                            <option value="8">demotest@elarafy.com</option>
                                            <option value="9">thudemostaff@elarafy.com</option>
                                            <option value="10">checkingstaff@elarafy.com</option>
                                            <option value="11">ddd@elarafy.com</option>
                                            <option value="12">dalvir@elarafy.com</option>
                                            <option value="13">testing2@gmail.com</option>
                                            <option value="14">testing3@gmail.com</option>
                                            <option value="15">superadmin@elarafy.com</option>
                                            <option value="16">testing1@gmail.com</option>

                                        </select>
                                    </div> */}
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="firstname">
                                            Client Category
                                        </label>
                                        <select
                                            name="client_category"
                                            id="client_category"
                                            className="form-input mt-2"
                                            {...register("client_category", {

                                            })}
                                        >
                                            <option value=""
                                                selected
                                                disabled="disabled">Select</option>
                                            {Client_Category && Client_Category.getList().map((data) => (
                                                <option key={data.key} value={data.key}>
                                                    {data.value}
                                                </option>
                                            ))}

                                        </select>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="firstname">
                                            Occupation Category
                                        </label>
                                        <select
                                            name="occupation_category"
                                            id="occupation_category"
                                            className="form-input mt-2"
                                            {...register(
                                                "occupation_category", {



                                            })}
                                        >
                                            <option value=""
                                                selected
                                                disabled="disabled">Select</option>
                                            {Occupation_Category && Occupation_Category.getList().map((data) => (
                                                <option key={data.key} value={data.key}>
                                                    {data.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="fristname">
                                            Self-Employed HST #
                                        </label>
                                        <input
                                            type="text"
                                            id="self_employed_hst_no"
                                            name="self_employed_hst_no"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register(
                                                "self_employed_hst_no", {



                                            })}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="firstname">Gender</label>
                                        <select
                                            name="gender"
                                            id="gender"
                                            className="form-input mt-2"
                                            {...register("gender", {

                                            })}
                                        >
                                            <option value=""
                                                selected
                                                disabled="disabled">Select</option>
                                            {Gender && Gender.getList().map((data) => (
                                                <option key={data.key} value={data.key}>
                                                    {data.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4"></div>
                                    {/* <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="firstname">Status</label>
                                        <select
                                            name="status"
                                            id="status"
                                            className="form-input mt-2"
                                            {...register("status", {

                                            })}
                                        >
                                            <option
                                                value=""
                                                selected
                                                disabled="disabled"
                                            >
                                                Status
                                            </option>
                                            <option value="1">Active</option>
                                            <option value="2">InActive</option>
                                        </select>
                                        {errors.status?.type === "required" && (
                                            <p
                                                role="alert"
                                                className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                            >
                                                Required
                                            </p>
                                        )}
                                    </div> */}


                                    <div className="col-lg-6 mt-3 h-au">
                                        <label htmlFor="firstname">
                                            Upload documents
                                        </label>
                                        <div className="upload-input position-relative mt-2">
                                            <div className="main-wrapper">
                                                <div className="upload-main-wrapper mx-auto text-center">
                                                    <div className="upload-wrapper justify-content-center add-ind">
                                                        <input
                                                            type="file"
                                                            name="id_proof"
                                                            onChange={
                                                                onChangeIdProof
                                                            }
                                                        />
                                                        <i className="fa fa-upload me-2"></i>
                                                        <span className="file-upload-text">
                                                            Browse Files
                                                        </span>
                                                    </div>
                                                    <h4 className="fs-14 mt-2">
                                                        Drag & drop here
                                                    </h4>
                                                    <p className="fs-14 text-muted">
                                                        Supports: JPEG, JPG, PDF, XLS, DOC
                                                        <br /> Max Size:
                                                        10 Mb
                                                    </p>
                                                </div>
                                                {IdPicture && (
                                                    <div className="upload-thumb text-center mt-4 d-flex align-items-center justify-content-center">
                                                        <div className="uploaded-img py-3 px-4 rounded-4 d-flex align-items-center">
                                                            {/* Render different thumbnail based on file type */}
                                                            {IdPicture.type.includes("image") ? (
                                                                <>
                                                                    <img src={URL.createObjectURL(IdPicture)} alt="Selected Image" />
                                                                    <h6 className="ms-2">{IdPicture.name}</h6>
                                                                </>

                                                            ) : (
                                                                <div>
                                                                    {/* Render file icon for non-image files */}
                                                                    {IdPicture.type.includes("pdf") && <img src={require("../../../assets/images/PDF_file_icon.png")} />}
                                                                    {IdPicture.type.includes("docx") && <img src={require("../../../assets/images/Google_Docs_Logo_512px.png")} />}
                                                                    <h6 className="ms-2">{IdPicture.name}</h6>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 mt-3 h-au">
                                        <label htmlFor="firstname">
                                            Upload Profile Picture
                                        </label>
                                        <div className="upload-input position-relative mt-2 text-center">
                                            <div className="main-wrapper">
                                                <div className="upload-main-wrapper mx-auto text-center">
                                                    <div className="upload-wrapper text-center add-ind">
                                                        <input
                                                            type="file"
                                                            name="profile_picture"
                                                            onChange={
                                                                onChangePicture
                                                            }
                                                        />
                                                        <i className="fa fa-upload me-2"></i>
                                                        <span className="file-upload-text">
                                                            Browse Files
                                                        </span>
                                                    </div>
                                                    <h4 className="fs-14 mt-2">
                                                        Drag & drop here
                                                    </h4>
                                                    <p className="fs-14 text-muted">
                                                        Supports: JPEG,
                                                        JPG, PDF
                                                        <br /> Max Size:
                                                        10 Mb
                                                    </p>

                                                </div>
                                                {picture && (
                                                    <div className="upload-thumb text-center mt-4 d-flex align-items-center justify-content-center">
                                                        <div className="uploaded-img py-3 px-4 rounded-4 d-flex align-items-center">
                                                            {/* Render different thumbnail based on file type */}
                                                            {picture.type.includes("image") ? (
                                                                <>
                                                                    <img src={URL.createObjectURL(picture)} alt="Selected Image" />
                                                                    <h6 className="ms-2">{picture.name}</h6>
                                                                </>

                                                            ) : (
                                                                <div>
                                                                    {/* Render file icon for non-image files */}
                                                                    {picture.type.includes("pdf") && <img src={require("../../../assets/images/PDF_file_icon.png")} />}
                                                                    {picture.type.includes("docx") && <img src={require("../../../assets/images/Google_Docs_Logo_512px.png")} />}
                                                                    <h6 className="ms-2">{picture.name}</h6>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>


                                    </div>

                                    <div className=" mt-3">
                                        <label htmlFor="Required Forms" className="inv-title text-black mb-2">Required Forms</label>
                                        {customFormList && customFormList.map((option) => (
                                            <div key={option.id} className="d-flex align-items-center gap-2 mt-2">
                                                <input
                                                    type="checkbox"
                                                    value={option.id}
                                                    checked={selectedOptions.includes(option.id)}
                                                    onChange={handleCheckboxCustomForm}
                                                />
                                                <label className="text-black">{option.data[0]?.title}</label>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-md-12 individual-footer">
                                        <div className="btn-groups mt-4">
                                            {/* <button
                                                        type="button"
                                                        className="btn cancel me-2"
                                                    >
                                                        Reset
                                                    </button> */}
                                            <button
                                                type="submit"
                                                className="btn save-btn ms-2"
                                            >
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>


                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddIndividualClient;
