import React, { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { http } from "../../../http/http";
import { toast } from "react-toastify";
import { handlePermissionData, handlePermissionIdsData } from '../../../http/help';

const Permissions = () => {
    const [addperModal, setaddperModal] = useState(false);
    const [permissionsList, setPermissionsList] = useState([]);
    const [moduleName, setModuleName] = useState('');
    const [moduleList, setModuleList] = useState([])
    console.log(moduleList, "moduleList")
    const [permissions, setPermissions] = useState({
        create: "no",
        view: "no",
        update: "no",
        delete: "no"
    })
    const [permissionsData, setPermissionData] = useState(null);
    const [deletePermissionm, setDeletePermission] = useState(false);
    const [selectedPermission, setSelectedPermission] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const [editData, setEditData] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [permission, setPermission] = useState([]);
    const [permissionIds, setPermissionIds] = useState([]);
    const userID = JSON.parse(localStorage.getItem('__assist_erp'));

    // Function to check if user has 'task' related permissions or is allowed full access
    const hasPermissionViewPermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("permission_view");
        const hasTaskFullAccess = permissionIds?.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };
    // Function to check if user has 'task' related permissions or is allowed full access
    const hasPermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("permission_create");
        const hasTaskFullAccess = permissionIds?.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };


    // Function to check if user has 'task' related permissions or is allowed full access
    const hasPermissionDeletePermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("permission_delete");
        const hasTaskFullAccess = permissionIds?.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };

    // Function to check if user has 'task' related permissions or is allowed full access
    const hasPermissionEditPermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("permission_update");
        const hasTaskFullAccess = permissionIds?.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };

    const handlePermissionClick = (permission) => {
        setPermissions((prevPermissions) => ({
            ...prevPermissions,
            [permission]: prevPermissions[permission] === 'yes' ? 'no' : 'yes'
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();



        try {
            http(
                {
                    method: "POST",
                    url: `permissions/add`,
                    isSecure: true,
                    body: {
                        name: moduleName,
                        actions: permissions
                    }
                },
                (res) => {
                    setPermissionData(res);
                    setaddperModal(false);
                    handlePermissionList();
                },
            )
        }
        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };



    const handlePermissionList = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `permissions`,
                    isSecure: true,
                },
                (res) => {
                    setPermissionsList(res?.data?.data);
                },
            )
        }
        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };

    useEffect(() => {
        handlePermissionList();

    }, []);

    const handleModuleList = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `modules`,
                    isSecure: true,
                },
                (res) => {
                    setModuleList(res?.data);
                },
            )
        }
        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };



    // Function to handle permission deletion
    const handleDelete = async (name) => {
        try {
            http(
                {
                    method: "DELETE",
                    url: `permissions/${name}/delete`,  // Append the name to the URL
                    isSecure: true,
                },
                (res) => {
                    // Filter the permissionsList to remove the deleted item
                    setPermissionsList(permissionsList.filter(item => item.name !== name));
                    setDeletePermission(false);
                }
            );
        } catch (error) {
            console.error('Error deleting permission:', error);
        }
    };
    // Open delete confirmation modal
    const openDeleteModal = (name) => {

        if (!hasPermissionDeletePermission()) {  // Assuming hasDeletePermission() is a function that checks delete permissions
            toast.error("You don’t have permission to delete this item."); // Show an error message
            return; // Exit the function if permission is not granted
        }
        setSelectedPermission(name);  // Set the module name to be deleted
        setDeletePermission(true);  // Open the modal
    };

    // Function to handle permission fetching for editing
    const handleEdit = async (name) => {

        if (!hasPermissionEditPermission()) {  // Assuming hasEditPermission() is a function that checks edit permissions
            toast.error("You don’t have permission to edit this item."); // Show an error message
            return; // Exit the function if permission is not granted
        }
        try {
            await http(
                {
                    method: "GET",
                    url: `permissions/${name}/edit`,  // Append the name to the URL for the edit API
                    isSecure: true, // Assuming secure API request
                },
                (res) => {
                    setEditData(res?.data); // Set the selected permission for editing
                    setShowEditModal(true); // Show the edit modal
                    // Don't modify permissionsList, keep it as is for main listing
                }
            );
        } catch (error) {
            console.error('Error fetching permission for editing:', error);
        }
    };
    const handleEditPermissionClick = (permissionType) => {
        setEditData((prevEditData) => ({
            ...prevEditData,
            actions: {
                ...prevEditData.actions,
                [permissionType]: prevEditData.actions[permissionType] === 'yes' ? 'no' : 'yes', // Toggle between yes and no
            },
        }));
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            await http(
                {
                    method: "POST",
                    url: `permissions/update/${editData.name}`, // Assuming this is your API endpoint for updating
                    isSecure: true,
                    body: {
                        name: editData.name,
                        actions: editData.actions, // Updated actions
                    },
                },
                (res) => {
                    setShowEditModal(false); // Close the modal after save
                    handlePermissionList(); // Refresh the permission list
                }
            );
        } catch (error) {
            console.error('Error updating permissions:', error);
        }
    };
    // Search input handler
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value); // Update search term when the user types
    };

    const filteredModules = permissionsList.filter((permission) =>
        permission.name.toLowerCase().includes(searchTerm.toLowerCase()) // Case-insensitive search
    );
    const oepnPermissionModal = () => {
        if (!hasPermission()) {
            toast.error("You don’t have permission to add a permission"); // Display toast message
            return; // Exit function if the user lacks permission
        }
        handleModuleList();
        setaddperModal(true)
    }

    useEffect(() => {
        // Fetch permission data when the component mounts
        handlePermissionData(setPermission);
        handlePermissionIdsData(setPermissionIds)
    }, []);
    return (
        <>
            <div className="userdetailbox">
                <div className="leftdetbox">
                    <div className="bg-white rounded-4 shadow-main h_800 overflow-y-scroll pb-4">
                        <div className="accord-head d-flex align-items-start justify-content-between px-4 pt-4 pb-0">
                            <div>
                                <h4 className="fs-20 font-weight-bold">Permissions</h4>
                                <p className="t_blue">Users & Roles</p>
                            </div>
                            <button className="add-new" onClick={oepnPermissionModal}>
                                <i className="fa fa-plus me-2"></i>
                                Add New
                            </button>
                            {/* <button className="add-new-button"></button> */}
                        </div>
                        <div className="search-drop pt-2 px-4 pb-4 d-flex align-items-center justify-content-between flex-wrap gap-3">

                            <form class="searchuser d-flex align-items-center gap-2">
                                <input
                                    type="search"
                                    className="form-input"
                                    name="search"
                                    placeholder="Search by module name"
                                    value={searchTerm}
                                    onChange={handleSearchChange} // Handle input change
                                />
                                <i className="fa fa-search"></i>
                            </form>

                        </div>


                        {hasPermissionViewPermission() ? (
                            <div className="tableuser px-4 pb-0 table-responsive">
                                <table className="w-100">
                                    <thead className="pb-2">
                                        <tr>
                                            <th>Module</th>
                                            <th>Create</th>
                                            <th>View</th>
                                            <th>Update</th>
                                            <th>Delete</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredModules && filteredModules.map((list, index) => (
                                            <tr key={index}>
                                                <td>{list?.name}</td>
                                                <td>
                                                    {list?.actions?.create === "yes" ? (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.597" viewBox="0 0 16 15.597">
                                                            <g id="_7-Check" data-name="7-Check" transform="translate(-32.027 -37.756)">
                                                                <path id="Path_3467" data-name="Path 3467" d="M33.69,44.879A1.668,1.668,0,0,0,32.4,47.6l4.236,5.19a1.514,1.514,0,0,0,1.266.562,1.624,1.624,0,0,0,1.274-.755l8.8-14.172,0-.007a.456.456,0,0,0-.115-.536.467.467,0,0,0-.679.047l-.01.011L38.3,47.967a.385.385,0,0,1-.547.03l-2.945-2.68A1.66,1.66,0,0,0,33.69,44.879Z" transform="translate(0 0)" fill="#1b8b00" />
                                                            </g>
                                                        </svg>
                                                    ) : (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.597" viewBox="0 0 24 24">
                                                            <path fill="#e74c3c" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 13.59L16.59 17 12 12.41 7.41 17 6 15.59 10.59 11 6 6.41 7.41 5l4.59 4.59L16.59 5 18 6.41 13.41 11 18 15.59z" />
                                                        </svg>
                                                    )}
                                                </td>
                                                <td>
                                                    {list?.actions?.view === "yes" ? (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.597" viewBox="0 0 16 15.597">
                                                            <g id="_7-Check" data-name="7-Check" transform="translate(-32.027 -37.756)">
                                                                <path id="Path_3467" data-name="Path 3467" d="M33.69,44.879A1.668,1.668,0,0,0,32.4,47.6l4.236,5.19a1.514,1.514,0,0,0,1.266.562,1.624,1.624,0,0,0,1.274-.755l8.8-14.172,0-.007a.456.456,0,0,0-.115-.536.467.467,0,0,0-.679.047l-.01.011L38.3,47.967a.385.385,0,0,1-.547.03l-2.945-2.68A1.66,1.66,0,0,0,33.69,44.879Z" transform="translate(0 0)" fill="#1b8b00" />
                                                            </g>
                                                        </svg>
                                                    ) : (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.597" viewBox="0 0 24 24">
                                                            <path fill="#e74c3c" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 13.59L16.59 17 12 12.41 7.41 17 6 15.59 10.59 11 6 6.41 7.41 5l4.59 4.59L16.59 5 18 6.41 13.41 11 18 15.59z" />
                                                        </svg>
                                                    )}
                                                </td>
                                                <td>
                                                    {list?.actions?.update === "yes" ? (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.597" viewBox="0 0 16 15.597">
                                                            <g id="_7-Check" data-name="7-Check" transform="translate(-32.027 -37.756)">
                                                                <path id="Path_3467" data-name="Path 3467" d="M33.69,44.879A1.668,1.668,0,0,0,32.4,47.6l4.236,5.19a1.514,1.514,0,0,0,1.266.562,1.624,1.624,0,0,0,1.274-.755l8.8-14.172,0-.007a.456.456,0,0,0-.115-.536.467.467,0,0,0-.679.047l-.01.011L38.3,47.967a.385.385,0,0,1-.547.03l-2.945-2.68A1.66,1.66,0,0,0,33.69,44.879Z" transform="translate(0 0)" fill="#1b8b00" />
                                                            </g>
                                                        </svg>
                                                    ) : (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.597" viewBox="0 0 24 24">
                                                            <path fill="#e74c3c" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 13.59L16.59 17 12 12.41 7.41 17 6 15.59 10.59 11 6 6.41 7.41 5l4.59 4.59L16.59 5 18 6.41 13.41 11 18 15.59z" />
                                                        </svg>
                                                    )}
                                                </td>
                                                <td>
                                                    {list?.actions?.delete === "yes" ? (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.597" viewBox="0 0 16 15.597">
                                                            <g id="_7-Check" data-name="7-Check" transform="translate(-32.027 -37.756)">
                                                                <path id="Path_3467" data-name="Path 3467" d="M33.69,44.879A1.668,1.668,0,0,0,32.4,47.6l4.236,5.19a1.514,1.514,0,0,0,1.266.562,1.624,1.624,0,0,0,1.274-.755l8.8-14.172,0-.007a.456.456,0,0,0-.115-.536.467.467,0,0,0-.679.047l-.01.011L38.3,47.967a.385.385,0,0,1-.547.03l-2.945-2.68A1.66,1.66,0,0,0,33.69,44.879Z" transform="translate(0 0)" fill="#1b8b00" />
                                                            </g>
                                                        </svg>
                                                    ) : (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.597" viewBox="0 0 24 24">
                                                            <path fill="#e74c3c" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 13.59L16.59 17 12 12.41 7.41 17 6 15.59 10.59 11 6 6.41 7.41 5l4.59 4.59L16.59 5 18 6.41 13.41 11 18 15.59z" />
                                                        </svg>
                                                    )}
                                                </td>
                                                {/* Similar for view, update, delete */}
                                                <td className="d-flex align-items-center gap-2">
                                                    <div className="editicon" onClick={() => handleEdit(list.name)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.999" viewBox="0 0 14 13.999">
                                                            <g id="write" transform="translate(-7.998 -8)">
                                                                <path id="Path_3528" data-name="Path 3528" d="M8.3,26.038,8,28.469a.731.731,0,0,0,.728.827.718.718,0,0,0,.093-.006L11.257,29a2.317,2.317,0,0,0,1.35-.663l6.3-6.3a.292.292,0,0,0,0-.412l-3.232-3.235a.292.292,0,0,0-.412,0l-6.3,6.3a2.317,2.317,0,0,0-.663,1.35Z" transform="translate(0 -7.297)" fill="#202529" />
                                                                <path id="Path_3529" data-name="Path 3529" d="M41.979,9.951,40.472,8.443a1.512,1.512,0,0,0-2.138,0L36.918,9.859a.292.292,0,0,0,0,.412L40.151,13.5a.292.292,0,0,0,.412,0l1.416-1.416A1.512,1.512,0,0,0,41.979,9.951Z" transform="translate(-20.425 0)" fill="#202529" />
                                                                <path id="Path_3530" data-name="Path 3530" d="M36.875,52.583A.583.583,0,0,0,36.291,52H29.583a.583.583,0,0,0,0,1.167h6.708A.583.583,0,0,0,36.875,52.583Z" transform="translate(-14.877 -31.168)" fill="#202529" />
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    <div className="deticon" onClick={() => openDeleteModal(list.name)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12.598" height="13.998" viewBox="0 0 12.598 13.998">
                                                            <path id="Path_3741" data-name="Path 3741" d="M411.1,1337.268a.46.46,0,0,1,.193.042.5.5,0,0,1,.163.112.517.517,0,0,1,.107.168.5.5,0,0,1,.036.2v.266a.512.512,0,0,1-.144.357.491.491,0,0,1-.355.154H399.5a.49.49,0,0,1-.355-.154.51.51,0,0,1-.145-.357v-.266a.514.514,0,0,1,.143-.364.5.5,0,0,1,.163-.112.461.461,0,0,1,.193-.042h2.041a.865.865,0,0,0,.559-.2.892.892,0,0,0,.309-.5l.106-.476a1.391,1.391,0,0,1,.482-.777,1.412,1.412,0,0,1,.857-.308h2.891a1.418,1.418,0,0,1,.843.3,1.391,1.391,0,0,1,.486.749l.114.511a.9.9,0,0,0,.309.511.948.948,0,0,0,.559.2Zm-1.037,9.722c.21-1.981.586-6.7.586-6.747a.527.527,0,0,0-.023-.21.58.58,0,0,0-.1-.182.528.528,0,0,0-.166-.119.49.49,0,0,0-.2-.042h-9.708a.5.5,0,0,0-.2.042.52.52,0,0,0-.166.119.566.566,0,0,0-.133.392c0,.007.015.175.037.455.1,1.232.376,4.668.555,6.3a2.033,2.033,0,0,0,2.051,1.974c.879.021,1.784.028,2.71.028.872,0,1.757-.007,2.66-.028a2.017,2.017,0,0,0,1.459-.553,2.039,2.039,0,0,0,.64-1.428Z" transform="translate(-399 -1335)" fill="#202529" fill-rule="evenodd" />
                                                        </svg>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <div className="no-permission-message">
                                <p className="fs-16 text-muted">You don’t have permission to view this information.</p>
                            </div>
                        )}

                    </div>
                </div>
            </div >


            {/* MODAL START */}
            < Modal
                show={addperModal}
                onHide={() => setaddperModal(false)}
                centered
                size="md"
                contentClassName="mx-auto p-3 h-auto"
                className="modal pin-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header p-0 justify-content-between align-items-center border-0">
                    <h4 className="fs-20">Add Permission</h4>
                    <Modal.Header closeButton className="close-icon border-0 pe-2 pb-0"></Modal.Header>
                </div>
                <div className="services-form row">
                    <div className="col-lg-12 mt-3 p-0">
                        <form onSubmit={handleSubmit}>
                            <div className="border-0 justify-content-center p-0">
                                <label className="fs-18" htmlFor="module">Module</label>
                                <select
                                    name="module"
                                    className="form-input mx-0 mt-1"
                                    id="module"
                                    value={moduleName}
                                    onChange={(e) => setModuleName(e.target.value)}
                                >
                                    <option value="">Select a Module</option> {/* Default option */}
                                    {Array.isArray(moduleList) && moduleList.length > 0 ? (
                                        moduleList.map((module, index) => (
                                            <option key={index} value={module.name}>
                                                {module.name} {/* Display module name */}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="" disabled>Loading modules...</option>
                                    )}
                                </select>

                            </div>
                            <div className="border-0 justify-content-center p-0 mt-3">
                                <label className="fs-18" htmlFor="permissions">Select Permissions</label>
                                <div className="perm-boxes mt-1">
                                    <ul>
                                        <li
                                            className={permissions.create === 'yes' ? 'act' : 'deact'}
                                            onClick={() => handlePermissionClick('create')}
                                        >
                                            Create
                                        </li>
                                        <li
                                            className={permissions.view === 'yes' ? 'act' : 'deact'}
                                            onClick={() => handlePermissionClick('view')}
                                        >
                                            View
                                        </li>
                                        <li
                                            className={permissions.update === 'yes' ? 'act' : 'deact'}
                                            onClick={() => handlePermissionClick('update')}
                                        >
                                            Update
                                        </li>
                                        <li
                                            className={permissions.delete === 'yes' ? 'act' : 'deact'}
                                            onClick={() => handlePermissionClick('delete')}
                                        >
                                            Delete
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="mt-5">
                                <button type="submit" className="add-new ms-auto px-4">Add</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal >
            {/* MODAL END */}
            <Modal
                show={showEditModal}
                onHide={() => setShowEditModal(false)}
                centered
                size="md"
                contentClassName="mx-auto p-3 h-auto"
                className="modal pin-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header p-0 justify-content-between align-items-center border-0">
                    <h4 className="fs-20">Edit Permission</h4>
                    <Modal.Header closeButton className="close-icon border-0 pe-2 pb-0"></Modal.Header>
                </div>
                <div className="services-form row">
                    <div className="col-lg-12 mt-3 p-0">
                        <form
                            onSubmit={handleEditSubmit}
                        >
                            <div className="border-0 justify-content-center p-0">
                                <label className="fs-18" htmlFor="module">Module</label>
                                <select
                                    name="module"
                                    className="form-input mx-0 mt-1"
                                    id="module"
                                    value={editData?.name || ''} // Bind the selected module name
                                    onChange={(e) => setEditData({ ...editData, name: e.target.value })} // Update the module name in editData
                                >
                                    <option value="">Select a Module</option> {/* Default option */}
                                    {Array.isArray(moduleList) && moduleList.length > 0 ? ( // Check if moduleList is defined and not empty
                                        moduleList.map((module, index) => (
                                            <option key={index} value={module.name}>
                                                {module.name}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="" disabled>Loading modules...</option> // Fallback if moduleList is empty or loading
                                    )}
                                </select>

                            </div>
                            <div className="border-0 justify-content-center p-0 mt-3">
                                <label className="fs-18" htmlFor="permissions">Select Permissions</label>
                                <div className="perm-boxes mt-1">
                                    <ul>
                                        {editData?.actions && (
                                            Object.keys(editData.actions).map((action, index) => (
                                                <li
                                                    key={index}
                                                    className={`permission-item ${editData.actions[action] === 'yes' ? 'act' : 'deact'}`}
                                                    onClick={() => handleEditPermissionClick(action)} // Allow editing the permission values
                                                >
                                                    <span className="permission-label">
                                                        {action.charAt(0).toUpperCase() + action.slice(1)} {/* Capitalize the action name */}
                                                    </span>
                                                </li>
                                            ))
                                        )}
                                    </ul>
                                </div>
                            </div>
                            <div className="mt-5">
                                <button type="submit" className="add-new ms-auto px-4">Save Changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
            <Modal
                show={deletePermissionm}
                onHide={() => setDeletePermission(false)}
                centered
                size="md"
                contentClassName="mx-auto p-3 h-auto"
                className="modal pin-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="services-form row">
                    <div className="col-lg-12 mt-3 p-0 text-center">
                        <h4>Delete Permission</h4>
                        <p className="mt-2">Are you sure, you want to delete this permission?</p>
                        <div className="d-flex align-items-center justify-content-center gap-2 mt-3">
                            <button
                                type="button"
                                className="add-no px-4"
                                onClick={() => setDeletePermission(false)}
                            >
                                No
                            </button>
                            <button
                                type="button"
                                className="add-yes px-4"
                                onClick={() => handleDelete(selectedPermission)}
                            >
                                Yes
                            </button>
                        </div>
                        {/* <div className="mt-5">
                            <button type="button" className="add-new ms-auto px-4" onClick={() => handleDelete(list.name)}>Add</button>
                        </div> */}

                    </div>
                </div>
            </Modal>
        </>
    )
}
export default Permissions;