import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { http } from "../../http/http";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";

const Dependent_Detail = (props) => {
    const { id } = useParams();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        watch,
        setError
    } = useForm({ mode: "all", defaultValues: { dependents: [] } });
    const formValues = watch();
    const { fetchData, showDependentModal, setShowDependentModal } = props;
    const [inputFields, setInputFields] = useState([{ id: 0 }]);
    const [usersDepend, setUsersDepend] = useState([]);

    const handleAddFields = () => {
        const newInputFields = [...inputFields, { id: inputFields[inputFields.length - 1].id + 1 }];
        setInputFields(newInputFields);
    };

    const handleRemoveFields = (id) => {
        if (id === 0) return; // Prevent deleting the field with ID 0
        const newInputFields = inputFields.filter((field) => field.id !== id);
        setInputFields(newInputFields);
    };

    const addDependent = () => {
        const dependentsData = formValues.dependents.map(dependent => ({
            first_name: dependent.first_name,
            last_name: dependent.last_name,
            middle_name: dependent.middle_name,
            sin: dependent.sin,
            date_of_birth: dependent.date_of_birth,
            relation: dependent.relation
        }));

        // Submit the dependents data to the server
        http(
            {
                method: "POST",
                url: `client/dependent/${id}/add`,
                body: dependentsData,
                isSecure: true,
            },
            (res) => {
                reset(); // Reset the form after successful submission
                fetchData();
                onHideHandler();
            },
            (err) => {
                console.error("Error Add Dependent:", err);
                // Handle server-side errors here and set errors in the form
                // For example, you could set errors based on the response
                setError("server", { type: "manual", message: "Failed to add dependents. Please try again." });
            }
        );
    };

    const onHideHandler = () => {
        setShowDependentModal(false);
    };

    return (
        <Modal
            show={showDependentModal}
            onHide={onHideHandler}
            centered
            size="xl"
            contentClassName="mx-auto"
            className="modal operating-modal font-poppins"
            backdrop="static"
            keyboard={false}
        >
            <div className="modal-header px-4 pt-3 pb-0 border-0 justify-content-between">
                <h4 className="fs-20">Add Dependent Details</h4>
                <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
            </div>
            <div className="modal-body px-3 pb-0">
                <form onSubmit={handleSubmit(addDependent)}>
                    <div className="modal-body p-0">
                        {inputFields.map((inputField, index) => (
                            <div className="spouse-form row mt-2 input_wrap" key={inputField.id}>
                                <div className="row mb-3 p-0">
                                    <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                        <label htmlFor="" className="fs-18">First Name <span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            name={`dependents[${index}].first_name`}
                                            id={`first_name${inputField.id}`}
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            {...register(`dependents[${index}].first_name`, { required: "Required", })}
                                        />
                                        {errors?.dependents?.[index]?.first_name && (
                                            <span className="error">{errors.dependents[index].first_name.message}</span>
                                        )}
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                        <label htmlFor="" className="fs-18">Middle Name</label>
                                        <input
                                            type="text"
                                            name={`dependents[${index}].middle_name`}
                                            id={`middle_name${inputField.id}`}
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            {...register(`dependents[${index}].middle_name`, {})}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                        <label htmlFor="" className="fs-18">Last Name <span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            name={`dependents[${index}].last_name`}
                                            id={`last_name${inputField.id}`}
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            {...register(`dependents[${index}].last_name`, { required: "Required", })}
                                        />
                                        {errors?.dependents?.[index]?.last_name && (
                                            <span className="error">{errors.dependents[index].last_name.message}</span>
                                        )}
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                        <label htmlFor="" className="fs-18">SIN</label>
                                        <input
                                            type="password"
                                            name={`dependents[${index}].sin`}
                                            id={`sin${inputField.id}`}
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            maxLength={9}
                                            {...register(`dependents[${index}].sin`, {
                                                pattern: {
                                                    value: /^\d+$/, // Allow only numbers

                                                },
                                            })}
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Replace non-numeric characters
                                            }}

                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                        <label htmlFor="" className="fs-18">Date of Birth</label>
                                        <input
                                            type="date"
                                            name={`dependents[${index}].date_of_birth`}
                                            id={`date_of_birth${inputField.id}`}
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            {...register(`dependents[${index}].date_of_birth`, {})}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                        <label htmlFor="" className="fs-18">Relation</label>
                                        <input
                                            type="text"
                                            name={`dependents[${index}].relation`}
                                            id={`relation${inputField.id}`}
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            {...register(`dependents[${index}].relation`, {})}
                                        />
                                    </div>
                                    <div className="col-lg-8 col-sm-12 mt-4 text-end align-content-center">
                                        {index !== 0 && (
                                            <button type="button" onClick={() => handleRemoveFields(inputField.id)} className="btn btn-danger mt-4">Remove</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                        <button type="button" onClick={handleAddFields} className="add_field_button bg-transparent border-0 ms-1 d-flex align-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17.998" viewBox="0 0 18 17.998">
                                <g id="add" transform="translate(0.002 -0.003)">
                                    <path id="Subtraction_3" data-name="Subtraction 3" d="M9,18a9,9,0,1,1,9-9A9.011,9.011,0,0,1,9,18ZM5.515,8.238a.7.7,0,0,0,0,1.394H8.3V12.42a.7.7,0,1,0,1.394,0V9.632h2.786a.7.7,0,1,0,0-1.394H9.7V5.45a.7.7,0,0,0-1.394,0V8.238Z" transform="translate(-0.002 0.003)" fill="#004fc5" />
                                </g>
                            </svg>
                            <p className="ms-1 text-blue">Add More</p>
                        </button>
                    </div>
                    <div className="modal-footer border-0 px-2 pb-4 mt-4">
                        <button type="submit" className="btn-blue px-5 py-4">Add</button>
                    </div>
                </form>

            </div>
        </Modal>

    );
};
export default Dependent_Detail;
