import React, { createContext, useContext, useState, useEffect } from "react";
import ClientTabs from "../../common/ClientTabs";
import { http } from "../../http/http";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { GlobalContext } from "../../../App";
import { handlePermissionData, handlePermissionIdsData } from '../../http/help';
const Notes = () => {
    const [activeTab, setActiveTab] = useState('all');
    const { setShowLoader } = useContext(GlobalContext);
    const [activeTabColor, setActiveTabColor] = useState({ all: 'active', pinned: '', archived: '' });
    const [openPinModal, setOpenPinModal] = useState(false);
    const [tags, setTags] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const storedId = JSON.parse(localStorage.getItem("clientId"));
    const [notesList, setNotesList] = useState([]);
    const [pinNote, setPinNote] = useState()
    const [pinId, setPinId] = useState();
    const [archiveNote, setArchiveNote] = useState();
    const [archiveId, setArchiveId] = useState();
    const [archiveModal, setArchiveModal] = useState(false);
    const [editNote, setEditNote] = useState();
    const [isEditing, setIsEditing] = useState(false);
    const [deleteTagIDs, setDeleteTagIDs] = useState([]);
    const [permission, setPermission] = useState([]);
    const [permissionIds, setPermissionIds] = useState([]);
    const userID = JSON.parse(localStorage.getItem('__assist_erp'));
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        setError, clearErrors,
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();


    // Function to check if user has the permission to add tasks
    const canAddNotes = () => {
        const hasAddPermission = permission?.permissions?.includes("note_create");
        const hasFullAccess = permissionIds?.every(id => id.id !== userID?.user?.id);
        return hasAddPermission || hasFullAccess;
    };
    const canUpdateNotes = () => {
        const hasAddPermission = permission?.permissions?.includes("note_update");
        const hasFullAccess = permissionIds?.every(id => id.id !== userID?.user?.id);
        return hasAddPermission || hasFullAccess;
    };

    // Function to check if user has 'task' related permissions or is allowed full access
    const hasNoteViewPermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("note_view");
        const hasTaskFullAccess = permissionIds?.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };


    const handleAddNotes = (data) => {
        // First, check if the user has permission to add notes
        if (!canAddNotes()) {
            toast.error("You are not authorized to access the resource")
            return;  // Exit early if the user doesn't have permission
        }

        setShowLoader(true);
        try {
            http(
                {
                    method: "POST",
                    url: `client/note/${storedId}/add`, data,
                    isSecure: true,
                    body: {
                        title: data.title,
                        description: data.description,
                        tag: data.tags,
                    },
                },
                (res) => {

                    // Refresh the notes list
                    fetchNotes();
                    toast.success(res.message);
                    setShowLoader(false);


                },
                (err) => {
                    console.error(err);
                },
            );
        } catch (err) {
            console.error(err);
        }
    }

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === ',' || event.key === ' ') {
            event.preventDefault();
            const tag = inputValue.trim();
            if (tag && !tags.includes(tag)) {
                setTags([...tags, tag]);
            }
            setInputValue('');
        }
    };

    const handleRemoveTag = (indexToRemove) => {
        // Add tag index to deleteTagIDs if it's not already included
        if (!deleteTagIDs.includes(indexToRemove)) {
            setDeleteTagIDs(prevDeleteTagIDs => [...prevDeleteTagIDs, indexToRemove]);
        }
        // Remove the tag from tags state
        setTags(prevTags => prevTags.filter((_, index) => index !== indexToRemove));
    };

    const onSubmit = (data) => {
        data.tags = tags; // Append tags to form data
        handleAddNotes(data);

        reset();
        setTags([]);
    };


    const handleInputFocus = (e) => {
        const element = e.target.closest('.bootstrap-tagsinput');
        if (element) {
            element.classList.add('has-focus');
        }
    };

    const handleInputBlur = (e) => {
        const element = e.target.closest('.bootstrap-tagsinput');
        if (element) {
            element.classList.remove('has-focus');
        }
    };



    // Add Pin API Calling -->  
    const fetchNotes = (tab) => {
        let endpoint = `client/note/${storedId}/`;
        if (tab === 'pinned') {
            endpoint += 'pinlisting';
        } else if (tab === 'archived') {
            endpoint += 'archivelisting';
        } else {
            endpoint += 'listing';
        }

        http({
            method: 'GET',
            url: endpoint,
            isSecure: true,
        },
            (res) => {

                setActiveTabColor({ all: '', pinned: '', archived: '', [tab]: 'active' });
                setNotesList(res.data?.data);
            },
            (err) => {
                console.error("Error:", err);
            }
        );
    };

    useEffect(() => {
        fetchNotes(activeTab);
    }, [activeTab]);


    const handlePinSubmit = (pinValue) => {
        try {
            http({
                method: "PATCH",
                url: `client/note/${pinId}/pin`,
                isSecure: true,
                body: { pin: pinValue },
            }, (res) => {
                fetchNotes();
                setOpenPinModal(false);
                toast.success(res.message);
            }, (err) => {
                console.error(err);
            });
        } catch (err) {
            console.error(err);
        }
    };
    const handleUnPinSubmit = (pinValue) => {
        try {
            http({
                method: "PATCH",
                url: `client/note/${pinId}/unpin`,
                isSecure: true,
                body: { pin: pinValue },
            }, (res) => {
                fetchNotes();
                setOpenPinModal(false);
                toast.success(res.message);
            }, (err) => {
                console.error(err);
            });
        } catch (err) {
            console.error(err);
        }
    };

    const handlePinClick = (id) => {
        setPinId(id);
        const selectedNote = notesList.find(note => note.id === id);
        setPinNote({
            ...selectedNote,
            pin: selectedNote.pin,
        });
        setOpenPinModal(true);
    };

    const hanldeArchiveClick = (id) => {
        setArchiveId(id);
        const selectedNoteArchive = notesList.find(noteArchive => noteArchive.id === id);
        setArchiveNote({
            ...selectedNoteArchive,
            archive: selectedNoteArchive.archive,
        });
        setArchiveModal(true);

    }

    const handleArchiveSubmit = (archiveValue) => {
        try {
            http({
                method: "PATCH",
                url: `client/note/${archiveId}/archive`,
                isSecure: true,
                body: { archive: archiveValue },

            }, (res) => {
                fetchNotes();
                setArchiveModal(false);
                toast.success(res.message);
            }, (err) => {
                console.error(err);
            });
        } catch (err) {
            console.error(err);
        }
    }

    const hanldeEditNotes = (id) => {
        if (!canUpdateNotes()) {
            toast.error("You are not authorized to access the resource")
            return;  // Exit early if the user doesn't have permission
        }
        try {
            // Check if the note with the given id exists in notesList
            const note = notesList.find(note => note.id === id);
            if (note) {
                // If the note exists, make a GET request to fetch its edit URL
                http({
                    method: "GET",
                    url: `client/note/${storedId}/${id}/edit`,
                    isSecure: true,
                },
                    (res) => {
                        // Update the editNote state with the fetched data
                        const data = res?.data;
                        setEditNote(data);
                        setValue("title", data.title);
                        setTags(data.tags.map(tag => tag.name));  // Update the tags state
                        setValue("description", data.description);
                        setIsEditing(true);
                    },
                    (err) => {
                        console.error(err);
                    });
            } else {
                // If the note does not exist, log an error message
                console.error("Note not found");
            }
        } catch (err) {
            console.error(err);
        }
    }

    const updateNoteHandle = (data) => {
        try {
            const filteredTags = editNote.tags.filter((tag, index) => !deleteTagIDs.includes(index));

            // Extract existing tag names from remaining tags in editNote
            const existingTags = new Set(filteredTags.map(tag => tag.name));

            // Filter out duplicates and trim whitespace from new tags
            const newTags = tags.map(tag => tag.trim()).filter(tag => tag !== '' && !existingTags.has(tag));

            // Combine existing tags and new unique tags
            const updatedTags = [...existingTags, ...newTags];

            const payload = {
                title: data.title,
                description: data.description,
                tag: updatedTags
            };

            http(
                {
                    method: "PUT",
                    url: `client/note/update/${storedId}/${editNote?.id}`,
                    isSecure: true,
                    body: payload
                },
                (res) => {
                    // Refresh the notes list
                    fetchNotes();
                    reset();
                    setTags([]);
                    setIsEditing(false); // Reset to "Add" mode
                },
                (err) => {
                    console.error(err);
                }
            );
        } catch (err) {
            console.error(err);
        }
    }
    useEffect(() => {
        // Fetch permission data when the component mounts
        handlePermissionData(setPermission);
        handlePermissionIdsData(setPermissionIds)
    }, []);


    return (
        <div className="right_section font-poppins">
            <div className="clients-detail-area py-3">
                <ClientTabs />

                {/* <!-- NOTES TAB START --> */}
                <div className="tab-pane" id="notes">
                    <div className="row notes_area mt-2">
                        <div className="col-md-7 left_notes">
                            <div className="d-flex justify-content-between align-items-center mb-2 comm-head">
                                <div className="left_head">
                                    <h4 className="fs-24 mt-3">Notes</h4>
                                </div>
                                <div className="right_head mt-3">
                                    <div className="notes-btn-group d-flex align-items-center">
                                        <button
                                            className={`nav-link ${activeTabColor.all === 'active' ? 'active' : ''}`}
                                            onClick={() => setActiveTab('all')}
                                        >All</button>
                                        <hr />
                                        <button
                                            className={`nav-link ${activeTabColor.pinned === 'active' ? 'active' : ''}`}
                                            onClick={() => setActiveTab('pinned')}
                                        >Pinned</button>
                                        <hr />
                                        <button
                                            className={`nav-link ${activeTabColor.archived === 'active' ? 'active' : ''}`}
                                            onClick={() => setActiveTab('archived')}
                                        >Archived</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {hasNoteViewPermission() ? (
                                    notesList && notesList.length > 0 ? (
                                        notesList.map((noteList, index) => (
                                            <div key={index} className="col-lg-4 col-md-6 col-sm-12 mt-3">
                                                <div className="note-box bg-white shadow-lg rounded-4 p-4">
                                                    <Link to={`/admin/client/note/notedetail/${noteList.id}`}>
                                                        <h4 className="fs-18 text-black text-start">{noteList?.title}</h4>
                                                        <p className="fs-14 text-muted mt-2">{moment(noteList?.created_at).format('MMM D, YYYY, h:mm a')}</p>
                                                        <p className="fs-14 text-muted mt-2">{noteList?.description}</p>

                                                        <div className="note-tags mt-3 d-flex align-items-center flex-wrap gap-2">
                                                            {noteList.tags && noteList.tags.map((tagsList, tagIndex) => (
                                                                <span key={tagIndex}>{tagsList?.name}</span>
                                                            ))}
                                                        </div>
                                                    </Link>
                                                    <div className="note-client mt-2 d-flex align-items-center justify-content-between flex-wrap">
                                                        <div className="l_client d-flex align-items-center mt-2">
                                                            <img src={require("../../../assets/images/user-pic.png")} alt="User" />
                                                            <p className="fs-14 text-black ms-2">John Smith</p>
                                                        </div>
                                                        <div className="r_client d-flex align-items-center gap-1 mt-2">
                                                            <span className={`d-flex align-items-center justify-content-center ${noteList.pin === "1" ? 'active-pin' : 'inactive-pin'}`} onClick={() => handlePinClick(noteList.id)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14.01" height="13.999" viewBox="0 0 14.01 13.999">
                                                                    <path id="thumbtack" d="M13.583,3.941,10.113.472a1.506,1.506,0,0,0-1.922-.2A1.459,1.459,0,0,0,8.005,2.49l.345.345a.473.473,0,0,1,0,.666L7.125,4.727a.879.879,0,0,1-.835.229l-.479-.123a4.741,4.741,0,0,0-1.692-.128A1.966,1.966,0,0,0,2.463,6.022,2.048,2.048,0,0,0,2.94,8.171L3.959,9.226.171,13.016A.584.584,0,1,0,1,13.841L4.77,10.067l.729.755a2.728,2.728,0,0,0,1.9.856,2.048,2.048,0,0,0,.684-.117A1.834,1.834,0,0,0,9.31,9.985a4.723,4.723,0,0,0-.125-1.677l-.124-.6a.88.88,0,0,1,.233-.825l1.219-1.217a.543.543,0,0,1,.346-.142.425.425,0,0,1,.3.127l.3.3a1.5,1.5,0,0,0,1.938.218,1.459,1.459,0,0,0,.186-2.221Z" transform="translate(0 -0.013)"></path>
                                                                </svg>
                                                            </span>
                                                            <span className={`d-flex align-items-center justify-content-center ${noteList.archive === "1" ? 'active-pin' : 'inactive-pin'}`} onClick={() => hanldeArchiveClick(noteList.id)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14.002" height="13.999" viewBox="0 0 14.002 13.999">
                                                                    <g id="box" transform="translate(0 -0.052)">
                                                                        <path id="Path_3569" data-name="Path 3569" d="M11.082.052H2.916A2.916,2.916,0,0,0,0,2.968H0a1.75,1.75,0,0,0,1.75,1.75h10.5A1.732,1.732,0,0,0,14,3.1,2.919,2.919,0,0,0,11.209.055Z" transform="translate(0)"></path>
                                                                        <path id="Path_3570" data-name="Path 3570" d="M33.583,213.385H21.917a.583.583,0,0,0-.583.583v4.666a2.92,2.92,0,0,0,2.916,2.916h7a2.92,2.92,0,0,0,2.916-2.916v-4.666A.583.583,0,0,0,33.583,213.385ZM29.5,216.3H26a.583.583,0,1,1,0-1.167h3.5a.583.583,0,1,1,0,1.167Z" transform="translate(-20.751 -207.5)"></path>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <span className="d-flex align-items-center justify-content-center" onClick={() => hanldeEditNotes(noteList.id)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="13.999" height="13.998" viewBox="0 0 13.999 13.998">
                                                                    <g id="write" transform="translate(-7.998 -8)">
                                                                        <path id="Path_3528" data-name="Path 3528" d="M8.3,26.037,8,28.468a.731.731,0,0,0,.728.827.718.718,0,0,0,.093-.006L11.257,29a2.317,2.317,0,0,0,1.35-.663l6.3-6.3a.292.292,0,0,0,0-.412l-3.232-3.235a.292.292,0,0,0-.412,0l-6.3,6.3a2.317,2.317,0,0,0-.663,1.35Z" transform="translate(0 -7.297)"></path>
                                                                        <path id="Path_3529" data-name="Path 3529" d="M41.979,9.951,40.471,8.443a1.512,1.512,0,0,0-2.138,0L36.918,9.858a.292.292,0,0,0,0,.412L40.151,13.5a.292.292,0,0,0,.412,0l1.416-1.416A1.512,1.512,0,0,0,41.979,9.951Z" transform="translate(-20.425 0)"></path>
                                                                        <path id="Path_3530" data-name="Path 3530" d="M36.874,52.583A.583.583,0,0,0,36.291,52H29.583a.583.583,0,0,0,0,1.167h6.707A.583.583,0,0,0,36.874,52.583Z" transform="translate(-14.877 -31.168)"></path>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="col-12 text-center mt-5">
                                            <img src={require("../../../assets/images/datanotfound.png")} alt="No Data Found" />
                                            <p className="fs-18 text-muted mt-3">No Data found</p>
                                        </div>
                                    )
                                ) : (
                                    <div className="no-access-message">
                                        You do not have access to view the list.
                                    </div>
                                )}
                            </div>

                        </div>
                        <div className="col-md-5 right_notes end-0 show-notes">
                            <div className="noteform-area ps-5 pe-2 py-3 position-relative">
                                <div className="vr-line"></div>
                                <div className="notes-head p-4 d-flex align-items-center gap-3">
                                    <img src="assets/images/note.png" alt="" />
                                    <h4 className="fs-20 text-white fw-regular lh-base">Please create<br /> a new note.
                                    </h4>
                                </div>
                                <div className="notes-content mt-4">
                                    <h5 className="fs-20 fw-medium">Add Note</h5>
                                    <p className="fs-16 text-muted mt-2">Please write your note in the space provided
                                        below.</p>
                                    <form onSubmit={handleSubmit(isEditing ? updateNoteHandle : onSubmit)}>
                                        <div className="form-group mt-4">
                                            <label >Title</label>
                                            <input
                                                type="text"
                                                name="title"
                                                id="title"
                                                className="form-input mt-2 bg-white"
                                                placeholder="Type here..."
                                                {...register('title', {
                                                    required: "Required",
                                                })}
                                            />
                                            {errors.title && (
                                                <span className="error">{errors.title.message}</span>
                                            )}
                                        </div>
                                        <div className="form-group mt-4 moreInfoShow">
                                            <label>Tags</label>
                                            <div className="bootstrap-tagsinput form-input">
                                                {tags.map((tag, index) => (
                                                    <span key={index} className="tag-item">
                                                        <span className="tag-text">{tag}</span>
                                                        <span className="tag-close" onClick={() => handleRemoveTag(index)}>x</span>
                                                    </span>
                                                ))}
                                                <input
                                                    type="text"
                                                    name="input"
                                                    value={inputValue}
                                                    onChange={handleInputChange}
                                                    onKeyDown={handleKeyDown}
                                                    onFocus={handleInputFocus}
                                                    onBlur={handleInputBlur}
                                                    placeholder="Press Enter, comma or space to add tags"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group mt-4">
                                            <label >Description</label>
                                            <textarea
                                                className="mt-2"
                                                name="description"
                                                id="description"
                                                cols="20"
                                                rows="10"
                                                {...register('description', { required: "Required", })}
                                            >
                                            </textarea>
                                            {errors.description && (
                                                <span className="error">{errors.description.message}</span>
                                            )}
                                        </div>
                                        <div className="form-group mt-4 text-end">
                                            <button type="submit" className="btn-blue">
                                                {isEditing ? 'Update' : 'Add'}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="close_message_notes"><i className="fa fa-times"></i></div>
                            <div className="notes-icon">
                                <i className="fa fa-sticky-note"></i>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- NOTES TAB END --> */}
            </div>

            <Modal
                show={openPinModal}
                size="md"
                onHide={setOpenPinModal}
                centered
                contentClassName="mx-auto p-3 h-auto"
                className="modal pin-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header border-0 justify-content-between p-0">
                    {
                        pinNote?.pin === "1" ? (
                            <h4 className="fs-20">Unpin</h4>
                        ) : (
                            <h4 className="fs-20">pin</h4>
                        )
                    }

                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>
                <div className="modal-body ">
                    {
                        pinNote?.pin === "1" ? (
                            <h4 className="txt text-center mt-2">Are you sure you want to Unpin?</h4>
                        ) : (
                            <h4 className="txt text-center mt-2">Are you sure you want to pin?</h4>
                        )
                    }


                </div>
                <div className=" text-center">
                    <form>
                        <div className="modal-footer border-0 justify-content-center p-0">
                            {pinNote?.pin === "1" ? (
                                <button type="button" className="btn-blue" onClick={() => { handleUnPinSubmit("0"); setOpenPinModal(false); }}>Unpin</button>
                            ) : (
                                <button type="button" className="btn-blue" onClick={() => { handlePinSubmit("1"); setOpenPinModal(false); }}>Pin</button>
                            )}
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal
                show={archiveModal}
                onHide={setArchiveModal}
                centered
                size="md"
                contentClassName="mx-auto p-3 h-auto"
                className="modal pin-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header  border-0 justify-content-between p-0">
                    {
                        archiveNote?.archive === "1" ? (
                            <h4 className="fs-20">UnArchive</h4>
                        ) : (

                            <h4 className="fs-20">Archive</h4>
                        )
                    }

                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>
                <div className="modal-body ">
                    {
                        archiveNote?.archive === "1" ? (<h4 className="txt text-center mt-2">Are you sure you want to UnArchive?</h4>) : (
                            <h4 className="txt text-center mt-2">Are you sure you want to Archive?</h4>
                        )
                    }


                </div>
                <div className=" text-center my-4">
                    <form>
                        <div className="modal-footer border-0 justify-content-center p-0">
                            {archiveNote?.archive === "1" ? (
                                <button type="button" className="btn-blue" onClick={() => { handleArchiveSubmit("0"); setArchiveModal(false); }}>Unarchive</button>
                            ) : (
                                <button type="button" className="btn-blue" onClick={() => { handleArchiveSubmit("1"); setArchiveModal(false); }}>Archive</button>
                            )
                            }

                        </div>
                    </form>
                </div>
            </Modal>
        </div>

    )
}
export default Notes;