import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import { http } from "../../../http/http";
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";
import { GlobalContext } from "../../../../App";
import { handlePermissionData, handlePermissionIdsData } from '../../../http/help';

const Users = () => {
    const [inviteModal, setinviteModal] = useState(false)
    const { register, handleSubmit, reset, watch, setValue, getValues } = useForm({
        defaultValues: {
            first_name: '',
            middle_name: '',
            last_name: '',
            email: '',
            phone_no: '',
            designation_id: '',
            roles: [],
        }
    });
    const { showLoader, setShowLoader } = useContext(GlobalContext);
    const [designationList, setDesignationList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    console.log(roleList, "roleList")
    const [userList, setUserList] = useState([]); // Initialize as an empty array

    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedUserDetails, setSelectedUserDetails] = useState(null);
    const [view, setView] = useState('userDetails');
    const [editInviteUserModal, setEditInviteUserModal] = useState(false);
    const [editInviteUserData, setEditInviteUserData] = useState(null);
    const watchedRoles = watch('roles', []);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("all");
    const [permission, setPermission] = useState([]);
    const [permissionIds, setPermissionIds] = useState([]);
    const userID = JSON.parse(localStorage.getItem('__assist_erp'));
    // Function to check if user has 'task' related permissions or is allowed full access
    const hasUserViewPermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("adminuser_view");
        const hasTaskFullAccess = permissionIds?.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };
    // Function to check if user has 'task' related permissions or is allowed full access
    const hasUserPermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("adminuser_create");
        const hasTaskFullAccess = permissionIds?.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };


    // Function to check if user has 'task' related permissions or is allowed full access
    const hasUserDeletePermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("adminuser_delete");
        const hasTaskFullAccess = permissionIds?.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };

    // Function to check if user has 'task' related permissions or is allowed full access
    const hasUserEditPermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("adminuser_update");
        const hasTaskFullAccess = permissionIds?.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };

    // Function to handle "Generate Password" button click
    const handleGeneratePasswordClick = () => {
        setView('generatePassword'); // Switch to the 'generatePassword' view
    };
    // Handle row click to show the 'shwdetail' class
    const handleRowClick = (userId) => {
        setSelectedUserId(userId); // Set the selected user ID
        try {
            http(
                {
                    method: "GET",
                    url: `adminusers/${userId}/view`,
                    isSecure: true,
                },
                (res) => {
                    setSelectedUserDetails(res?.data);
                },
            )
        }
        catch (error) {

        }
    };

    // Handle click on icon to remove 'shwdetail' class
    const handleIconClick = () => {
        console.log("click")
        setSelectedUserId(null); // Clear the selected user to remove the 'shwdetail' class
    };
    const AddUsersHandle = async (data) => {
        setShowLoader(true);
        try {
            http(
                {
                    method: "POST",
                    url: `adminusers/add`,
                    isSecure: true,
                    body: {
                        first_name: data.first_name,
                        last_name: data.last_name,
                        middle_name: data.middle_name,
                        email: data.email,
                        phone_no: data.phone_no,
                        designation_id: parseInt(data.designation_id), // Assuming designation ID is a number
                        roles: data.roles, // This is an array of roles
                    },
                },
                (res) => {
                    console.log("User added successfully", res);
                    setinviteModal(false); // Close the modal after successful submission
                    reset(); // Reset form after submission
                    toast.success(res.message);
                    setShowLoader(false);
                    handleUserList();
                }
            );
        } catch (error) {
            console.error('Error adding user:', error);
            setShowLoader(false);
        }
    };



    const handleDesignationList = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `designation/listing`,
                    isSecure: true,
                },
                (res) => {
                    setDesignationList(res?.data?.data);
                },
            )
        }
        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };


    const handleRoleList = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `roles`,
                    isSecure: true,
                },
                (res) => {
                    setRoleList(res?.data);
                },
            )
        }
        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };

    const handleUserList = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `adminusers`,
                    isSecure: true,
                },
                (res) => {
                    setUserList(res?.data);
                },
            )
        }
        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };
    useEffect(() => {

        handleUserList();
    }, []);

    const handleStatusChange = async (userId, status) => {
        try {
            // Sending the updated status in the required format
            http(
                {
                    method: 'PUT',
                    url: `adminusers/status/${userId}`, // Dynamic user ID
                    isSecure: true,
                    body: {
                        status: status, // Ensure the status is sent as a string
                    },
                },
                (res) => {
                    console.log('Status updated successfully', res);

                    // Update the selected user status in state to reflect the new status in the UI
                    setSelectedUserDetails((prevDetails) => ({
                        ...prevDetails,
                        status: parseInt(status),
                    }));
                    handleUserList();
                    toast.success(res.message);
                }
            );
        } catch (error) {
            console.error('Error updating status', error);
        }
    };

    // Function to handle user deletion
    const handleDelete = async (userId) => {
        if (!hasUserDeletePermission()) {
            toast.error("You don’t have permission to add a user"); // Display toast message
            return; // Exit function if the user lacks permission
        }
        try {
            http(
                {
                    method: 'DELETE',
                    url: `adminusers/${userId}/delete`, // Append the user ID to the URL
                    isSecure: true,
                },
                (res) => {
                    console.log('User deleted successfully', res);

                    // Optionally, update the local state to remove the user from the UI
                    setUserList((prevList) => prevList.filter((user) => user.id !== userId));

                    // Clear selected user details after deletion
                    setSelectedUserDetails(null);
                    handleUserList();
                    setSelectedUserId(null);
                    toast.success(res.message);
                }
            );
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    // Function to handle the API call and populate form fields
    const handleEditInviteUser = async (selectedUserId) => {

        if (!hasUserEditPermission()) { // Assuming hasEditPermission() checks for edit permissions
            toast.error("You don’t have permission to edit this user."); // Show error message
            return; // Exit the function if permission is not granted
        }

        try {
            http(
                {
                    method: 'GET',
                    url: `adminusers/${selectedUserId}/edit`, // Dynamic user ID
                    isSecure: true,
                },
                (res) => {
                    const userData = res?.data;
                    setEditInviteUserData(userData); // Store full user data

                    // Populate the form fields using setValue for each field
                    setValue("firstName", userData.first_name || '');
                    setValue("middleName", userData.middle_name || '');
                    setValue("lastName", userData.last_name || '');
                    setValue("email", userData.email || '');
                    setValue("phoneNo", userData.phone_no || '');
                    // setValue("designation", userData.designation_id ? userData.designation_id.id : '');
                    setValue("roles", userData.roles || []);

                    // Open the modal
                    setEditInviteUserModal(true);
                    handleRoleList();
                    handleDesignationList();
                }
            );
        } catch (error) {
            console.error('Error fetching user details', error);
        }
    };
    // Handle role selection (checkboxes)
    const handleRoleChange = (role) => {
        const currentRoles = getValues('roles') || [];
        const updatedRoles = currentRoles.includes(role)
            ? currentRoles.filter(r => r !== role) // Remove the role if it's already selected
            : [...currentRoles, role]; // Add the role if it's not selected

        setValue('roles', updatedRoles); // Update roles in the form data
    };

    // Handle form submission
    const updateInviteUser = (data) => {
        try {
            const userId = editInviteUserData?.id;
            http(
                {
                    method: "POST",
                    url: `adminusers/update/${userId}`, // Use the correct user ID dynamically if needed
                    isSecure: true,
                    body: {
                        first_name: data.firstName,
                        last_name: data.lastName,
                        email: data.email,
                        phone_no: data.phoneNo,
                        designation_id: data.designation_id,

                        roles: data.roles, // Pass the roles array directly from the form
                    },
                },
                (res) => {
                    toast.success(res.message)
                    setEditInviteUserModal(false);
                    handleUserList();
                }
            );
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };

    // Search input handler
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value); // Update search term when the user types
    };


    // Handle status change from the dropdown
    const handleFilterStatusChange = (event) => {
        setSelectedStatus(event.target.value); // Update the selected status
    }
    const filteredUsers = userList.filter((user) => {
        // Apply status filter
        if (selectedStatus === "active" && user.status !== "1") {
            return false; // Exclude users who are not active
        }
        if (selectedStatus === "inactive" && user.status !== "0") {
            return false; // Exclude users who are not inactive
        }

        // Apply search filter
        const search = searchTerm.toLowerCase();

        const nameMatch = user?.name?.toLowerCase().includes(search) || false;
        const emailMatch = user?.email?.toLowerCase().includes(search) || false;
        const roleMatch = user?.roles?.some((role) => role?.toLowerCase().includes(search)) || false;

        return nameMatch || emailMatch || roleMatch; // Return true if any match is found
    });

    const handleInviteUserClick = () => {

        if (!hasUserPermission()) {
            toast.error("You don’t have permission to add a user"); // Display toast message
            return; // Exit function if the user lacks permission
        }
        setinviteModal(true);  // Open the modal
        handleDesignationList();
        handleRoleList();
    };
    useEffect(() => {
        if (editInviteUserData && designationList.length > 0 && editInviteUserData.designation_id) {
            setValue("designation_id", editInviteUserData.designation_id.id || '');
        }
    }, [editInviteUserData, designationList]);


    useEffect(() => {
        // Fetch permission data when the component mounts
        handlePermissionData(setPermission);
        handlePermissionIdsData(setPermissionIds)
    }, []);
    return (
        <>
            <div className="userdetailbox">
                <div className={`leftdetbox ${selectedUserId ? 'shwdetail' : ''}`}>
                    <div className="bg-white rounded-4 shadow-main h_800 overflow-y-scroll pb-4">
                        <div className="accord-head d-flex align-items-start justify-content-between px-4 pt-4 pb-0">
                            <div>
                                <h4 className="fs-20 font-weight-bold">Users</h4>
                                <p className="t_blue">Users & Roles</p>
                            </div>
                            <button className="add-new" onClick={handleInviteUserClick}>
                                <i className="fa fa-plus me-2"></i>
                                Invite User
                            </button>
                            {/* <button className="add-new-button"></button> */}
                        </div>
                        <div className="search-drop pt-2 px-4 pb-4 d-flex align-items-center justify-content-between flex-wrap gap-3">

                            <form class="searchuser d-flex align-items-center gap-2">
                                <input type="search"
                                    className="form-input"
                                    name="search"
                                    placeholder="Search by name, role, or email"
                                    value={searchTerm}
                                    onChange={handleSearchChange} />
                                <i className="fa fa-search"></i>
                            </form>
                            <select className="form-input" value={selectedStatus} onChange={handleFilterStatusChange}>
                                <option value="all">All</option> {/* Show all users (active and inactive) */}
                                <option value="active">Active</option> {/* Show only active users */}
                                <option value="inactive">Inactive</option> {/* Show only inactive users */}
                            </select>
                        </div>

                        {hasUserViewPermission() ? (
                            <table className="table tableuser px-4 pb-0 table-responsive">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Roles</th>
                                        <th>Designation</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredUsers && filteredUsers.length > 0 ? (
                                        filteredUsers.map((user) => (
                                            <tr
                                                key={user.id} // Add a key for each row
                                                onClick={() => handleRowClick(user.id)}
                                                className={selectedUserId === user.id ? 'selected-row' : ''}
                                            >
                                                <td>
                                                    <img src={require("../../../../assets/images/user-pic.png")} className="me-2" alt="" />
                                                    {user?.name}
                                                </td>
                                                <td>{user?.roles?.join(', ')}</td>
                                                <td>{user?.designation_id?.name}</td>
                                                <td>{user?.email}</td>
                                                <td>{user?.phone_no || 'N/A'}</td>
                                                <td>
                                                    {user.status === "1" ? (
                                                        <span className="active">Active</span>
                                                    ) : (
                                                        <span className="inactive">Inactive</span>
                                                    )}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="6">No users found.</td> {/* Show message if userList is empty */}
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        ) : (
                            <div className="text-center p-4">
                                <h4 className="text-danger">You don’t have permission to view users.</h4>
                            </div>
                        )}
                    </div>
                </div>
                {view === 'userDetails' && selectedUserDetails && (
                    <div className={`rightdetbox ${selectedUserId ? 'shwdetail' : ''}`}>
                        <div className="bg-white shadow---main p-4 rounded-4">
                            <div className="d-flex align-items-center gap-4 justify-content-between">
                                <h4 className="fs-20">User Details</h4>
                                <div className="r_usrhead d-flex alifgn-items-center gap-2">
                                    <div className="editicon" onClick={() => {
                                        handleEditInviteUser(selectedUserDetails?.id);  // Call API with the dynamic userId
                                        setEditInviteUserModal(true);  // Open the modal after API call
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.999" viewBox="0 0 14 13.999">
                                            <g id="write" transform="translate(-7.998 -8)">
                                                <path id="Path_3528" data-name="Path 3528" d="M8.3,26.038,8,28.469a.731.731,0,0,0,.728.827.718.718,0,0,0,.093-.006L11.257,29a2.317,2.317,0,0,0,1.35-.663l6.3-6.3a.292.292,0,0,0,0-.412l-3.232-3.235a.292.292,0,0,0-.412,0l-6.3,6.3a2.317,2.317,0,0,0-.663,1.35Z" transform="translate(0 -7.297)" fill="#202529" />
                                                <path id="Path_3529" data-name="Path 3529" d="M41.979,9.951,40.472,8.443a1.512,1.512,0,0,0-2.138,0L36.918,9.859a.292.292,0,0,0,0,.412L40.151,13.5a.292.292,0,0,0,.412,0l1.416-1.416A1.512,1.512,0,0,0,41.979,9.951Z" transform="translate(-20.425 0)" fill="#202529" />
                                                <path id="Path_3530" data-name="Path 3530" d="M36.875,52.583A.583.583,0,0,0,36.291,52H29.583a.583.583,0,0,0,0,1.167h6.708A.583.583,0,0,0,36.875,52.583Z" transform="translate(-14.877 -31.168)" fill="#202529" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="deticon"
                                        onClick={() => handleDelete(selectedUserDetails.id)}

                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12.598" height="13.998" viewBox="0 0 12.598 13.998">
                                            <path id="Path_3741" data-name="Path 3741" d="M411.1,1337.268a.46.46,0,0,1,.193.042.5.5,0,0,1,.163.112.517.517,0,0,1,.107.168.5.5,0,0,1,.036.2v.266a.512.512,0,0,1-.144.357.491.491,0,0,1-.355.154H399.5a.49.49,0,0,1-.355-.154.51.51,0,0,1-.145-.357v-.266a.514.514,0,0,1,.143-.364.5.5,0,0,1,.163-.112.461.461,0,0,1,.193-.042h2.041a.865.865,0,0,0,.559-.2.892.892,0,0,0,.309-.5l.106-.476a1.391,1.391,0,0,1,.482-.777,1.412,1.412,0,0,1,.857-.308h2.891a1.418,1.418,0,0,1,.843.3,1.391,1.391,0,0,1,.486.749l.114.511a.9.9,0,0,0,.309.511.948.948,0,0,0,.559.2Zm-1.037,9.722c.21-1.981.586-6.7.586-6.747a.527.527,0,0,0-.023-.21.58.58,0,0,0-.1-.182.528.528,0,0,0-.166-.119.49.49,0,0,0-.2-.042h-9.708a.5.5,0,0,0-.2.042.52.52,0,0,0-.166.119.566.566,0,0,0-.133.392c0,.007.015.175.037.455.1,1.232.376,4.668.555,6.3a2.033,2.033,0,0,0,2.051,1.974c.879.021,1.784.028,2.71.028.872,0,1.757-.007,2.66-.028a2.017,2.017,0,0,0,1.459-.553,2.039,2.039,0,0,0,.64-1.428Z" transform="translate(-399 -1335)" fill="#202529" fill-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <img src={require("../../../../assets/images/circle-xmark.png")} className="object-fit-contain" alt="" onClick={handleIconClick} />
                                </div>
                            </div>
                            <div className="usrhead d-flex align-items-center gap-4 justify-content-between mt-3">
                                <div className="lhead d-flex align-items-center gap-3">
                                    <img src={require("../../../../assets/images/userman.jpg")} className="rounded-4" alt="" />
                                    <div>
                                        <h6 className="fs-18 fw-semibold">{selectedUserDetails.name}</h6>
                                        <span className="d-flex align-items-center gap-2">
                                            <img src={require("../../../../assets/images/email.png")} className="emailicon" alt="" />
                                            <p className="fs-14 text-muted">{selectedUserDetails.email}</p>
                                        </span>
                                    </div>
                                </div>
                                <div className="rhead">
                                    <select
                                        value={selectedUserDetails.status} // Set the current status as the selected option
                                        onChange={(e) =>
                                            handleStatusChange(selectedUserDetails.id, e.target.value) // Capture the selected value and send the API call
                                        }
                                    >
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </select>
                                </div>
                            </div>
                            <hr className="my-4" />
                            <div className="row">
                                <div className="col-lg-6 p-0 mb-3">
                                    <p className="fs-14 text-muted">First Name</p>
                                    <h6 className="fs-18 fs-medium">{selectedUserDetails?.first_name}</h6>
                                </div>
                                <div className="col-lg-6 p-0 mb-3">
                                    <p className="fs-14 text-muted">Middle Name</p>
                                    <h6 className="fs-18 fs-medium">{selectedUserDetails?.middle_name}</h6>
                                </div>
                                <div className="col-lg-6 p-0 mb-3">
                                    <p className="fs-14 text-muted">Last Name</p>
                                    <h6 className="fs-18 fs-medium">{selectedUserDetails?.last_name}</h6>
                                </div>
                                <div className="col-lg-6 p-0 mb-3">
                                    <p className="fs-14 text-muted">Designation</p>
                                    <h6 className="fs-18 fs-medium"> {selectedUserDetails.designation_id?.name}</h6>
                                </div>
                                <div className="col-lg-6 p-0 mb-3">
                                    <p className="fs-14 text-muted">Email Address</p>
                                    <h6 className="fs-18 fs-medium">{selectedUserDetails.email}</h6>
                                </div>
                                <div className="col-lg-6 p-0 mb-3">
                                    <p className="fs-14 text-muted">Phone No.</p>
                                    <h6 className="fs-18 fs-medium">{selectedUserDetails.phone_no || 'N/A'}</h6>
                                </div>
                            </div>
                            <hr className="my-4" />
                            <h4 className="fs-semibold fs-20">Assigned Roles</h4>
                            <ul className="mt-3 ps-0 assignrole">
                                <li className="fs-18 fw-medium"><span></span>{selectedUserDetails.roles.join(', ')}</li>

                            </ul>
                            <hr className="my-4" />
                            <button className="btn btn-dark" onClick={handleGeneratePasswordClick}>Generate Password</button>
                        </div>
                    </div>

                )}
                {view === 'generatePassword' && (
                    <div className="bg-white shadow---main p-4 rounded-4 w-full position-relative">
                        <div className="gpassword d-flex align-items-center justify-content-between gap-3">
                            <i className="fa fa-arrow-left fs-20"></i>
                            <a href="#!">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path id="circle-xmark" d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm3.707,14.293a1,1,0,1,1-1.414,1.414L12,13.414,9.707,15.707a1,1,0,0,1-1.414-1.414L10.586,12,8.293,9.707A1,1,0,0,1,9.707,8.293L12,10.586l2.293-2.293a1,1,0,0,1,1.414,1.414L13.414,12l2.293,2.293Z" fill="#212529" />
                                </svg>
                            </a>
                        </div>

                        <h4 className="fs-20 mt-3">Generate Password</h4>
                        <p className="fs-14">support@assisterp.com</p>
                        <hr className="my-3" />
                        <div className="createpwd">
                            <div className="d-flex align-items-start gap-2">
                                <div className="l_createpwd">
                                    <input id="createpwd" type="checkbox" />
                                </div>
                                <div className="r_createpwd">
                                    <label htmlFor="createpwd" className="text-dark">Automatically create password</label>
                                    <p className="mt-2 fs-14">Passwords must be between 8 and 20 characters and use a combination of at least three of the following: uppercase letters, lowercase letters, numbers, and symbols.</p>
                                    <div className="mt-2">
                                        <label htmlFor="password">Password</label>
                                        <div className="pwdinput position-relative">
                                            <input type="password" class="form-input mt-1" name="password" placeholder="Type here..." />
                                            <i className="fa fa-eye"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-3">
                            <div className="l_createpwd d-flex align-items-start gap-2">
                                <input id="require" type="checkbox" />
                                <label htmlFor="require">Require this user to change their password when they first sign in</label>
                            </div>
                        </div>

                        <div className="generatebtn">
                            <button className="add-new">Generate Password</button>
                        </div>


                    </div>
                )}
            </div>


            {/* MODAL START */}
            <Modal
                show={inviteModal}
                onHide={() => setinviteModal(false)}
                centered
                size="md"
                contentClassName="mx-auto p-3 h-auto"
                className="modal pin-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header p-0 justify-content-between align-items-center border-0">
                    <h4 className="fs-20">Invite User</h4>
                    <Modal.Header closeButton className="close-icon border-0 pe-2 pb-0"></Modal.Header>
                </div>
                <div className="services-form row">
                    <div className="col-lg-12 mt-3 p-0">
                        {/* Form submission handled by React Hook Form */}
                        <form onSubmit={handleSubmit(AddUsersHandle)}>
                            <div className="border-0 justify-content-center p-0">
                                <label htmlFor="first_name">First Name</label>
                                <input
                                    {...register('first_name')}
                                    id="first_name"
                                    className="form-input mx-0 mt-1"
                                    type="text"
                                    placeholder="Type here..."
                                />
                            </div>
                            <div className="border-0 justify-content-center p-0 mt-3">
                                <label htmlFor="middle_name">Middle Name</label>
                                <input
                                    {...register('middle_name')}
                                    id="middle_name"
                                    className="form-input mx-0 mt-1"
                                    type="text"
                                    placeholder="Type here..."
                                />
                            </div>
                            <div className="border-0 justify-content-center p-0 mt-3">
                                <label htmlFor="last_name">Last Name</label>
                                <input
                                    {...register('last_name', {})}
                                    id="last_name"
                                    className="form-input mx-0 mt-1"
                                    type="text"
                                    placeholder="Type here..."
                                />
                            </div>
                            <div className="border-0 justify-content-center p-0 mt-3">
                                <label htmlFor="email">Email</label>
                                <input
                                    {...register('email', {})}
                                    id="email"
                                    className="form-input mx-0 mt-1"
                                    type="email"
                                    placeholder="Type here..."
                                />
                            </div>
                            <div className="border-0 justify-content-center p-0 mt-3">
                                <label htmlFor="phone_no">Phone No.</label>
                                <input
                                    {...register('phone_no', {})}
                                    id="phone_no"
                                    className="form-input mx-0 mt-1"
                                    type="number"
                                    placeholder="Type here..."
                                />
                            </div>
                            <div className="border-0 justify-content-center p-0 mt-3">
                                <select
                                    {...register('designation_id', {})}
                                    className="form-input mx-0 mt-1"
                                >
                                    <option value="">Select Designation</option>
                                    {designationList && designationList.length > 0 ? (
                                        designationList.map((designation, index) => (
                                            <option key={index} value={designation.id}>
                                                {designation.name}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="" disabled>Loading designations...</option>
                                    )}
                                </select>
                            </div>

                            <hr className="my-4 opacity-25" />
                            <div className="roles">
                                <h4 className="fs-18">Set Roles</h4>
                                <p className="fs-14 text-muted">
                                    You can assign multiple roles to a user for flexible permissions and access.
                                </p>
                                <div className="d-flex gap-4 flex-wrap">

                                    {roleList && roleList.length > 0 ? (
                                        roleList.map((role) => (
                                            <div key={role.id} className="checks">
                                                <div className="input-grp my-2">
                                                    <input
                                                        type="checkbox"
                                                        value={role.name}
                                                        {...register('roles')}
                                                    />
                                                    <label htmlFor={role.name}>{role.name}</label>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No roles available.</p> // Optional: fallback if roleList is empty
                                    )}

                                </div>
                            </div>
                            <div className="mt-5 border-0">
                                <button type="submit" className="btn-blue ms-auto">Add</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
            {/* MODAL END */}

            <Modal
                show={editInviteUserModal}
                onHide={() => setEditInviteUserModal(false)}
                centered
                size="md"
                contentClassName="mx-auto p-3 h-auto"
                className="modal pin-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header p-0 justify-content-between align-items-center border-0">
                    <h4 className="fs-20">Edit Invite</h4>
                    <Modal.Header closeButton className="close-icon border-0 pe-2 pb-0"></Modal.Header>
                </div>
                <div className="services-form row">
                    <div className="col-lg-12 mt-3 p-0">
                        <form
                            onSubmit={handleSubmit(updateInviteUser)}
                        >
                            <div className="border-0 justify-content-center p-0">
                                <label htmlFor="first_name">First Name</label>
                                <input
                                    id="first_name"
                                    name="first_name"
                                    className="form-input mx-0 mt-1"
                                    placeholder="Type here..."
                                    {...register('firstName')} // Register input field with useForm
                                />
                            </div>
                            <div className="border-0 justify-content-center p-0">
                                <label htmlFor="middle_name">Middle Name</label>
                                <input
                                    id="middle_name"
                                    className="form-input mx-0 mt-1"
                                    placeholder="Type here..."
                                    {...register('middleName')}
                                />
                            </div>
                            <div className="border-0 justify-content-center p-0">
                                <label htmlFor="last_name">Last Name</label>
                                <input
                                    id="last_name"
                                    className="form-input mx-0 mt-1"
                                    placeholder="Type here..."
                                    {...register('lastName')}
                                />
                            </div>
                            <div className="border-0 justify-content-center p-0 mt-3">
                                <label htmlFor="email">Email</label>
                                <input
                                    id="email"
                                    className="form-input mx-0 mt-1"
                                    type="email"
                                    placeholder="Type here..."
                                    {...register('email')}
                                />
                            </div>
                            <div className="border-0 justify-content-center p-0 mt-3">
                                <label htmlFor="phone_no">Phone No.</label>
                                <input
                                    id="phone_no"
                                    className="form-input mx-0 mt-1"
                                    type="number"
                                    placeholder="Type here..."
                                    {...register('phoneNo')}
                                />
                            </div>
                            <div className="border-0 justify-content-center p-0 mt-3">
                                <label htmlFor="designation">Designation</label>
                                <select
                                    className="form-input mx-0 mt-1"
                                    {...register('designation_id')}
                                    defaultValue={editInviteUserData?.designation_id?.id || ''} // Set default value here
                                >
                                    <option value="">Select Designation</option>
                                    {designationList.length > 0 ? (
                                        designationList.map((designation, index) => (
                                            <option key={index} value={designation.id}>
                                                {designation.name}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="" disabled>Loading designations...</option>
                                    )}
                                </select>
                            </div>
                            <hr className="my-4 opacity-25" />
                            <div className="roles">
                                <h4 className="fs-18">Set Roles</h4>
                                <p className="fs-14 text-muted">
                                    You can assign multiple roles to a user for flexible permissions and access.
                                </p>
                                <div className="d-flex gap-4 flex-wrap">
                                    {roleList && roleList.length > 0 ? (
                                        roleList.map((role) => (
                                            <div key={role.id} className="checks">
                                                <div className="input-grp my-2">
                                                    <input
                                                        type="checkbox"
                                                        checked={watchedRoles.includes(role.name)} // Watch the roles array to check the status
                                                        onChange={() => handleRoleChange(role.name)} // Handle changes
                                                    />
                                                    <label htmlFor={role.name}>{role.name}</label>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No roles available.</p> // Optional: fallback if roleList is empty
                                    )}
                                </div>
                            </div>
                            <div className="mt-5 border-0">
                                <button type="submit" className="btn-blue ms-auto">Save Changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default Users;