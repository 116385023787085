import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { http } from "../../http/http";
import Operation_Detail from "../modals/Operation_Detail";
import Advance_Details from "../modals/Advance_Details";
import Directors_Details from "../modals/Directors_Details";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Services from "../modals/Services";
import Assigent_Member from "../modals/Assigend_Member";
import Upload_Files from "../modals/Upload_Files";
import { toast } from "react-toastify";
import { Authform_id, Authform_id_1013, Status_id } from "../../../helpers/staticList";
import BusinessTabs from "../../common/BusinessTabs";
import ClientTabs from "../../common/ClientTabs";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.min.css';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Add_ShareHolder from "../modals/Add_ShareHolder";
import { handleDesignationData, handlePermissionData, handlePermissionIdsData } from '../../http/help';
import Add_Contact from "../modals/Add_Contact";

const BusClientProfie = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { register, handleSubmit, setValue, reset, getValues, watch, formState: { errors }, } = useForm();
    const formValues = watch();
    const [useProfile, setUserProfile] = useState()
    const [editProfileData, setEditProfileData] = useState()
    const [page, setPage] = useState(1);
    const [operationModal, setOperationModal] = useState(false);
    const [advanceModal, setAdvanceModal] = useState(false);
    const [directorModal, setDirectorModal] = useState(false);
    const [shareHolderModal, setShareHolderModal] = useState(false);
    const [servicesModal, setServicesModal] = useState(false);
    const [assigentModal, setAssigentModal] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);
    const [editProfile, setEditProfile] = useState(false);
    const [advanceList, setAdvanceList] = useState();
    const [advanceDetail, setAdvanceDetail] = useState();
    const [editAdvanceModel, setEditAdvanceModel] = useState(false);
    const [operationData, setOperationData] = useState([]);
    const [showEditOperationData, setShowEditOperationData] = useState();
    const [activeAccordionIndex, setActiveAccordionIndex] = useState(null);
    const [editOperationModal, setEditOperationModel] = useState(false);
    const [directorData, setDirectorData] = useState([]);
    console.log(directorData, "directorData");
    const [activeDirectorIndex, setActiveDirectorIndex] = useState(null);
    const [servicePage, setServicePage] = useState(1);
    const [serviceList, setServiceList] = useState();
    console.log(serviceList, "serviceList");
    const [editServiceModal, setEditServiceModal] = useState(false);
    const [serviceDetail, setServiceDetail] = useState();
    const [assignedList, setAssignedList] = useState();
    const [editAssignedDetail, setEditAssignedDetail] = useState();
    const [editAssignedModal, setEditAssignedModal] = useState(false);
    const [editDirectorModal, setEditDirectorModal] = useState(false);
    const [editShareHolderModal, setEditShareHolderModal] = useState(false);
    const [editContactModal, setEditContactModal] = useState(false);
    const [showEditDirData, setShowEditDirData] = useState()
    const [showEditShareHolder, setShowEditShareHolderData] = useState();
    const [showEditContact, setShowEditContact] = useState();
    const [allStaffApis, setAllStaffApi] = useState({
        staff_id: [],
        assistant_id: [],
        bookkeeper_id: [],
        communicator_id: []
    })
    const [allAdvanceApi, setAllAdvanceApi] = useState({
        businesstype_id: [],
        businessnature_id: [],
        businesscategory_id: [],
        data_entry_style_id: [],
        business_planing_id: []

    })
    const [hstList, setHstList] = useState([]);
    const [hstStyleList, setHstStyleList] = useState([]);
    const [staffList, setStaffList] = useState([]);
    const [assignedListNew, setAssignedListNew] = useState([]);
    const [bookeeperList, setBookeeperList] = useState([]);
    const [communiList, setCommuniList] = useState([]);
    const [businessList, setBusinessList] = useState([]);
    const [businessNatureList, setBusinessNatureList] = useState([]);
    const [businesCateList, setBusinessCateList] = useState([]);
    const [businessEnterStyleList, setBusinessEnteryStyleList] = useState([]);
    const [businessplaningList, setBusinessPlaningList] = useState([]);
    const [arcSetupList, setArcSetupList] = useState([]);
    const [hstOpenList, setHstOpenList] = useState([])
    const [payRollList, setPayRollList] = useState([]);
    const [ArcAccessList, setArcAccessList] = useState([])
    const [AdvanceFeeList, setAdvanceFeeList] = useState([])
    const [AgreemetList, setAgreementList] = useState([]);
    const [arcGridList, setArcGridList] = useState([]);
    const [AgreementReasonList, setAgrementReasonList] = useState([]);
    const [currentTask, setCurrentTask] = useState(null);
    const [showCustomFormModal, setShowCustomFormModal] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0); // Track the selected tab
    const [showSurvey, setShowSurvey] = useState(false);
    const [formListing, setFormListing] = useState([])
    const [surveyFormId, setSurveyFormId] = useState(null);
    const [selectedForm, setSelectedForm] = useState(null);
    const [isFormUpdate, setIsFormUpdate] = useState(false);
    const [surveyModels, setSurveyModels] = useState([]);
    const [editForm, setEditForm] = useState(null);
    const [address, setAddress] = useState(''); // State for managing address input
    const [error, setErrors] = useState({});
    const [errorDir, setErrorsDir] = useState({});
    const [errorShareHolder, setErrorShareHolder] = useState({});
    const [isDirectorSinVisible, setIsDirectorSinSinVisible] = useState(false);
    const [isShareHolderSinVisiable, setIsShareHolderSinVisible] = useState(false);
    const [linkProfileModal, setLinkProfileModal] = useState(false);
    const [linkShareHolderModal, setLinkShareHolderModal] = useState(false);
    const [linkContactModal, setLinkContactModal] = useState(false);
    const [selectedDirector, setSelectedDirector] = useState(null);
    const [selectedShareHolder, setSelectedShareHolder] = useState(null);
    const [selectContact, setSelectContact] = useState(null);
    const [shareHolderData, setShareHolderData] = useState([])
    const [activeShareHolderIndex, setActiveShareHolderIndex] = useState(null);
    const [activeContactIndex, setActiveContactIndex] = useState(null)
    const [designationListing, setDesignationListing] = useState([]);
    const [permission, setPermission] = useState([]);
    const [permissionIds, setPermissionIds] = useState([]);
    const userID = JSON.parse(localStorage.getItem('__assist_erp'));
    const [contactModal, setContactModal] = useState(false);
    const [showContactList, setShowContactList] = useState("");
    const [activeAccordionContactIndex, setActiveAccordionContactIndex] = useState(null)
    const [addressContact, setContactAddress] = useState("");
    const [city, setCity] = useState("");
    const [province, setProvince] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [email, setEmail] = useState("")
    const [primaryContact, setPrimaryContact] = useState(selectContact?.primary_contact || "0");
    const [designationList, setDesignationList] = useState([]);
    const [editAddressContact, setEditContactAddress] = useState(showEditShareHolder?.address || "");
    const [editCity, setEditCity] = useState(showEditShareHolder?.city || "");
    const [editProvince, setEditProvince] = useState(showEditShareHolder?.province || "");
    const [editPostalCode, setEditPostalCode] = useState(showEditShareHolder?.postal_code || "");
    const [editDirAddressContact, setEditDirContactAddress] = useState(showEditDirData?.address || "");
    const [editDirCity, setEditDirCity] = useState(showEditDirData?.city || "");
    const [editDirProvince, setEdiDirtProvince] = useState(showEditDirData?.province || "");
    const [editDirPostalCode, setEditDirPostalCode] = useState(showEditDirData?.postal_code || "");

    // useEffect to update state if `showEditShareHolder` changes
    useEffect(() => {
        if (showEditShareHolder) {
            setEditContactAddress(showEditShareHolder.address || "");
            setEditCity(showEditShareHolder.city || "");
            setEditProvince(showEditShareHolder.province || "");
            setEditPostalCode(showEditShareHolder.postal_code || "");
        }
    }, [showEditShareHolder]);

    useEffect(() => {
        if (showEditDirData) {
            setEditDirContactAddress(showEditDirData.address || "");
            setEditDirCity(showEditDirData.city || "");
            setEdiDirtProvince(showEditDirData.province || "");
            setEditDirPostalCode(showEditDirData.postal_code || "");
        }
    }, [showEditDirData]);



    const handleShareHolderSelectAddress = (selectedAddress) => {
        setEditContactAddress(selectedAddress);

        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: selectedAddress }, (results, status) => {
            if (status === "OK" && results[0]) {
                const addressComponents = results[0].address_components;

                const getComponent = (type) =>
                    addressComponents.find((component) => component.types.includes(type))?.long_name || "";

                const updatedShareHolder = {
                    ...showEditShareHolder,
                    address: selectedAddress,
                    city: getComponent("locality"),
                    province: getComponent("administrative_area_level_1"),
                    postal_code: getComponent("postal_code"),
                };

                // Update local state and `showEditShareHolder`
                setEditCity(updatedShareHolder.city);
                setEditProvince(updatedShareHolder.province);
                setEditPostalCode(updatedShareHolder.postal_code);
                setShowEditShareHolderData(updatedShareHolder); // Update `showEditShareHolder` directly
            }
        });
    };

    const handleDirectorSelectAddress = (selectedAddress) => {
        setEditContactAddress(selectedAddress);

        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: selectedAddress }, (results, status) => {
            if (status === "OK" && results[0]) {
                const addressComponents = results[0].address_components;

                const getComponent = (type) =>
                    addressComponents.find((component) => component.types.includes(type))?.long_name || "";

                const updatedDirector = {
                    ...showEditDirData,
                    address: selectedAddress,
                    city: getComponent("locality"),
                    province: getComponent("administrative_area_level_1"),
                    postal_code: getComponent("postal_code"),
                };

                // Update local state and `showEditShareHolder`
                setEditDirCity(updatedDirector.city);
                setEdiDirtProvince(updatedDirector.province);
                setEditDirPostalCode(updatedDirector.postal_code);
                setShowEditDirData(updatedDirector); // Update `showEditShareHolder` directly
            }
        });
    };
    // Function to check if user has 'task' related permissions or is allowed full access
    const hasAdvancePermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("advance_create");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };
    // Function to check if user has 'task' related permissions or is allowed full access
    const hasAdvanceViewPermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("advance_view");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };
    // Function to check if user has 'task' related permissions or is allowed full access
    const hasAdvanceDeletePermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("advance_delete");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };
    // Function to check if user has 'task' related permissions or is allowed full access
    const hasAdvanceEditPermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("advance_update");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };

    const hasServicePermission = () => {
        const hasTaskPermission = permission?.permissions?.includes("service_create");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id === userID?.user?.id);
        return hasTaskPermission && hasTaskFullAccess;
    };

    // Function to check if user has 'task' related permissions or is allowed full access
    const hasServiceEditPermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("service_update");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };


    // Function to check if user has 'task' related permissions or is allowed full access
    const hasServiceViewPermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("service_view");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };


    // Function to check if user has 'task' related permissions or is allowed full access
    const hasServiceDeletePermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("service_delete");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };

    // Function to check if user has 'task' related permissions or is allowed full access
    const hasAssginePermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("assigne_create");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };

    // Function to check if user has 'task' related permissions or is allowed full access
    const hasAssgineEditPermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("assigne_update");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };


    // Function to check if user has 'task' related permissions or is allowed full access
    const hasAssigneViewPermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("assigne_view");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };


    // Function to check if user has 'task' related permissions or is allowed full access
    const hasAssigneDeletePermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("assigne_delete");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };

    // Function to check if user has 'task' related permissions or is allowed full access
    const hasShareHolderPermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("shareholder_create");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };

    // Function to check if user has 'task' related permissions or is allowed full access
    const hasShareHolderEditPermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("shareholder_update");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };


    // Function to check if user has 'task' related permissions or is allowed full access
    const hasShareHolderViewPermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("shareholder_view");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };


    // Function to check if user has 'task' related permissions or is allowed full access
    const hasShareHolderDeletePermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("shareholder_delete");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };

    // Function to check if user has 'task' related permissions or is allowed full access
    const hasExecutivePermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("director_create");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };

    // Function to check if user has 'task' related permissions or is allowed full access
    const hasExecutiveEditPermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("director_update");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };


    // Function to check if user has 'task' related permissions or is allowed full access
    const hasExecutiveViewPermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("director_view");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };


    // Function to check if user has 'task' related permissions or is allowed full access
    const hasExecutiveDeletePermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("director_delete");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };


    // Function to check if user has 'task' related permissions or is allowed full access
    const hasOperatingPermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("operating_create");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };

    // Function to check if user has 'task' related permissions or is allowed full access
    const hasOperatingEditPermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("operating_update");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };


    // Function to check if user has 'task' related permissions or is allowed full access
    const hasOperatingViewPermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("operating_view");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };


    // Function to check if user has 'task' related permissions or is allowed full access
    const hasOperatingDeletePermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("operating_delete");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };

    // Function to check if user has 'task' related permissions or is allowed full access
    const hasContactPermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("contact_create");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };

    // Function to check if user has 'task' related permissions or is allowed full access
    const hasContactEditPermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("contact_update");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };


    // Function to check if user has 'task' related permissions or is allowed full access
    const hasContactViewPermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("contact_view");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };


    // Function to check if user has 'task' related permissions or is allowed full access
    const hasContactDeletePermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("contact_delete");
        const hasTaskFullAccess = permissionIds && permissionIds.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };





    // Function to validate form inputs
    const validateForm = () => {
        const errors = {};
        if (!showEditOperationData?.name) {
            errors.name = 'Name is required';
        }
        if (!showEditOperationData?.registration_date) {
            errors.registration_date = 'Registration date is required';
        }
        // Add other validation checks as needed
        return errors;
    };

    const validateFormDirector = () => {
        const newErrors = {};
        if (!showEditDirData.first_name) newErrors.first_name = 'required';
        if (!showEditDirData.last_name) newErrors.last_name = 'required';
        if (!showEditDirData.email) newErrors.email = 'required';
        return newErrors;
    }
    const validateFormShareHolder = () => {
        const newErrors = {};
        if (!showEditShareHolder.first_name) newErrors.first_name = 'required';
        if (!showEditShareHolder.last_name) newErrors.last_name = 'required';
        if (!showEditShareHolder.email) newErrors.email = 'required';
        return newErrors;
    }





    const handleChange = (value) => {
        setAddress(value);
        setValue('office_address', value); // Ensure form state is updated
    };

    const handleSelect = async (value) => {
        setAddress(value);
        try {
            const results = await geocodeByAddress(value);
            if (results && results[0]) {
                const formattedAddress = results[0].formatted_address;
                setAddress(formattedAddress); // Update local state
                setValue('office_address', formattedAddress); // Update form state
            } else {
                console.warn('No results found for the selected address.');
            }
        } catch (error) {
            console.error('Error selecting place:', error?.message || 'No message', error?.stack || 'No stack trace');
        }
    };

    const handleAccordionClick = (index) => {
        setActiveAccordionIndex(index); // Update state with clicked index
    };
    const handleAccordionDirectorClick = (index) => {
        setActiveDirectorIndex(index);
        setSelectedDirector(directorData[index]); // Store the selected director's data
        setLinkProfileModal(true); // Open the modal
    };

    const handleAccordionShareHolderClick = (index) => {
        setActiveShareHolderIndex(index);
        setSelectedShareHolder(shareHolderData[index]); // Store the selected director's data
        setLinkShareHolderModal(true); // Open the modal
    };

    const handleAccordionContactClick = (index) => {
        setActiveContactIndex(index);
        setSelectContact(showContactList[index]); // Store the selected director's data
        setLinkContactModal(true); // Open the modal
    };



    // const handleAccordionContactClick = (index) => {
    //     setActiveAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
    // };


    // const handleAccordionDirectorClick = (index) => {
    //     setActiveDirectorIndex(index === activeDirectorIndex ? null : index);
    // };
    const handleModalOpen = (taskData) => {
        setCurrentTask(taskData);
        setShowCustomFormModal(true);
    };
    const handleTabSelect = async (index) => {
        setSelectedTabIndex(index);
        setShowSurvey(true);

        const form = currentTask.links[index];

        const formListingItem = formListing.find(item => item.id === form.id);
        console.log(formListingItem, "formListingItem")

        console.log('Selected form:', form);
        console.log('Form ID:', form?.id);

        if (!form?.id) {
            console.error(`No valid form or ID found for index ${index}`);
            return;
        }

        setSurveyFormId(form.id);
        setSelectedForm(form);

        // Parse the form_data structure
        let surveyData = form.form_data[0];
        console.log(surveyData, "surveyData");

        // Fetch data from the API using fetchSurveyDataForEdit
        let apiData = await fetchSurveyDataForEdit(formListingItem);

        if (!apiData) {
            console.error('Failed to fetch survey data from API.');
            return;
        }

        console.log('API Response Data:', apiData);

        const existingData = apiData.data?.data?.data || {};
        console.log("Existing Data Extracted from API Response:", existingData);

        // Check if there is existing data to decide if this form is in "update" mode
        setIsFormUpdate(Object.keys(existingData).length > 0);

        const surveyModel = new Model({
            title: surveyData.title,
            pages: surveyData.pages.map((page) => ({
                ...page,
                elements: page.elements.map((element) => {
                    const elementName = element.name;
                    const existingValue = existingData[elementName];

                    console.log(`Processing element: ${elementName}, Found value: ${existingValue}`);

                    const updatedElement = {
                        ...element,
                        value: existingValue !== undefined ? existingValue : element.value,
                        defaultValue: existingValue !== undefined ? existingValue : element.defaultValue,
                    };

                    console.log("Updated Element:", updatedElement);

                    return updatedElement;
                }),
            })),
        });

        console.log('Created Survey Model:', surveyModel);

        const updatedSurveyModels = [...surveyModels];
        updatedSurveyModels[index] = surveyModel;
        setSurveyModels(updatedSurveyModels);

        console.log('Updated Survey Models:', updatedSurveyModels);
    };

    const formListingData = () => {
        try {
            http(
                {
                    method: "GET",
                    url: `custom_form/listing`,
                    isSecure: true,

                },
                (res) => {
                    setFormListing(res?.data?.data)
                },
            )
        }
        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    useEffect(() => {

        formListingData();
    }, []);

    const handleCompleteSurvey = (sender, context = 'profile') => {
        try {
            // Capture the filled survey data
            const surveyData = sender.data;

            // Dynamically retrieve module_name based on the context
            const moduleName = context === 'profile'
                ? useProfile?.links?.[0]?.module_name
                : serviceDetail?.links?.[0]?.module_name;

            if (!moduleName) {
                console.error('Module name not found in the given context.');
                return;
            }

            // Determine the URL based on whether the form is being updated or saved for the first time
            const url = isFormUpdate
                ? `custom_form/link/update/${surveyFormId}`
                : `custom_form/link/${surveyFormId}/save`;

            // Make the HTTP request to save the survey data
            http(
                {
                    method: "POST",
                    url: url,
                    isSecure: true,
                    body: {
                        "module_name": moduleName, // Dynamic module name
                        "data": {
                            "data": surveyData // Captured survey data
                        }
                    }
                },
                (res) => {
                    console.log('Survey data saved successfully:', res);
                    if (!isFormUpdate) {
                        // After first save, mark the form as updateable
                        setIsFormUpdate(true);
                    }
                },
                (error) => {
                    console.error('Error saving survey data:', error);
                }
            );
        } catch (error) {
            console.error('Error completing survey:', error);
        }
    };


    const fetchSurveyDataForEdit = async (formListingItem) => {
        try {
            console.log('Fetching data for form ID:', formListingItem?.id); // Debugging: Log the ID being used
            return new Promise((resolve, reject) => {
                http(
                    {
                        method: "GET",
                        url: `custom_form/link/${formListingItem?.id}/edit`,  // Use the id from the formListingItem passed in
                        isSecure: true,
                    },
                    (res) => {
                        console.log(res, "API Response Data");
                        resolve(res?.data);  // Return the nested data object
                        setEditForm(res?.data)
                    },
                    (error) => {
                        console.error('Error fetching survey data for edit:', error);
                        reject(error);
                    }
                );
            });
        } catch (error) {
            console.error('Error fetching survey data for edit:', error);
            return null;
        }
    };
    const showEditProfile = () => {
        http(
            {
                method: "GET",
                url: `corporate/client/${id}/edit`,
                isSecure: true,
            },
            (res) => {
                setEditProfileData(res?.data);
                setEditProfile(true);
                setValue('office_address', res?.data?.office_address); // Set the initial address in the form
                setAddress(res?.data?.office_address);
                // Set form values
                Object.keys(res?.data).forEach((key) => {
                    setValue(key, res?.data[key]);
                });
            },
            (err) => { }
        );
    };


    const updateProfile = (updatedData) => {
        http(
            {
                method: "POST",
                url: `corporate/client/update/${id}`,
                body: updatedData,
                isSecure: true,
            },
            (res) => {
                setEditProfile(false)
            },
            (err) => {
                console.error("Error updating profile:", err);
            }
        );
    };

    const onSubmit = (formData) => {
        // Call the updateProfile function with the formData
        updateProfile(formData);
    };




    useEffect(() => {
        http(
            {
                method: "GET",
                url: `corporate/client/${id}/view`,
                isSecure: true,
            },
            (res) => {
                setUserProfile(res?.data, "dtata");
                localStorage.setItem("CorporateUserData", JSON.stringify(res?.data?.name));
            },
            (err) => { },
        );
    }, []);


    // Corporate Advance Listing Api --->>    



    const fetchAdvData = () => {
        http(
            {
                method: "GET",
                url: `corporate/advance/${id}/listing?page=${page}&limit=${1}`,
                isSecure: true,
            },
            (res) => {
                setAdvanceList(res?.data?.data, "dtata");
            },
            (err) => { },
        );
    }

    useEffect(() => {

        fetchAdvData();
    }, []);

    ////// Coporate Advance Edit Api Calling -->

    const fetchAdvanceData = () => {
        if (advanceList && advanceList.length > 0) {
            http(
                {
                    method: "GET",
                    url: `corporate/advance/${advanceList[0]?.corporate_id}/${advanceList[0]?.id}/edit`,
                    isSecure: true,
                },
                (res) => {
                    setAdvanceDetail(res?.data);
                    Object.keys(res?.data).forEach((key) => {
                        setValue(key, res?.data[key]);
                    });
                },
                (err) => { }
            );
        }
    }


    useEffect(() => {

        fetchAdvanceData()
    }, [advanceList]);


    //// Coporate Advance Update Api calling -->
    const updateAdvance = (updatedData) => {
        http(
            {
                method: "PUT",
                url: `corporate/advance/update/${advanceDetail?.corporate_id}/${advanceDetail?.id}`,
                body: updatedData,
                isSecure: true,
            },
            (res) => {
                setEditAdvanceModel(false);
                // Optionally, you can perform additional actions after the profile is updated
                fetchAdvData();
            },
            (err) => {
                console.error("Error updating profile:", err);
            }
        );
    };
    const onAdvanceSubmit = (formData) => {
        // Call the updateProfile function with the formData
        updateAdvance(formData);


    };


    /// Show Listing Operation Api Calling -->

    const fetchData = async () => {

        try {
            http(
                {
                    method: "GET",
                    url: `corporate/operating/${id}/listing`,
                    isSecure: true,
                },
                (res) => {
                    setOperationData(res?.data?.data, "dtata");
                }

            );
        } catch (error) {

        }
    };



    useEffect(() => {

        fetchData()
    }, [id]);

    /// Edit Operation Api Calling -->

    const editOperationData = async (corporateId, operationId) => {
        if (!hasOperatingEditPermission()) {
            toast.error("You are not authorized to access the resource")
            return;  // Exit early if the user doesn't have permission
        }
        try {
            http(
                {
                    method: "GET",
                    url: `corporate/operating/${corporateId}/${operationId}/edit`,
                    isSecure: true,
                },
                (res) => {
                    setShowEditOperationData(res?.data); // Set data for edit modal
                    setEditOperationModel(true); // Show the edit modal

                }

            );

        } catch (error) {
            console.error('Error fetching edit operation data:', error);
        }
    };

    const handleEditIconClick = (corporateId, operationId) => {
        editOperationData(corporateId, operationId);
    };


    ///// Update Operation Api Calling -->
    const updateOperationData = async (corporateId, operationId) => {
        const validationErrors = validateForm(); // Validate inputs
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors); // Set errors if validation fails
            return; // Exit if validation fails
        }


        try {
            http(
                {
                    method: "PUT",
                    url: `corporate/operating/update/${corporateId}/${operationId}`,
                    isSecure: true,
                    body: {
                        name: showEditOperationData?.name, // Assuming showEditOperationData contains the updated name
                        registration_date: showEditOperationData?.registration_date // Assuming showEditOperationData contains the updated registration_date
                    }
                },
                (res) => {
                    hideEditOperationModel();
                    fetchData();
                }

            );

        } catch (error) {
            console.error('Error fetching edit operation data:', error);
        }
    };



    /// Show Listing Director Api Calling -->

    const fetchDirData = async () => {

        try {
            http(
                {
                    method: "GET",
                    url: `corporate/director/${id}/listing`,
                    isSecure: true,
                },
                (res) => {
                    setDirectorData(res?.data?.data);
                }

            );
        } catch (error) {

        }
    };



    useEffect(() => {
        fetchDirData();
    }, [id]);

    ///// Edit Director Api Calling -->
    const editDirectorData = async (corporateId, operationId) => {
        try {
            http(
                {
                    method: "GET",
                    url: `corporate/director/${corporateId}/${operationId}/edit`,
                    isSecure: true,
                },
                (res) => {
                    setShowEditDirData(res?.data); // Set data for edit modal
                    setPrimaryContact(res?.data?.primary_contact || "0");
                    setEditDirectorModal(true); // Show the edit modal
                }

            );

        } catch (error) {
            console.error('Error fetching edit operation data:', error);
        }
    };

    const handleEditDirectorClick = (corporateId, operationId) => {
        if (!hasExecutiveEditPermission()) {
            toast.error("You are not authorized to access the resource")
            return;  // Exit early if the user doesn't have permission
        }
        editDirectorData(corporateId, operationId);
        setLinkProfileModal(false);
    };

    ///// Edit Director Api Calling -->
    const editShareHolderData = async (corporateId, operationId) => {
        try {
            http(
                {
                    method: "GET",
                    url: `corporate/shareholder/${corporateId}/${operationId}/edit`,
                    isSecure: true,
                },
                (res) => {
                    setShowEditShareHolderData(res?.data); // Set data for edit modal
                    setEditShareHolderModal(true); // Show the edit modal
                }

            );

        } catch (error) {
            console.error('Error fetching edit operation data:', error);
        }
    };

    const handleEditShareHolderClick = (corporateId, operationId) => {
        if (!hasShareHolderEditPermission()) {
            toast.error("You are not authorized to access the resource")
            return;  // Exit early if the user doesn't have permission
        }
        editShareHolderData(corporateId, operationId);
        setLinkShareHolderModal(false);
    };




    // const editContactData = async (contactId) => {
    //     try {
    //         await http(
    //             {
    //                 method: "GET",
    //                 url: `contact/${contactId}/edit`,
    //                 isSecure: true,
    //             },
    //             (res) => {
    //                 const data = res?.data;
    //                 reset({
    //                     first_name: data.first_name,
    //                     last_name: data.last_name,
    //                     email: data.email,
    //                     phone_number: data.phone_number,
    //                     designation_id: data.designation_id,
    //                     primary_contact: data.primary_contact,
    //                     notes: data.notes,
    //                     // Pre-fill address-related fields
    //                     address: data.address,
    //                     city: data.city,
    //                     province: data.province,
    //                     postal_code: data.postal_code,
    //                 });
    //                 setPrimaryContact(data.primary_contact);
    //                 setContactAddress(data.address);
    //                 setCity(data.city);
    //                 setProvince(data.province);
    //                 setPostalCode(data.postal_code);
    //                 handleDesignationList();
    //                 setEditContactModal(true);
    //                 setLinkContactModal(false);
    //             }
    //         );
    //     } catch (error) {
    //         console.error("Error fetching edit contact data:", error);
    //     }
    // };
    const editContactData = async (contactId) => {
        try {
            await http(
                {
                    method: "GET",
                    url: `contact/${contactId}/edit`,
                    isSecure: true,
                },
                (res) => {
                    const data = res?.data;
                    reset({
                        first_name: data.first_name,
                        last_name: data.last_name,
                        email: data.email,
                        phone_number: data.phone_number,
                        designation_id: data.designation_id,
                        primary_contact: data.primary_contact,
                        notes: data.notes,
                        address: data.address,
                        city: data.city,
                        province: data.province,
                        postal_code: data.postal_code,
                    });

                    // Set state for address fields
                    setContactAddress(data.address);
                    setCity(data.city);
                    setProvince(data.province);
                    setPostalCode(data.postal_code);
                    setPrimaryContact(data.primary_contact); // Set primary contact state
                    setEmail(data.email);
                    handleDesignationList();
                    setEditContactModal(true);
                    setLinkContactModal(false);
                }
            );
        } catch (error) {
            console.error("Error fetching edit contact data:", error);
        }
    };
    const onSubmitContact = async (data) => {
        const payload = {
            client_type: selectContact?.client_type,
            company_name: data.company_name || "",
            first_name: data.first_name || "",
            last_name: data.last_name || "",
            phone_number: data.phone_number,
            designation_id: data.designation_id,
            primary_contact: primaryContact,
            address: addressContact,
            city: city,
            province: province,
            postal_code: postalCode,
            notes: data.notes,
            email: email, // Use email state here
        };

        try {
            await http(
                {
                    method: "PUT",
                    url: `contact/update/${selectContact?.id}`,
                    body: payload,
                    isSecure: true,
                },
                (res) => {
                    toast.success(res.message);
                    hideEditContactModal();
                    fetchContactData();
                }
            );
        } catch (error) {
            console.error("Error updating contact:", error);
        }
    };
    const handleSelectAddress = async (value) => {
        setContactAddress(value);
        try {
            const results = await geocodeByAddress(value);
            if (results && results[0]) {
                const addressComponents = results[0].address_components;
                let selectedCity = "";
                let selectedProvince = "";
                let selectedPostalCode = "";

                // Extract city, province, and postal code from address components
                addressComponents.forEach((component) => {
                    if (component.types.includes("locality")) {
                        selectedCity = component.long_name;
                    }
                    if (component.types.includes("administrative_area_level_1")) {
                        selectedProvince = component.short_name;
                    }
                    if (component.types.includes("postal_code")) {
                        selectedPostalCode = component.long_name;
                    }
                });

                // Update the state and form with extracted values
                setCity(selectedCity);
                setProvince(selectedProvince);
                setPostalCode(selectedPostalCode);
                reset({
                    ...getValues(),
                    city: selectedCity,
                    province: selectedProvince,
                    postal_code: selectedPostalCode,
                });
            }
        } catch (error) {
            console.error("Error selecting place:", error);
        }
    };

    // Handle edit icon click
    const handleContactClick = (contactId) => {
        if (!hasContactEditPermission()) {
            toast.error("You are not authorized to access the resource");
            return; // Exit early if the user doesn't have permission
        }
        editContactData(contactId); // Call editContactData with only contactId
    };

    ///// Edit Director Api Calling -->


    // const editDirectarData = (corporateId, operationId) => {
    //     // Find the director data based on the provided IDs
    //     const director = directorData.find(
    //         (d) => d.corporate_id === corporateId && d.id === operationId
    //     );

    //     // Update state with the selected director data
    //     setSelectedDirector(director);
    //     setLinkProfileModal(true); // Show the modal
    // };

    //// Update Director Api Calling -->

    const updateDirectorData = async (corporateId, operationId) => {
        const validationErrors = validateFormDirector(); // Validate inputs
        if (Object.keys(validationErrors).length > 0) {
            setErrorsDir(validationErrors); // Set errors if validation fails
            return; // Exit if validation fails
        }


        try {
            http(
                {
                    method: "PUT",
                    url: `corporate/director/update/${corporateId}/${operationId}`,
                    isSecure: true,
                    body: {
                        first_name: showEditDirData?.first_name, // Assuming showEditDirData contains the updated name
                        middle_name: showEditDirData?.middle_name,
                        last_name: showEditDirData?.last_name,
                        sin: showEditDirData?.sin,
                        date_of_birth: showEditDirData?.date_of_birth,
                        email: showEditDirData?.email,
                        phone_number: showEditDirData?.phone_number,
                        address: showEditDirData?.address,
                        city: showEditDirData?.city,
                        province: showEditDirData?.province,
                        postal_code: showEditDirData?.postal_code,
                        shareholder_part: showEditDirData?.shareholder_part,
                        primary_contact: showEditDirData?.primary_contact,
                        designation_id: showEditDirData?.designation_id


                    }
                },
                (res) => {
                    setEditDirectorModal();
                    fetchDirData();
                }

            );

        } catch (error) {
            console.error('Error fetching edit operation data:', error);
        }
    };


    const updateShareHolderData = async (corporateId, operationId) => {
        const validationErrors = validateFormShareHolder(); // Validate inputs
        if (Object.keys(validationErrors).length > 0) {
            setErrorShareHolder(validationErrors); // Set errors if validation fails
            return; // Exit if validation fails
        }


        try {
            http(
                {
                    method: "PUT",
                    url: `corporate/shareholder/update/${corporateId}/${operationId}`,
                    isSecure: true,
                    body: {
                        first_name: showEditShareHolder?.first_name, // Assuming showEditDirData contains the updated name
                        middle_name: showEditShareHolder?.middle_name,
                        last_name: showEditShareHolder?.last_name,
                        sin: showEditShareHolder?.sin,
                        date_of_birth: showEditShareHolder?.date_of_birth,
                        email: showEditShareHolder?.email,
                        phone_number: showEditShareHolder?.phone_number,
                        address: showEditShareHolder?.address,
                        city: showEditShareHolder?.city,
                        province: showEditShareHolder?.province,
                        postal_code: showEditShareHolder?.postal_code,
                        shareholder_part: showEditShareHolder?.shareholder_part,
                        primary_contact: showEditShareHolder?.primary_contact,
                        // designation_id: showEditShareHolder?.designation_id,


                    }
                },
                (res) => {
                    setEditShareHolderModal();
                    fetchShareHolderData();
                }

            );

        } catch (error) {
            console.error('Error fetching edit shareHolder data:', error);
        }
    };


    /// Show Service List Api callig -->    
    const fetchSerData = () => {
        http(
            {
                method: "GET",
                url: `corporate/service/${id}/listing?page=${servicePage}&limit=${1}`,
                isSecure: true,
            },
            (res) => {
                setServiceList(res?.data?.data);
            },
            (err) => { },
        );
    }


    useEffect(() => {
        fetchSerData();
    }, []);

    /// Edit Service Api Calling -->
    const editServiceData = () => {
        if (serviceList && serviceList.length > 0) {
            http(
                {
                    method: "GET",
                    url: `corporate/service/${serviceList[0]?.corporate_id}/${serviceList[0]?.id}/edit`,
                    isSecure: true,
                },
                (res) => {
                    setServiceDetail(res?.data);
                    Object.keys(res?.data).forEach((key) => {
                        setValue(key, res?.data[key]);
                    });
                },
                (err) => { },
            );
        }
    }

    useEffect(() => {
        editServiceData();

    }, [serviceList]);

    // Udapte Service Api Calling -->
    const updateService = (updatedData) => {
        http(
            {
                method: "PUT",
                url: `corporate/service/update/${serviceDetail?.corporate_id}/${serviceDetail?.id}`,
                body: updatedData,
                isSecure: true,
            },
            (res) => {
                setEditServiceModal(false);
                toast.success("Update Service Successfully")
                fetchSerData();
            },
            (err) => {
                console.error("Error updating profile:", err);
            }
        );
    };
    const onServiceSubmit = (formData) => {
        // Call the updateProfile function with the formData
        updateService(formData);
    };

    /// Show Assigned List Api callig -->    
    const fetchAsiData = () => {
        http(
            {
                method: "GET",
                url: `corporate/assign/${id}/listing`,
                isSecure: true,
            },
            (res) => {
                setAssignedList(res?.data?.data);
            },
            (err) => { },
        );
    }
    useEffect(() => {
        fetchAsiData();

    }, []);

    // Edit Assigned Api Calling -->

    const editAssignedSubmit = (assignedItem) => {
        if (!hasAssgineEditPermission()) {
            toast.error("You are not authorized to access the resource")
            return;  // Exit early if the user doesn't have permission
        }
        http(
            {
                method: "GET",
                url: `corporate/assign/${assignedItem?.corporate_id}/${assignedItem?.id}/edit`,
                isSecure: true,
            },
            (res) => {
                setEditAssignedDetail(res?.data);
                setEditAssignedModal(true);
                setValue("staff_id", res?.data?.staff_id);
                setValue("assistant_id", res?.data?.assistant_id);
                setValue("bookkeeper_id", res?.data?.bookkeeper_id);
                setValue("communicator_id", res?.data?.communicator_id);

            },
            (err) => { },
        );
    }



    const updateAssignedSubmit = async (data) => {
        try {

            http(
                {
                    method: "PUT",
                    url: `corporate/assign/update/${editAssignedDetail?.corporate_id}/${editAssignedDetail?.id}`,
                    isSecure: true,
                    body: {
                        staff_id: data.staff_id,
                        assistant_id: data.assistant_id,
                        bookkeeper_id: data.bookkeeper_id,
                        communicator_id: data.communicator_id,
                    }
                },
                (res) => {
                    hideEditAssigendModal();
                    toast.success("Update assigned member Successfully");
                    fetchAsiData();
                },
            );
        } catch (error) {
            console.error(error);
        }
    };


    ///HST Reture LIST Api Calling -->
    const showHstList = () => {
        http(
            {
                method: "GET",
                url: `hst_return/listing`,
                isSecure: true,
            },
            (res) => {
                setHstList(res?.data?.data); // Update hstList with the fetched data
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showHstList();
    }, []);

    /// HST Style Api Calling -->
    const showHstStyle = () => {
        http(
            {
                method: "GET",
                url: `hst_style/listing`,
                isSecure: true,
            },
            (res) => {
                setHstStyleList(res?.data?.data); // Update hstList with the fetched data
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showHstStyle();
    }, []);

    /// Arc Setup Listing Api Calling -->
    const showArsetupList = () => {
        http(
            {
                method: "GET",
                url: `arc_setup/listing`,
                isSecure: true,
            },
            (res) => {
                setArcSetupList(res?.data?.data); // Update hstList with the fetched data
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showArsetupList();
    }, []);

    /// Hst Open Listing Api Calling -->
    const showHstOpenList = () => {
        http(
            {
                method: "GET",
                url: `hst_open/listing`,
                isSecure: true,
            },
            (res) => {
                setHstOpenList(res?.data?.data); // Update hstList with the fetched data
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showHstOpenList();
    }, []);


    /// PayRoll Account Listing Api Calling -->
    const showPayRollList = () => {
        http(
            {
                method: "GET",
                url: `payroll_account/listing`,
                isSecure: true,
            },
            (res) => {
                setPayRollList(res?.data?.data); // Update hstList with the fetched data
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showPayRollList();
    }, []);

    /// Arc Access Listing Api Calling -->
    const showArcAccessList = () => {
        http(
            {
                method: "GET",
                url: `arc_access/listing`,
                isSecure: true,
            },
            (res) => {
                setArcAccessList(res?.data?.data); // Update hstList with the fetched data
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showArcAccessList();
    }, []);

    /// Advanse Fee Listing Api Calling -->
    const showAdvanceList = () => {
        http(
            {
                method: "GET",
                url: `advance_fee/listing`,
                isSecure: true,
            },
            (res) => {
                setAdvanceFeeList(res?.data?.data); // Update hstList with the fetched data
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showAdvanceList();
    }, []);

    /// Advanse Fee Listing Api Calling -->
    const showAgreementList = () => {
        http(
            {
                method: "GET",
                url: `agreement_sign/listing`,
                isSecure: true,
            },
            (res) => {
                setAgreementList(res?.data?.data); // Update hstList with the fetched data
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showAgreementList();
    }, []);



    /// Arc Grid Listing Api Calling -->
    const showArcGridList = () => {
        http(
            {
                method: "GET",
                url: `arc_grid/listing`,
                isSecure: true,
            },
            (res) => {
                setArcGridList(res?.data?.data); // Update hstList with the fetched data
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showArcGridList();
    }, []);


    /// Agreement Reason Listing Api Calling -->
    const showAgreementReasonList = () => {
        http(
            {
                method: "GET",
                url: `agreement_reason/listing`,
                isSecure: true,
            },
            (res) => {
                setAgrementReasonList(res?.data?.data); // Update hstList with the fetched data
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showAgreementReasonList();
    }, []);



    useEffect(() => {
        // Fetch staff data from API
        fetchStaffData();
    }, []);

    const fetchStaffData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `staff/listing`,
                    isSecure: true,

                },
                (res) => {
                    setStaffList(res?.data?.data);
                    allStaffApis['staff_id'] = res?.data?.data;
                    setAllStaffApi(allStaffApis);

                },
            )
        }

        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };

    useEffect(() => {
        // Fetch staff data from API
        fetchAssisgnedData();
    }, []);

    const fetchAssisgnedData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `assistant/listing`,
                    isSecure: true,

                },
                (res) => {
                    setAssignedListNew(res?.data?.data);
                    allStaffApis['assistant_id'] = res?.data?.data;
                    setAllStaffApi(allStaffApis);
                },
            )
        }

        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };


    useEffect(() => {
        // Fetch staff data from API
        fetchBookeeperData();
    }, []);

    const fetchBookeeperData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `bookkeeper/listing`,
                    isSecure: true,

                },
                (res) => {
                    setBookeeperList(res?.data?.data);
                    allStaffApis['bookkeeper_id'] = res?.data?.data;
                    setAllStaffApi(allStaffApis);
                },
            )
        }

        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };


    useEffect(() => {
        // Fetch staff data from API
        fetchCommuniData();
    }, []);

    const fetchCommuniData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `communicator/listing`,
                    isSecure: true,

                },
                (res) => {
                    setCommuniList(res?.data?.data);
                    allStaffApis['communicator_id'] = res?.data?.data;
                    setAllStaffApi(allStaffApis);
                },
            )
        }

        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };

    ///Business Type LIST Api Calling -->
    const showHsBusinessTypeList = () => {
        http(
            {
                method: "GET",
                url: `business-type/listing`,
                isSecure: true,
            },
            (res) => {
                setBusinessList(res?.data?.data); // Update hstList with the fetched data
                allAdvanceApi['businesstype_id'] = res?.data?.data;
                setAllAdvanceApi(allAdvanceApi);
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showHsBusinessTypeList();
    }, []);

    ///Business Nature LIST Api Calling -->
    const showHsBusinessNatureList = () => {
        http(
            {
                method: "GET",
                url: `business-nature/listing`,
                isSecure: true,
            },
            (res) => {
                setBusinessNatureList(res?.data?.data); // Update hstList with the fetched data
                allAdvanceApi['businessnature_id'] = res?.data?.data;
                setAllAdvanceApi(allAdvanceApi);
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showHsBusinessNatureList();
    }, []);

    ///Business category LIST Api Calling -->
    const showBusinessCateList = () => {
        http(
            {
                method: "GET",
                url: `business-category/listing`,
                isSecure: true,
            },
            (res) => {
                setBusinessCateList(res?.data?.data); // Update hstList with the fetched data
                allAdvanceApi['businesscategory_id'] = res?.data?.data;
                setAllAdvanceApi(allAdvanceApi);


            },
            (err) => { },
        );
    };
    useEffect(() => {
        showBusinessCateList();
    }, []);

    ///Business Data Entery Style LIST Api Calling -->
    const showBusinessEnteryList = () => {
        http(
            {
                method: "GET",
                url: `data_entry_style/listing`,
                isSecure: true,
            },
            (res) => {
                setBusinessEnteryStyleList(res?.data?.data); // Update hstList with the fetched data
                allAdvanceApi['data_entry_style_id'] = res?.data?.data;
                setAllAdvanceApi(allAdvanceApi);
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showBusinessEnteryList();
    }, []);

    ///Business Planing LIST Api Calling -->
    const showBusinessPlaningList = () => {
        http(
            {
                method: "GET",
                url: `business_planning/listing`,
                isSecure: true,
            },
            (res) => {
                setBusinessPlaningList(res?.data?.data); // Update hstList with the fetched data
                allAdvanceApi['business_planing_id'] = res?.data?.data;
                setAllAdvanceApi(allAdvanceApi);
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showBusinessPlaningList();
    }, []);


    const deleteDirector = () => {
        if (!hasExecutiveDeletePermission()) {
            toast.error("You are not authorized to access the resource")
            return;  // Exit early if the user doesn't have permission
        }
        try {
            http(
                {
                    method: "DELETE",
                    url: `corporate/director/${selectedDirector?.id}/delete`,
                    isSecure: true,
                },
                (res) => {
                    // Handle the response if needed, e.g., showing a success message
                    console.log('Director deleted successfully:', res);

                    // Close the modal after successful deletion
                    handleCloseModal();
                    fetchDirData();
                },
            );
        } catch (error) {
            console.error('Error deleting director:', error);
        }
    };
    const deleteShareHolder = () => {

        if (!hasShareHolderDeletePermission()) {
            toast.error("You are not authorized to access the resource")
            return;  // Exit early if the user doesn't have permission
        }
        try {
            http(
                {
                    method: "DELETE",
                    url: `corporate/shareholder/${selectedShareHolder?.id}/delete`,
                    isSecure: true,
                },
                (res) => {
                    // Handle the response if needed, e.g., showing a success message
                    toast.success(res.message);

                    // Close the modal after successful deletion
                    handleCloseShareHolderModal();
                    fetchShareHolderData();
                },
            );
        } catch (error) {
            console.error('Error deleting director:', error);
        }
    };


    const deleteAssigne = (id) => {

        if (!hasAssigneDeletePermission()) {
            toast.error("You are not authorized to access the resource")
            return;  // Exit early if the user doesn't have permission
        }
        try {
            http(
                {
                    method: "DELETE",
                    url: `corporate/assign/${id}/delete`, // Use the dynamic ID here
                    isSecure: true,
                },
                (res) => {
                    toast.success(res.message);
                    fetchAsiData();
                },
            );
        } catch (error) {
            console.error('Error deleting assigned item:', error);
        }
    };

    const deleteAdvance = (id) => {
        // Check if user has permission to delete
        if (!hasAdvanceDeletePermission()) {
            toast.error("You are not authorized to access the resource");
            return;  // Exit early if the user doesn't have permission
        }

        try {
            http(
                {
                    method: "DELETE",
                    url: `corporate/advance/${id}/delete`, // Use the dynamic ID here
                    isSecure: true,
                },
                (res) => {
                    toast.success(res.message);
                    // Remove the deleted item from the state to update the UI
                    setAdvanceList(advanceList.filter((advance) => advance.id !== id));
                    // Optionally fetch updated data from API
                    // fetchAsiData();
                }
            );
        } catch (error) {
            console.error('Error deleting assigned item:', error);
            toast.error("Failed to delete the advance. Please try again.");
        }
    };


    const deleteService = (id) => {
        // Check if user has permission to delete
        if (!hasServiceDeletePermission()) {
            toast.error("You are not authorized to access the resource");
            return;  // Exit early if the user doesn't have permission
        }

        try {
            http(
                {
                    method: "DELETE",
                    url: `corporate/service/${id}/delete`, // Use the dynamic ID here
                    isSecure: true,
                },
                (res) => {
                    toast.success(res.message);
                    // Remove the deleted item from the state to update the UI
                    setServiceList(serviceList.filter((service) => service.id !== id));
                    // Optionally fetch updated data from API
                    // fetchAsiData();
                }
            );
        } catch (error) {
            console.error('Error deleting service:', error);
            toast.error("Failed to delete the service. Please try again.");
        }
    };

    const deleteOperating = (id) => {
        // Check if user has permission to delete
        if (!hasOperatingDeletePermission()) {
            toast.error("You are not authorized to access the resource");
            return;  // Exit early if the user doesn't have permission
        }

        try {
            http(
                {
                    method: "DELETE",
                    url: `corporate/operating/${id}/delete`, // Use the dynamic ID here
                    isSecure: true,
                },
                (res) => {
                    toast.success(res.message);
                    // Remove the deleted item from the state to update the UI
                    setOperationData(operationData.filter((operation) => operation.id !== id));
                }
            );
        } catch (error) {
            console.error('Error deleting operating item:', error);
            toast.error("Failed to delete the operating item. Please try again.");
        }
    };

    const deleteContact = () => {

        if (!hasContactDeletePermission()) {
            toast.error("You are not authorized to access the resource")
            return;  // Exit early if the user doesn't have permission
        }
        try {
            http(
                {
                    method: "DELETE",
                    url: `contact/delete/${selectContact?.id}`,
                    isSecure: true,
                },
                (res) => {
                    // Handle the response if needed, e.g., showing a success message
                    toast.success(res.message);

                    // Close the modal after successful deletion
                    handleCloseContactModal();
                    fetchContactData();
                    // fetchShareHolderData();
                },
            );
        } catch (error) {
            console.error('Error deleting director:', error);
        }
    };


    const onHideHandler = () => {
        setEditProfile(false);

    };
    const showOpreationModal = () => {

        if (hasOperatingPermission()) {
            // Open the contact modal if permission is granted
            setOperationModal(true);
        } else {
            // Show a toast message if permission is denied
            toast.error("You are not authorized to access the resource")
        }
    };
    const showAdvanceModal = () => {
        if (hasAdvancePermission()) {
            // Open the contact modal if permission is granted
            setAdvanceModal(true);
        } else {
            // Show a toast message if permission is denied
            toast.error("You are not authorized to access the resource")
        }
    };
    const showDirectorModal = () => {
        if (hasExecutivePermission()) {
            // Open the contact modal if permission is granted
            setDirectorModal(true);
        } else {
            // Show a toast message if permission is denied
            toast.error("You are not authorized to access the resource")
        }

    }
    const showShareHolderModal = () => {
        if (hasShareHolderPermission()) {
            // Open the contact modal if permission is granted
            setShareHolderModal(true);
        } else {
            // Show a toast message if permission is denied
            toast.error("You are not authorized to access the resource")
        }

    }

    const showServicesModal = () => {
        if (hasServicePermission()) {
            setServicesModal(true);
        } else {
            toast.error("You are not authorized to access the resource");
        }
    };

    const showAssigendModal = () => {
        if (hasAssginePermission()) {
            // Open the contact modal if permission is granted
            setAssigentModal(true);
        } else {
            // Show a toast message if permission is denied
            toast.error("You are not authorized to access the resource")
        }

    }

    const showContactModal = () => {
        if (hasContactPermission()) {
            // Open the contact modal if permission is granted
            setContactModal(true);
        } else {
            // Show a toast message if permission is denied
            toast.error("You are not authorized to access the resource")
        }

    }

    const showUploadModal = () => {
        setUploadModal(true);
    }
    const showEditAdvanceModel = () => {
        if (hasAdvanceEditPermission()) {
            // Open the contact modal if permission is granted
            setEditAdvanceModel(true);
        } else {
            // Show a toast message if permission is denied
            toast.error("You are not authorized to access the resource")
        }
    }
    const hideEditAdvanceModel = () => {
        setEditAdvanceModel(false);
    }
    const hideEditOperationModel = () => {
        setEditOperationModel(false);
    }
    const hideEditDirectModal = () => {
        setEditDirectorModal(false);
    }
    const hideEditShareModelModal = () => {
        setEditShareHolderModal(false);
    }
    const hideEditContactModal = () => {
        setEditContactModal(false);
    }
    const showEditServiceModal = () => {
        if (hasServiceEditPermission()) {
            // Open the contact modal if permission is granted
            setEditServiceModal(true);
        } else {
            // Show a toast message if permission is denied
            toast.error("You are not authorized to access the resource")
        }
    }
    const hideEditServiceModal = () => {
        setEditServiceModal(false);
    }

    const hideEditAssigendModal = () => {

        setEditAssignedModal(false);
    }
    // Function to toggle the visibility of SIN number
    const toggleDirectorSinVisibility = () => {
        setIsDirectorSinSinVisible(!isDirectorSinVisible); // Toggle the state
    };

    const toggleShareHolderSinVisibility = () => {
        setIsShareHolderSinVisible(!isShareHolderSinVisiable); // Toggle the state
    };


    const updatePrimaryData = async (businessID, directorID) => {
        try {
            http(
                {
                    method: "POST",
                    url: `corporate/director/make-primary/${businessID}/${directorID}`,
                    isSecure: true,
                    body: {
                        primary_contact: "1",
                    },
                },
                (res) => {
                    fetchDirData();
                }
            );
        } catch (error) {
            console.error('Error updating primary contact:', error);
        }
    };

    const updateShareHolderPrimaryData = async (businessID, directorID) => {
        try {
            http(
                {
                    method: "POST",
                    url: `corporate/shareholder/make-primary/${businessID}/${directorID}`,
                    isSecure: true,
                    body: {
                        primary_contact: "1",
                    },
                },
                (res) => {
                    fetchShareHolderData();
                }
            );
        } catch (error) {
            console.error('Error updating primary contact:', error);
        }
    };

    // Function to close the modal
    const handleCloseModal = () => {
        setLinkProfileModal(false);
        setSelectedDirector(null);

    };
    const handleCloseShareHolderModal = () => {
        setLinkShareHolderModal(false);
        setSelectedShareHolder(null);

    };

    const handleCloseContactModal = () => {
        setLinkContactModal(false);
        setSelectContact(null);

    };

    const fetchShareHolderData = async () => {

        try {
            http(
                {
                    method: "GET",
                    url: `corporate/shareholder/${id}/listing`,
                    isSecure: true,
                },
                (res) => {
                    setShareHolderData(res?.data?.data);
                }

            );
        } catch (error) {

        }
    };


    const fetchContactData = () => {
        http(
            {
                method: "GET",
                url: `contact/business/listing`,
                isSecure: true,
            },
            (res) => {

                setShowContactList(res.data?.data);

            }

        );
    }

    const handleDesignationList = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `designation/listing`,
                    isSecure: true,
                },
                (res) => {
                    setDesignationList(res?.data?.data);
                },
            )
        }
        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };

    useEffect(() => {
        fetchContactData();
    }, []);



    useEffect(() => {
        fetchShareHolderData();
    }, [id]);

    useEffect(() => {
        // Fetch permission data when the component mounts
        handleDesignationData(setDesignationListing);

    }, []);

    useEffect(() => {
        // Fetch permission data when the component mounts
        handlePermissionData(setPermission);
        handlePermissionIdsData(setPermissionIds)
    }, []);
    useEffect(() => {
        if (editContactModal && selectContact) {
            // Set default values for the form fields using `setValue`
            setValue("company_name", selectContact.company_name || "");

        }
    }, [editContactModal, selectContact, setValue]);
    return (
        <>

            <div className="right_section font-poppins">
                <div className="clients-detail-area py-3">
                    <><BusinessTabs /></>

                    <div className="row tabs-content">
                        <div className="col-md-12 tab-content p-0">

                            {/* <!-- HOME TAB START --> */}
                            <div className="tab-pane active" id="businesshome">
                                <div className="row mt-2 justify-content-between">
                                    <div className="col-md-6 left_head">
                                        <h4 className="fs-20 fw-semibold mt-3">Profile</h4>
                                    </div>
                                    <div className="col-md-6 mt-2 right_head">
                                        <ul className="ps-0 d-flex align-items-center gap-2 justify-content-end">
                                            <li
                                                onClick={() => handleModalOpen(useProfile)}
                                            >
                                                <span className="file-form"
                                                //  style={style}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12.599" height="13.999" viewBox="0 0 12.599 13.999">
                                                        <path id="Path_506" data-name="Path 506" d="M402.367,1279h5.866a3.067,3.067,0,0,1,3.366,3.381v7.231a3.056,3.056,0,0,1-3.366,3.388h-5.866a3.072,3.072,0,0,1-3.367-3.388v-7.231A3.083,3.083,0,0,1,402.367,1279Zm.189,3.262v-.007h2.092a.541.541,0,0,1,.388.161.549.549,0,0,1,0,.777.541.541,0,0,1-.388.161h-2.092a.546.546,0,0,1,0-1.092Zm0,4.256h5.488a.546.546,0,0,0,0-1.092h-5.488a.546.546,0,0,0,0,1.092Zm0,3.2h5.488a.531.531,0,0,0,.35-.182.55.55,0,0,0,0-.735.531.531,0,0,0-.35-.182h-5.488a.5.5,0,0,0-.3.056.5.5,0,0,0-.225.2.56.56,0,0,0,0,.588.541.541,0,0,0,.525.252Z" transform="translate(-399 -1279)" fill="#202529" fillRule="evenodd" />
                                                    </svg>
                                                    Fill Required Forms
                                                </span>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="col-md-12 mt-4">
                                        <div className="profile-box position-relative">
                                            <div className="login-box d-flex align-items-center position-absolute top-0 start-0">
                                                <span></span>
                                                <p className="ms-2 fs-14">Active</p>
                                            </div>
                                            <div className="position-absolute start-0 end-0 bottom-0">
                                                <div className="position-relative p-detail-box px-4 py-3 d-flex align-items-center justify-content-between flex-wrap align-items-center">
                                                    <div className="left-prof d-flex align-items-center mt-2">
                                                        <div className="dp-box me-2">
                                                            <img src={require("../../../assets/images/dp.jpg")} alt="" />
                                                        </div>
                                                        <div className="dp-detail">
                                                            <h5 className="text-capitalize">{useProfile?.name}</h5>
                                                            <span className="d-flex align-items-center mt-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18.333" viewBox="0 0 20 18.333">
                                                                    <path id="Path_3524" data-name="Path 3524" d="M468.939,1224a5,5,0,0,1,3.576,1.507,5.182,5.182,0,0,1,1.485,3.636v8.046a5.182,5.182,0,0,1-1.485,3.636,5,5,0,0,1-3.576,1.507H459.06a5,5,0,0,1-3.575-1.507A5.183,5.183,0,0,1,454,1237.19v-8.046a5.212,5.212,0,0,1,.385-1.966,5.139,5.139,0,0,1,2.739-2.791,5.056,5.056,0,0,1,1.936-.387Zm1.591,6.661.08-.082a.8.8,0,0,0-.011-1.019.825.825,0,0,0-.529-.265.759.759,0,0,0-.3.041.857.857,0,0,0-.26.163L465,1233.167a1.547,1.547,0,0,1-2,0l-4.5-3.667a.771.771,0,0,0-.512-.153.739.739,0,0,0-.486.224.794.794,0,0,0-.073,1.018l.131.132,4.55,3.616a3.1,3.1,0,0,0,3.909,0Z" transform="translate(-454 -1224)" fill="#fff" fillRule="evenodd" />
                                                                </svg>

                                                                <p className="ms-2">{useProfile?.email}</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="right-prof mt-2 ms-0">
                                                        <ul className="d-flex align-items-center">
                                                            <li className="d-flex align-items-center gap-2 px-4">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 75.789 60">
                                                                    <g id="folder" transform="translate(0 -53.334)">
                                                                        <path id="Path_3646" data-name="Path 3646" d="M82.948,53.334H29.264a7.875,7.875,0,0,0-7.819,8.921,1.579,1.579,0,0,0,2.365,1.159,4.472,4.472,0,0,1,2.3-.606H37.348a4.762,4.762,0,0,1,4.491,3.215l.268.865A7.932,7.932,0,0,0,49.6,72.281H86.106a4.736,4.736,0,0,1,2.359.632,1.582,1.582,0,0,0,2.378-1.364V61.229A7.9,7.9,0,0,0,82.948,53.334Z" transform="translate(-18.211)" fill="#ffa000" />
                                                                        <path id="Path_3647" data-name="Path 3647" d="M71.852,106.54a7.848,7.848,0,0,0-3.957-1.067H31.389a4.762,4.762,0,0,1-4.491-3.215l-.268-.865A7.932,7.932,0,0,0,19.137,96H7.895a7.555,7.555,0,0,0-3.865,1.02A7.876,7.876,0,0,0,0,103.894v37.895a7.9,7.9,0,0,0,7.895,7.895h60a7.9,7.9,0,0,0,7.895-7.895V113.367A7.841,7.841,0,0,0,71.852,106.54Z" transform="translate(0 -36.349)" fill="#ffc107" />
                                                                    </g>
                                                                </svg>
                                                                <p className="fs-18">{useProfile?.folder_number}</p>
                                                            </li>
                                                            <li className="d-flex align-items-center gap-2 px-4">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16.849" height="20" viewBox="0 0 16.849 20">
                                                                    <path id="Path_3527" data-name="Path 3527" d="M343.5,1287.244a8.424,8.424,0,0,1,16.849,0,10.242,10.242,0,0,1-2.211,6.234,21.807,21.807,0,0,1-5.491,5.094,1.179,1.179,0,0,1-1.446,0,21.27,21.27,0,0,1-5.491-5.094A10.247,10.247,0,0,1,343.5,1287.244Zm5.643.258a2.8,2.8,0,0,0,.469,1.546,2.78,2.78,0,0,0,4.881-2.607,2.7,2.7,0,0,0-1.024-1.249,2.782,2.782,0,0,0-4.326,2.309Z" transform="translate(-343.5 -1278.82)" fill="#fff" fillRule="evenodd" />
                                                                </svg>
                                                                <p className="fs-18">{useProfile?.office_address}</p>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                </div>
                                                <a onClick={() => showEditProfile()}>
                                                    <div className="write-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="58" height="59" viewBox="0 0 58 59">
                                                            <defs>
                                                                <filter id="Rectangle_241" x="0" y="0" width="58" height="59" filterUnits="userSpaceOnUse">
                                                                <feOffset dy="10" input="SourceAlpha"/>
                                                                <feGaussianBlur stdDeviation="3" result="blur"/>
                                                                <feFlood flood-opacity="0.161"/>
                                                                <feComposite operator="in" in2="blur"/>
                                                                <feComposite in="SourceGraphic"/>
                                                                </filter>
                                                            </defs>
                                                            <g id="Group_678" data-name="Group 678" transform="translate(-1819 -366)">
                                                                <g transform="matrix(1, 0, 0, 1, 1819, 366)" filter="url(#Rectangle_241)">
                                                                <rect id="Rectangle_241-2" data-name="Rectangle 241" width="40" height="40" rx="12" transform="translate(9)" fill="#fff"/>
                                                                </g>
                                                                <g id="write" transform="translate(1830.001 368)">
                                                                <path id="Path_3528" data-name="Path 3528" d="M8.427,29.354l-.42,3.473a1.044,1.044,0,0,0,1.04,1.182A1.026,1.026,0,0,0,9.181,34l3.473-.42a3.31,3.31,0,0,0,1.928-.947l9-9a.417.417,0,0,0,0-.589l-4.618-4.622a.417.417,0,0,0-.589,0l-9,9a3.31,3.31,0,0,0-.947,1.928Z" transform="translate(0 -6.009)" fill="#024fc5"/>
                                                                <path id="Path_3529" data-name="Path 3529" d="M44.186,10.787,42.032,8.633a2.16,2.16,0,0,0-3.055,0l-2.022,2.023a.417.417,0,0,0,0,.589l4.62,4.62a.417.417,0,0,0,.589,0l2.023-2.023A2.16,2.16,0,0,0,44.186,10.787Z" transform="translate(-16.82 0)" fill="#024fc5"/>
                                                                <path id="Path_3530" data-name="Path 3530" d="M40.25,52.833A.833.833,0,0,0,39.417,52H29.833a.833.833,0,0,0,0,1.667h9.583A.833.833,0,0,0,40.25,52.833Z" transform="translate(-12.251 -25.667)" fill="#024fc5"/>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <div className="assig-member p-4 bg-white shadow-lg rounded-4">
                                            <div className="prof-insides">
                                                <div className="d-flex align-items-center justify-content-between" onClick={() => showOpreationModal()}>
                                                    <h6 className="fs-20 fw-semibold" >Operating Details</h6>
                                                    <a ><svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                                        <path id="Path_3531" data-name="Path 3531" d="M15,1A14,14,0,1,0,29,15,14,14,0,0,0,15,1Zm6.364,15.273H16.273v5.091a1.273,1.273,0,1,1-2.545,0V16.273H8.636a1.273,1.273,0,1,1,0-2.545h5.091V8.636a1.273,1.273,0,1,1,2.545,0v5.091h5.091a1.273,1.273,0,1,1,0,2.545Z" transform="translate(-1 -1)" fill="#212529" />
                                                    </svg>
                                                    </a>
                                                </div>
                                            </div>
                                            <div>
                                                {hasOperatingViewPermission() ? (
                                                    operationData.length === 0 ? (
                                                        <div className="row contact-info mt-2 position-relative">
                                                            <div className="col-md-12 other-info-box mt-2">
                                                                <h4 className="fs-14 text-danger text-capitalize text-center">No Data found</h4>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        operationData.map((operation, index) => (
                                                            <div key={index} className="operator-drop mt-3">
                                                                <div className="accordion mb-2" id={`accordion${index}`}>
                                                                    <div className="accordion-item position-relative" id={`heading${index}`}>
                                                                        <h2 className="accordion-header fs-18 position-relative">
                                                                            <button
                                                                                className="accordion-button"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target={`#collapse${index}`}
                                                                                aria-expanded={activeAccordionIndex === index ? "true" : "false"}
                                                                                aria-controls={`collapse${index}`}
                                                                                onClick={() => handleAccordionClick(index)}
                                                                            >
                                                                                Business Operating {operation?.name}
                                                                                <div>
                                                                                    <a className="me-3" onClick={() => deleteOperating(operation.id)} >
                                                                                        {/* Uncomment and replace with actual function when needed */}


                                                                                        <svg
                                                                                            width="18"
                                                                                            height="18"
                                                                                            version="1.1"
                                                                                            viewBox="0 0 14 18"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                        >
                                                                                            <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
                                                                                                <g fill="#000000" id="Core" transform="translate(-299.000000, -129.000000)">
                                                                                                    <g id="delete" transform="translate(299.000000, 129.000000)">
                                                                                                        <path
                                                                                                            d="M1,16 C1,17.1 1.9,18 3,18 L11,18 C12.1,18 13,17.1 13,16 L13,4 L1,4 L1,16 L1,16 Z M14,1 L10.5,1 L9.5,0 L4.5,0 L3.5,1 L0,1 L0,3 L14,3 L14,1 L14,1 Z"
                                                                                                            id="Shape"
                                                                                                        />
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </svg>
                                                                                    </a>
                                                                                    <a
                                                                                        className="position-absolute edt_icon"
                                                                                        onClick={() => handleEditIconClick(operation.corporate_id, operation.id)}
                                                                                    >
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 28.001 28">
                                                                                            <g id="write" transform="translate(-7.998 -8)">
                                                                                                <path id="Path_3528" data-name="Path 3528" d="M8.6,33.775l-.587,4.863a1.462,1.462,0,0,0,1.457,1.654,1.436,1.436,0,0,0,.186-.012l4.863-.587a4.634,4.634,0,0,0,2.7-1.326l12.6-12.6a.583.583,0,0,0,0-.825l-6.465-6.47a.583.583,0,0,0-.825,0l-12.6,12.6a4.634,4.634,0,0,0-1.326,2.7Z" transform="translate(0 -4.292)" fill="#202529" />
                                                                                                <path id="Path_3529" data-name="Path 3529" d="M47.127,11.9,44.112,8.886a3.023,3.023,0,0,0-4.277,0L37,11.718a.583.583,0,0,0,0,.825l6.468,6.468a.583.583,0,0,0,.825,0l2.832-2.832A3.023,3.023,0,0,0,47.127,11.9Z" transform="translate(-12.014 0)" fill="#202529" />
                                                                                                <path id="Path_3530" data-name="Path_3530" d="M44.75,53.167A1.167,1.167,0,0,0,43.583,52H30.167a1.167,1.167,0,0,0,0,2.333H43.583A1.167,1.167,0,0,0,44.75,53.167Z" transform="translate(-8.751 -18.333)" fill="#202529" />
                                                                                            </g>
                                                                                        </svg>
                                                                                    </a>
                                                                                </div>

                                                                            </button>
                                                                        </h2>
                                                                        <div
                                                                            id={`collapse${index}`}
                                                                            className={`accordion-collapse collapse${activeAccordionIndex === index ? ' show' : ''}`}
                                                                            aria-labelledby={`heading${index}`}
                                                                            data-bs-parent={`#accordion${index}`}
                                                                        >
                                                                            {activeAccordionIndex === index && (
                                                                                <div className="row accordion-body">
                                                                                    <div className="col-md-6 other-info-box mt-2 p-2">
                                                                                        <p className="fs-14">Business Name</p>
                                                                                        <h4 className="fs-18">{operation.name}</h4>
                                                                                    </div>
                                                                                    <div className="col-md-6 other-info-box mt-2 p-2">
                                                                                        <p className="fs-14">Registration Date</p>
                                                                                        <h4 className="fs-18">{operation.registration_date}</h4>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    )
                                                ) : (
                                                    <div className="row contact-info mt-2 position-relative">
                                                        <div className="col-md-12 other-info-box mt-2">
                                                            <h4 className="fs-18 text-danger">You don’t have access to view this information</h4>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>


                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <div className="prof-inside p-4 bg-white shadow-lg rounded-4">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h6 className="fs-20 fw-semibold">Advance Details</h6>
                                                {hasAdvanceViewPermission() ? (
                                                    advanceList?.length > 0 ? (
                                                        <div>
                                                            <a className="me-3" onClick={() => deleteAdvance(advanceList[0]?.id)}>
                                                                {/* Uncomment and replace with actual function when needed */}
                                                                <svg
                                                                    width="18"
                                                                    height="18"
                                                                    version="1.1"
                                                                    viewBox="0 0 14 18"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
                                                                        <g fill="#000000" id="Core" transform="translate(-299.000000, -129.000000)">
                                                                            <g id="delete" transform="translate(299.000000, 129.000000)">
                                                                                <path
                                                                                    d="M1,16 C1,17.1 1.9,18 3,18 L11,18 C12.1,18 13,17.1 13,16 L13,4 L1,4 L1,16 L1,16 Z M14,1 L10.5,1 L9.5,0 L4.5,0 L3.5,1 L0,1 L0,3 L14,3 L14,1 L14,1 Z"
                                                                                    id="Shape"
                                                                                />
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </a>
                                                            <a onClick={() => showEditAdvanceModel()}>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="18"
                                                                    height="18"
                                                                    viewBox="0 0 28.001 28"
                                                                >
                                                                    <g id="write" transform="translate(-7.998 -8)">
                                                                        <path
                                                                            id="Path_3528"
                                                                            data-name="Path 3528"
                                                                            d="M8.6,33.775l-.587,4.863a1.462,1.462,0,0,0,1.457,1.654,1.436,1.436,0,0,0,.186-.012l4.863-.587a4.634,4.634,0,0,0,2.7-1.326l12.6-12.6a.583.583,0,0,0,0-.825l-6.465-6.47a.583.583,0,0,0-.825,0l-12.6,12.6a4.634,4.634,0,0,0-1.326,2.7Z"
                                                                            transform="translate(0 -4.292)"
                                                                            fill="#202529"
                                                                        />
                                                                        <path
                                                                            id="Path_3529"
                                                                            data-name="Path_3529"
                                                                            d="M47.127,11.9,44.112,8.886a3.023,3.023,0,0,0-4.277,0L37,11.718a.583.583,0,0,0,0,.825l6.468,6.468a.583.583,0,0,0,.825,0l2.832-2.832A3.023,3.023,0,0,0,47.127,11.9Z"
                                                                            transform="translate(-12.014 0)"
                                                                            fill="#202529"
                                                                        />
                                                                        <path
                                                                            id="Path_3530"
                                                                            data-name="Path_3530"
                                                                            d="M44.75,53.167A1.167,1.167,0,0,0,43.583,52H30.167a1.167,1.167,0,0,0,0,2.333H43.583A1.167,1.167,0,0,0,44.75,53.167Z"
                                                                            transform="translate(-8.751 -18.333)"
                                                                            fill="#202529"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    ) : (
                                                        <a onClick={() => showAdvanceModal()}>
                                                            <svg
                                                                id="Layer_2"
                                                                data-name="Layer 2"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="28"
                                                                height="28"
                                                                viewBox="0 0 28 28"
                                                            >
                                                                <path
                                                                    id="Path_3531"
                                                                    data-name="Path_3531"
                                                                    d="M15,1A14,14,0,1,0,29,15,14,14,0,0,0,15,1Zm6.364,15.273H16.273v5.091a1.273,1.273,0,1,1-2.545,0V16.273H8.636a1.273,1.273,0,1,1,0-2.545h5.091V8.636a1.273,1.273,0,1,1,2.545,0v5.091h5.091a1.273,1.273,0,1,1,0,2.545Z"
                                                                    transform="translate(-1 -1)"
                                                                    fill="#212529"
                                                                />
                                                            </svg>
                                                        </a>
                                                    )
                                                ) : null}
                                            </div>

                                            <>
                                                {hasAdvanceViewPermission() ? (
                                                    advanceList && advanceList.length > 0 ? (
                                                        advanceList.map((advanceList) => (
                                                            <div className="row mt-3 other-info" key={advanceList?.id}>
                                                                <div className="col-md-6 other-info-box mt-3">
                                                                    <p className="fs-14">Business Type</p>
                                                                    <h4 className="fs-18">  {advanceList?.business_type?.name}</h4>

                                                                </div>
                                                                <div className="col-md-6 other-info-box mt-3">
                                                                    <p className="fs-14">Nature of Business</p>
                                                                    <h4 className="fs-18">  {advanceList?.business_nature?.name}</h4>
                                                                </div>
                                                                <div className="col-md-6 other-info-box mt-3">
                                                                    <p className="fs-14">Category</p>
                                                                    <h4 className="fs-18">  {advanceList?.business_category?.name}</h4>
                                                                </div>
                                                                <div className="col-md-6 other-info-box mt-3">
                                                                    <p className="fs-14">Business No.</p>
                                                                    <h4 className="fs-18">{advanceList?.business_no}</h4>
                                                                </div>
                                                                <div className="col-md-6 other-info-box mt-3">
                                                                    <p className="fs-14">Authorization Form (RC59)</p>
                                                                    <h4 className="fs-18">{Authform_id.getName(advanceList?.authform_id_59)}</h4>
                                                                </div>

                                                                <div className="col-md-6 other-info-box mt-3">
                                                                    <p className="fs-14">Authorization Form (T1013)</p>
                                                                    <h4 className="fs-18">{Authform_id_1013.getName(advanceList?.authform_id_1013)}</h4>
                                                                </div>
                                                                <div className="col-md-6 other-info-box mt-3">
                                                                    <p className="fs-14">Incorporation Date</p>
                                                                    <h4 className="fs-18">{advanceList?.incorporation_date}</h4>
                                                                </div>
                                                                <div className="col-md-6 other-info-box mt-3">
                                                                    <p className="fs-14">Data Entry Style</p>
                                                                    <h4 className="fs-18">{advanceList?.data_entry_style?.name}</h4>
                                                                </div>
                                                                <div className="col-md-6 other-info-box mt-3">
                                                                    <p className="fs-14">Business Planning Status</p>
                                                                    <h4 className="fs-18">{advanceList?.business_planing?.name}</h4>
                                                                </div>
                                                                <div className="col-md-6 other-info-box mt-3">
                                                                    <p className="fs-14">Date of Business Planning</p>
                                                                    <h4 className="fs-18">{advanceList?.date_planning}</h4>
                                                                </div>
                                                                <div className="col-md-6 other-info-box mt-3">
                                                                    <p className="fs-14">Corporation Year Ending</p>
                                                                    <h4 className="fs-18">{advanceList?.year_ending}</h4>
                                                                </div>
                                                                <div className="col-md-12 other-info-box mt-3">
                                                                    <p className="fs-14">HST Year Ending</p>
                                                                    <h4 className="fs-18">{advanceList?.hstyear_ending}</h4>
                                                                </div>
                                                                <div className="col-md-12 other-info-box mt-3">
                                                                    <p className="fs-14">Business Planning Session Notes</p>
                                                                    <h4 className="fs-18">{advanceList?.business_notes}</h4>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div className="row contact-info mt-2 position-relative">
                                                            <div className="col-md-12 other-info-box mt-2">
                                                                <h4 className="fs-14 text-danger text-capitalize text-center">No Data found</h4>
                                                            </div>
                                                        </div>
                                                    )
                                                ) : (
                                                    <div className="row contact-info mt-2 position-relative">
                                                        <div className="col-md-12 other-info-box mt-2">
                                                            <h4 className="fs-14 text-danger text-capitalize text-center">You don’t have access to view this information</h4>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <div className="assig-member p-4 bg-white shadow-lg rounded-4">
                                            <div className="prof-insides">
                                                <div className="d-flex align-items-center justify-content-between" onClick={() => showDirectorModal()}>
                                                    <h6 className="fs-20 fw-semibold">Executive's Details</h6>
                                                    <a ><svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                                        <path id="Path_3531" data-name="Path 3531" d="M15,1A14,14,0,1,0,29,15,14,14,0,0,0,15,1Zm6.364,15.273H16.273v5.091a1.273,1.273,0,1,1-2.545,0V16.273H8.636a1.273,1.273,0,1,1,0-2.545h5.091V8.636a1.273,1.273,0,1,1,2.545,0v5.091h5.091a1.273,1.273,0,1,1,0,2.545Z" transform="translate(-1 -1)" fill="#212529" />
                                                    </svg>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="director-container">

                                                {/* <div>
                                                    {hasExecutiveViewPermission ? (
                                                        (directorData?.length ?? 0) === 0 ? (
                                                            <div className="row contact-info mt-2 position-relative">
                                                                <div className="col-md-12 other-info-box mt-2">
                                                                    <h4 className="fs-18 c-text">No Data found</h4>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            directorData.map((director, index) => (
                                                                <div
                                                                    className="director"
                                                                    key={index}
                                                                >
                                                                    <div className="director-head d-flex align-items-center justify-content-between gap-3" onClick={() => handleAccordionDirectorClick(index)}>
                                                                        <div className="d_leftcont d-flex align-items-center gap-2">
                                                                            <img src={require("../../../assets/images/user-pic.png")} alt="" />
                                                                            <span>
                                                                                <h4 className="fs-18">
                                                                                    {director?.first_name}{" "}
                                                                                    {director?.primary_contact == 1 && (
                                                                                        <span className="fs-12 text-blue">
                                                                                            <svg
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                width="16"
                                                                                                height="16"
                                                                                                viewBox="0 0 16 16"
                                                                                            >
                                                                                                <g id="Group_2567" data-name="Group 2567" transform="translate(4463 12052)">
                                                                                                    <path
                                                                                                        id="Path_4095"
                                                                                                        data-name="Path 4095"
                                                                                                        d="M16.081,8.156a2.6,2.6,0,0,1-.643-.553,2.721,2.721,0,0,1,.206-.887,2.146,2.146,0,0,0,.068-1.922c-.393-.562-1.135-.544-1.785-.528a2.44,2.44,0,0,1-.859-.061,2.686,2.686,0,0,1-.316-.826c-.184-.657-.4-1.4-1.042-1.621-.623-.21-1.2.252-1.711.657a2.43,2.43,0,0,1-.753.49,2.429,2.429,0,0,1-.761-.49c-.509-.4-1.084-.87-1.71-.657-.645.219-.856.964-1.042,1.62a2.726,2.726,0,0,1-.313.824,2.483,2.483,0,0,1-.861.062c-.653-.019-1.389-.039-1.785.528a2.13,2.13,0,0,0,.068,1.922,2.687,2.687,0,0,1,.209.887,2.594,2.594,0,0,1-.642.554c-.545.389-1.163.831-1.163,1.549s.618,1.159,1.163,1.549a2.594,2.594,0,0,1,.642.553,2.687,2.687,0,0,1-.2.887,2.149,2.149,0,0,0-.068,1.922c.392.562,1.132.544,1.785.528a2.443,2.443,0,0,1,.86.061,2.711,2.711,0,0,1,.315.826c.185.657.4,1.4,1.042,1.621A1.018,1.018,0,0,0,7.1,17.7a2.476,2.476,0,0,0,1.39-.71,2.43,2.43,0,0,1,.753-.49,2.429,2.429,0,0,1,.761.49c.51.406,1.087.87,1.711.657.645-.219.856-.964,1.042-1.619a2.7,2.7,0,0,1,.315-.825,2.443,2.443,0,0,1,.858-.062c.653.016,1.389.039,1.785-.528a2.132,2.132,0,0,0-.068-1.922,2.722,2.722,0,0,1-.209-.885,2.584,2.584,0,0,1,.643-.554c.545-.389,1.163-.831,1.163-1.549S16.627,8.546,16.081,8.156Z"
                                                                                                        transform="translate(-4464.247 -12053.705)"
                                                                                                        fill="#004fc5"
                                                                                                    />
                                                                                                    <path
                                                                                                        id="Path_4096"
                                                                                                        data-name="Path 4096"
                                                                                                        d="M11,14.75a.745.745,0,0,1-.53-.22l-2-2a.75.75,0,0,1,1.06-1.06l1.54,1.54,3.48-2.61a.75.75,0,1,1,.9,1.2l-4,3a.751.751,0,0,1-.45.15Z"
                                                                                                        transform="translate(-4467.009 -12056.5)"
                                                                                                        fill="#fff"
                                                                                                    />
                                                                                                </g>
                                                                                            </svg>
                                                                                        </span>
                                                                                    )}
                                                                                </h4>
                                                                                <h6 className="fs-14 text-muted mt-1">Account Manager</h6>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        )
                                                    ) : (
                                                        <div className="row contact-info mt-2 position-relative">
                                                            <div className="col-md-12 other-info-box mt-2">
                                                                <h4 className="fs-18 text-danger">You don’t have access to view this information</h4>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div> */}
                                                {hasExecutiveViewPermission() ? (
                                                    (directorData?.length ?? 0) === 0 ? (
                                                        <div className="row contact-info mt-2 position-relative">
                                                            <div className="col-md-12 other-info-box mt-2">
                                                                <h4 className="fs-18 c-text">No Data found</h4>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        directorData.map((director, index) => (
                                                            <div
                                                                className="director"
                                                                key={index}
                                                            >
                                                                <div className="director-head d-flex align-items-center justify-content-between gap-3" onClick={() => handleAccordionDirectorClick(index)}>
                                                                    <div className="d_leftcont d-flex align-items-center gap-2">
                                                                        <img src={require("../../../assets/images/user-pic.png")} alt="" />
                                                                        <span>
                                                                            <h4 className="fs-18">
                                                                                {director?.first_name}{" "}
                                                                                {director?.primary_contact == 1 && (
                                                                                    <span className="fs-12 text-blue">
                                                                                        <svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            width="16"
                                                                                            height="16"
                                                                                            viewBox="0 0 16 16"
                                                                                        >
                                                                                            <g id="Group_2567" data-name="Group 2567" transform="translate(4463 12052)">
                                                                                                <path
                                                                                                    id="Path_4095"
                                                                                                    data-name="Path 4095"
                                                                                                    d="M16.081,8.156a2.6,2.6,0,0,1-.643-.553,2.721,2.721,0,0,1,.206-.887,2.146,2.146,0,0,0,.068-1.922c-.393-.562-1.135-.544-1.785-.528a2.44,2.44,0,0,1-.859-.061,2.686,2.686,0,0,1-.316-.826c-.184-.657-.4-1.4-1.042-1.621-.623-.21-1.2.252-1.711.657a2.43,2.43,0,0,1-.753.49,2.429,2.429,0,0,1-.761-.49c-.509-.4-1.084-.87-1.71-.657-.645.219-.856.964-1.042,1.62a2.726,2.726,0,0,1-.313.824,2.483,2.483,0,0,1-.861.062c-.653-.019-1.389-.039-1.785.528a2.13,2.13,0,0,0,.068,1.922,2.687,2.687,0,0,1,.209.887,2.594,2.594,0,0,1-.642.554c-.545.389-1.163.831-1.163,1.549s.618,1.159,1.163,1.549a2.594,2.594,0,0,1,.642.553,2.687,2.687,0,0,1-.2.887,2.149,2.149,0,0,0-.068,1.922c.392.562,1.132.544,1.785.528a2.443,2.443,0,0,1,.86.061,2.711,2.711,0,0,1,.315.826c.185.657.4,1.4,1.042,1.621A1.018,1.018,0,0,0,7.1,17.7a2.476,2.476,0,0,0,1.39-.71,2.43,2.43,0,0,1,.753-.49,2.429,2.429,0,0,1,.761.49c.51.406,1.087.87,1.711.657.645-.219.856-.964,1.042-1.619a2.7,2.7,0,0,1,.315-.825,2.443,2.443,0,0,1,.858-.062c.653.016,1.389.039,1.785-.528a2.132,2.132,0,0,0-.068-1.922,2.722,2.722,0,0,1-.209-.885,2.584,2.584,0,0,1,.643-.554c.545-.389,1.163-.831,1.163-1.549S16.627,8.546,16.081,8.156Z"
                                                                                                    transform="translate(-4464.247 -12053.705)"
                                                                                                    fill="#004fc5"
                                                                                                />
                                                                                                <path
                                                                                                    id="Path_4096"
                                                                                                    data-name="Path 4096"
                                                                                                    d="M11,14.75a.745.745,0,0,1-.53-.22l-2-2a.75.75,0,0,1,1.06-1.06l1.54,1.54,3.48-2.61a.75.75,0,1,1,.9,1.2l-4,3a.751.751,0,0,1-.45.15Z"
                                                                                                    transform="translate(-4467.009 -12056.5)"
                                                                                                    fill="#fff"
                                                                                                />
                                                                                            </g>
                                                                                        </svg>
                                                                                    </span>
                                                                                )}
                                                                            </h4>
                                                                            <h6 className="fs-14 text-muted mt-1">Account Manager</h6>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    )
                                                ) :
                                                    (
                                                        <div className="row contact-info mt-2 position-relative">
                                                            <div className="col-md-12 other-info-box mt-2">
                                                                <h4 className="fs-18 text-danger">You don’t have access to view this information</h4>
                                                            </div>
                                                        </div>
                                                    )}


                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <div className="prof-inside p-4 bg-white shadow-lg rounded-4">
                                            <div className="d-flex align-items-center justify-content-between" >
                                                <h6 className="fs-20 fw-semibold">Services</h6>
                                                {hasServiceViewPermission() ? (
                                                    serviceList?.length > 0 ? (
                                                        <>
                                                            <span className="file-form"
                                                                //  style={style}
                                                                onClick={() => handleModalOpen(serviceDetail)}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12.599" height="13.999" viewBox="0 0 12.599 13.999">
                                                                    <path id="Path_506" data-name="Path 506" d="M402.367,1279h5.866a3.067,3.067,0,0,1,3.366,3.381v7.231a3.056,3.056,0,0,1-3.366,3.388h-5.866a3.072,3.072,0,0,1-3.367-3.388v-7.231A3.083,3.083,0,0,1,402.367,1279Zm.189,3.262v-.007h2.092a.541.541,0,0,1,.388.161.549.549,0,0,1,0,.777.541.541,0,0,1-.388.161h-2.092a.546.546,0,0,1,0-1.092Zm0,4.256h5.488a.546.546,0,0,0,0-1.092h-5.488a.546.546,0,0,0,0,1.092Zm0,3.2h5.488a.531.531,0,0,0,.35-.182.55.55,0,0,0,0-.735.531.531,0,0,0-.35-.182h-5.488a.5.5,0,0,0-.3.056.5.5,0,0,0-.225.2.56.56,0,0,0,0,.588.541.541,0,0,0,.525.252Z" transform="translate(-399 -1279)" fill="#202529" fillRule="evenodd" />
                                                                </svg>
                                                                Fill Required Forms
                                                            </span>
                                                            <a className="me-3" onClick={() => deleteService(serviceList[0]?.id)}>

                                                                <svg
                                                                    width="24"
                                                                    height="24"
                                                                    version="1.1"
                                                                    viewBox="0 0 14 18"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
                                                                        <g fill="#000000" id="Core" transform="translate(-299.000000, -129.000000)">
                                                                            <g id="delete" transform="translate(299.000000, 129.000000)">
                                                                                <path
                                                                                    d="M1,16 C1,17.1 1.9,18 3,18 L11,18 C12.1,18 13,17.1 13,16 L13,4 L1,4 L1,16 L1,16 Z M14,1 L10.5,1 L9.5,0 L4.5,0 L3.5,1 L0,1 L0,3 L14,3 L14,1 L14,1 Z"
                                                                                    id="Shape"
                                                                                />
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </a>

                                                            <a
                                                                onClick={() => showEditServiceModal()}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="28.001" height="28" viewBox="0 0 28.001 28">
                                                                    <g id="write" transform="translate(-7.998 -8)">
                                                                        <path id="Path_3528" data-name="Path 3528" d="M8.6,33.775l-.587,4.863a1.462,1.462,0,0,0,1.457,1.654,1.436,1.436,0,0,0,.186-.012l4.863-.587a4.634,4.634,0,0,0,2.7-1.326l12.6-12.6a.583.583,0,0,0,0-.825l-6.465-6.47a.583.583,0,0,0-.825,0l-12.6,12.6a4.634,4.634,0,0,0-1.326,2.7Z" transform="translate(0 -4.292)" fill="#202529" />
                                                                        <path id="Path_3529" data-name="Path 3529" d="M47.127,11.9,44.112,8.886a3.023,3.023,0,0,0-4.277,0L37,11.718a.583.583,0,0,0,0,.825l6.468,6.468a.583.583,0,0,0,.825,0l2.832-2.832A3.023,3.023,0,0,0,47.127,11.9Z" transform="translate(-12.014 0)" fill="#202529" />
                                                                        <path id="Path_3530" data-name="Path 3530" d="M44.75,53.167A1.167,1.167,0,0,0,43.583,52H30.167a1.167,1.167,0,0,0,0,2.333H43.583A1.167,1.167,0,0,0,44.75,53.167Z" transform="translate(-8.751 -18.333)" fill="#202529" />
                                                                    </g>
                                                                </svg>
                                                            </a>

                                                        </>

                                                    ) : (
                                                        <a onClick={() => showServicesModal()}>
                                                            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                                                <path id="Path_3531" data-name="Path 3531" d="M15,1A14,14,0,1,0,29,15,14,14,0,0,0,15,1Zm6.364,15.273H16.273v5.091a1.273,1.273,0,1,1-2.545,0V16.273H8.636a1.273,1.273,0,1,1,0-2.545h5.091V8.636a1.273,1.273,0,1,1,2.545,0v5.091h5.091a1.273,1.273,0,1,1,0,2.545Z" transform="translate(-1 -1)" fill="#212529" />
                                                            </svg>
                                                        </a>
                                                    )
                                                ) : null}
                                            </div>
                                            <>
                                                {hasServiceViewPermission() ? (
                                                    serviceList && serviceList?.length > 0 ? (
                                                        serviceList.map((serviceList) => (
                                                            <div className="row mt-3 other-info" key={serviceList?.id}>
                                                                <div className="col-md-6 other-info-box mt-2">
                                                                    <p className="fs-14">HST Open</p>
                                                                    <h4 className="fs-18">{serviceList?.hst_open?.name}</h4>
                                                                </div>
                                                                <div className="col-md-6 other-info-box mt-2">
                                                                    <p className="fs-14">HST Return</p>
                                                                    <h4 className="fs-18">{serviceList?.hst_return?.name}</h4>
                                                                </div>
                                                                <div className="col-md-6 other-info-box mt-2">
                                                                    <p className="fs-14">HST Style</p>
                                                                    <h4 className="fs-18">{serviceList?.hst_style?.name}</h4>
                                                                </div>
                                                                <div className="col-md-6 other-info-box mt-2">
                                                                    <p className="fs-14">Payroll Account</p>
                                                                    <h4 className="fs-18">{serviceList?.payroll_account?.name}</h4>
                                                                </div>
                                                                <div className="col-md-6 other-info-box mt-2">
                                                                    <p className="fs-14">ARC Setup</p>
                                                                    <h4 className="fs-18">{serviceList?.arc_setup?.name}</h4>
                                                                </div>
                                                                <div className="col-md-6 other-info-box mt-2">
                                                                    <p className="fs-14">ARC Access</p>
                                                                    <h4 className="fs-18">{serviceList?.arc_access?.name}</h4>
                                                                </div>
                                                                <div className="col-md-6 other-info-box mt-2">
                                                                    <p className="fs-14">ARC Grid Saved</p>
                                                                    <h4 className="fs-18">{serviceList?.arc_grid?.name}</h4>
                                                                </div>
                                                                <div className="col-md-6 other-info-box mt-2">
                                                                    <p className="fs-14">ARC Details</p>
                                                                    <h4 className="fs-18">{serviceList?.arc_detail}</h4>
                                                                </div>
                                                                <div className="col-md-6 other-info-box mt-2">
                                                                    <p className="fs-14">Agreement Sign</p>
                                                                    <h4 className="fs-18">{serviceList?.agreement_sign?.name}</h4>
                                                                </div>
                                                                <div className="col-md-6 other-info-box mt-2">
                                                                    <p className="fs-14">Agreement Reason</p>
                                                                    <h4 className="fs-18">{serviceList?.agreement_reason}</h4>
                                                                </div>
                                                                <div className="col-md-6 other-info-box mt-2">
                                                                    <p className="fs-14">Agreement Reason</p>
                                                                    <h4 className="fs-18">{serviceList?.advance_fee}</h4>
                                                                </div>
                                                                <div className="col-md-6 other-info-box mt-2">
                                                                    <p className="fs-14">Agreement Signing Amount</p>
                                                                    <h4 className="fs-18">{serviceList?.advance_amount}</h4>
                                                                </div>
                                                                <div className="col-md-6 other-info-box mt-2">
                                                                    <p className="fs-14">Reason if not Signed </p>
                                                                    <h4 className="fs-18">{serviceList?.advance_reason}</h4>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div className="row contact-info mt-2 position-relative">
                                                            <div className="col-md-12 other-info-box mt-2">
                                                                <h4 className="fs-14 text-danger text-capitalize text-center">No Data found</h4>
                                                            </div>
                                                        </div>
                                                    )
                                                ) : (
                                                    <div className="row contact-info mt-2 position-relative">
                                                        <div className="col-md-12 other-info-box mt-2">
                                                            <h4 className="fs-14 text-danger text-capitalize text-center">You don’t have access to view this information</h4>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <div className="prof-inside assig-member p-4 bg-white shadow-lg rounded-4">
                                            <div className="d-flex align-items-center justify-content-between" onClick={() => showAssigendModal()}>
                                                <h6 className="fs-20 fw-semibold">Assigned Member</h6>
                                                <a ><svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                                    <path id="Path_3531" data-name="Path 3531" d="M15,1A14,14,0,1,0,29,15,14,14,0,0,0,15,1Zm6.364,15.273H16.273v5.091a1.273,1.273,0,1,1-2.545,0V16.273H8.636a1.273,1.273,0,1,1,0-2.545h5.091V8.636a1.273,1.273,0,1,1,2.545,0v5.091h5.091a1.273,1.273,0,1,1,0,2.545Z" transform="translate(-1 -1)" fill="#212529" />
                                                </svg>
                                                </a>
                                            </div>
                                            <div>
                                                {hasAssigneViewPermission() ? (
                                                    assignedList && assignedList.length > 0 ? (
                                                        assignedList.map((assignedItem, index) => (
                                                            <ul className="mt-2 assigned-box ps-0" key={index}>
                                                                <li className="rounded-4 mt-1 p-3">
                                                                    <div className="d-flex align-items-center gap-2 justify-content-end">
                                                                        <a className="d-flex justify-content-end" onClick={() => deleteAssigne(assignedItem.id)}>
                                                                            <svg width="24" height="24" viewBox="0 0 14 18" xmlns="http://www.w3.org/2000/svg">
                                                                                <g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1">
                                                                                    <g fill="#000000" id="Core" transform="translate(-299.000000, -129.000000)">
                                                                                        <g id="delete" transform="translate(299.000000, 129.000000)">
                                                                                            <path d="M1,16 C1,17.1 1.9,18 3,18 L11,18 C12.1,18 13,17.1 13,16 L13,4 L1,4 L1,16 L1,16 Z M14,1 L10.5,1 L9.5,0 L4.5,0 L3.5,1 L0,1 L0,3 L14,3 L14,1 L14,1 Z" id="Shape"></path>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </svg>
                                                                        </a>

                                                                        <a className="d-flex justify-content-end" onClick={() => editAssignedSubmit(assignedItem)}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="28.001" height="28" viewBox="0 0 28.001 28">
                                                                                <g id="write" transform="translate(-7.998 -8)">
                                                                                    <path id="Path_3528" data-name="Path 3528" d="M8.6,33.775l-.587,4.863a1.462,1.462,0,0,0,1.457,1.654,1.436,1.436,0,0,0,.186-.012l4.863-.587a4.634,4.634,0,0,0,2.7-1.326l12.6-12.6a.583.583,0,0,0,0-.825l-6.465-6.47a.583.583,0,0,0-.825,0l-12.6,12.6a4.634,4.634,0,0,0-1.326,2.7Z" transform="translate(0 -4.292)" fill="#202529" />
                                                                                    <path id="Path_3529" data-name="Path 3529" d="M47.127,11.9,44.112,8.886a3.023,3.023,0,0,0-4.277,0L37,11.718a.583.583,0,0,0,0,.825l6.468,6.468a.583.583,0,0,0,.825,0l2.832-2.832A3.023,3.023,0,0,0,47.127,11.9Z" transform="translate(-12.014 0)" fill="#202529" />
                                                                                    <path id="Path_3530" data-name="Path 3530" d="M44.75,53.167A1.167,1.167,0,0,0,43.583,52H30.167a1.167,1.167,0,0,0,0,2.333H43.583A1.167,1.167,0,0,0,44.75,53.167Z" transform="translate(-8.751 -18.333)" fill="#202529" />
                                                                                </g>
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                    <div className="d-flex align-items-center justify-content-between mt-3">
                                                                        <span className="d-block w-50">
                                                                            <h4 className="fs-18">{assignedItem?.staff?.first_name}</h4>
                                                                            <p className="fs-14">Account Manager</p>
                                                                        </span>
                                                                        <span className="d-block w-50">
                                                                            <h4 className="fs-18">{assignedItem?.assistant?.first_name}</h4>
                                                                            <p className="fs-14">Assistant</p>
                                                                        </span>
                                                                    </div>
                                                                    <div className="d-flex align-items-center justify-content-between mt-3">
                                                                        <span className="d-block w-50">
                                                                            <h4 className="fs-18">{assignedItem?.bookkeeper?.first_name}</h4>
                                                                            <p className="fs-14">Bookkeeper</p>
                                                                        </span>
                                                                        <span className="d-block w-50">
                                                                            <h4 className="fs-18">{assignedItem?.communicator?.first_name}</h4>
                                                                            <p className="fs-14">Communicator</p>
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        ))
                                                    ) : (
                                                        <div className="row contact-info mt-2 position-relative">
                                                            <div className="col-md-12 other-info-box mt-2">
                                                                <h4 className="fs-14 text-danger text-capitalize text-center">No Data Found</h4>
                                                            </div>
                                                        </div>
                                                    )
                                                ) : (
                                                    <div className="row contact-info mt-2 position-relative">
                                                        <div className="col-md-12 other-info-box mt-2">
                                                            <h4 className="fs-14 text-danger text-capitalize text-center">You don’t have access to view this information</h4>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <div className="prof-inside p-4 bg-white shadow-lg rounded-4">
                                            <div className="d-flex align-items-center justify-content-between" onClick={() => showUploadModal()}>
                                                <h6 className="fs-20 fw-semibold">Upload Files</h6>
                                                <a ><svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                                    <path id="Path_3531" data-name="Path 3531" d="M15,1A14,14,0,1,0,29,15,14,14,0,0,0,15,1Zm6.364,15.273H16.273v5.091a1.273,1.273,0,1,1-2.545,0V16.273H8.636a1.273,1.273,0,1,1,0-2.545h5.091V8.636a1.273,1.273,0,1,1,2.545,0v5.091h5.091a1.273,1.273,0,1,1,0,2.545Z" transform="translate(-1 -1)" fill="#212529" />
                                                </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <div className="assig-member p-4 bg-white shadow-lg rounded-4">
                                            <div className="prof-insides">
                                                <div className="d-flex align-items-center justify-content-between" onClick={() => showShareHolderModal()}>
                                                    <h6 className="fs-20 fw-semibold">Shareholders Details</h6>
                                                    <a ><svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                                        <path id="Path_3531" data-name="Path 3531" d="M15,1A14,14,0,1,0,29,15,14,14,0,0,0,15,1Zm6.364,15.273H16.273v5.091a1.273,1.273,0,1,1-2.545,0V16.273H8.636a1.273,1.273,0,1,1,0-2.545h5.091V8.636a1.273,1.273,0,1,1,2.545,0v5.091h5.091a1.273,1.273,0,1,1,0,2.545Z" transform="translate(-1 -1)" fill="#212529" />
                                                    </svg>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="director-container">
                                                {hasShareHolderViewPermission() ? (
                                                    (shareHolderData?.length ?? 0) === 0 ? (
                                                        <div className="row contact-info mt-2 position-relative">
                                                            <div className="col-md-12 other-info-box mt-2">
                                                                <h4 className="fs-14 text-danger text-capitalize text-center">No Data found</h4>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        shareHolderData.map((shareHolder, index) => (
                                                            <div className="director" key={index}>
                                                                <div
                                                                    className="director-head d-flex align-items-center justify-content-between gap-3"
                                                                    onClick={() => handleAccordionShareHolderClick(index)}
                                                                >
                                                                    <div className="d_leftcont d-flex align-items-center gap-2">
                                                                        <img src={require("../../../assets/images/user-pic.png")} alt="User Pic" />
                                                                        <span>
                                                                            <h4 className="fs-18">
                                                                                {shareHolder?.first_name}{" "}
                                                                                {shareHolder?.primary_contact === "1" && (
                                                                                    <span className="fs-12 text-blue">
                                                                                        <svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            width="16"
                                                                                            height="16"
                                                                                            viewBox="0 0 16 16"
                                                                                        >
                                                                                            <g id="Group_2567" data-name="Group 2567" transform="translate(4463 12052)">
                                                                                                <path
                                                                                                    id="Path_4095"
                                                                                                    data-name="Path 4095"
                                                                                                    d="M16.081,8.156a2.6,2.6,0,0,1-.643-.553,2.721,2.721,0,0,1,.206-.887,2.146,2.146,0,0,0,.068-1.922c-.393-.562-1.135-.544-1.785-.528a2.44,2.44,0,0,1-.859-.061,2.686,2.686,0,0,1-.316-.826c-.184-.657-.4-1.4-1.042-1.621-.623-.21-1.2.252-1.711.657a2.43,2.43,0,0,1-.753.49,2.429,2.429,0,0,1-.761-.49c-.509-.4-1.084-.87-1.71-.657-.645.219-.856.964-1.042,1.62a2.726,2.726,0,0,1-.313.824,2.483,2.483,0,0,1-.861.062c-.653-.019-1.389-.039-1.785.528a2.13,2.13,0,0,0,.068,1.922,2.687,2.687,0,0,1,.209.887,2.594,2.594,0,0,1-.642.554c-.545.389-1.163.831-1.163,1.549s.618,1.159,1.163,1.549a2.594,2.594,0,0,1,.642.553,2.687,2.687,0,0,1-.2.887,2.149,2.149,0,0,0-.068,1.922c.392.562,1.132.544,1.785.528a2.443,2.443,0,0,1,.86.061,2.711,2.711,0,0,1,.315.826c.185.657.4,1.4,1.042,1.621A1.018,1.018,0,0,0,7.1,17.7a2.476,2.476,0,0,0,1.39-.71,2.43,2.43,0,0,1,.753-.49,2.429,2.429,0,0,1,.761.49c.51.406,1.087.87,1.711.657.645-.219.856-.964,1.042-1.619a2.7,2.7,0,0,1,.315-.825,2.443,2.443,0,0,1,.858-.062c.653.016,1.389.039,1.785-.528a2.132,2.132,0,0,0-.068-1.922,2.722,2.722,0,0,1-.209-.885,2.584,2.584,0,0,1,.643-.554c.545-.389,1.163-.831,1.163-1.549S16.627,8.546,16.081,8.156Z"
                                                                                                    transform="translate(-4464.247 -12053.705)"
                                                                                                    fill="#004fc5"
                                                                                                />
                                                                                                <path
                                                                                                    id="Path_4096"
                                                                                                    data-name="Path 4096"
                                                                                                    d="M11,14.75a.745.745,0,0,1-.53-.22l-2-2a.75.75,0,0,1,1.06-1.06l1.54,1.54,3.48-2.61a.75.75,0,1,1,.9,1.2l-4,3a.751.751,0,0,1-.45.15Z"
                                                                                                    transform="translate(-4467.009 -12056.5)"
                                                                                                    fill="#fff"
                                                                                                />
                                                                                            </g>
                                                                                        </svg>
                                                                                    </span>
                                                                                )}
                                                                            </h4>
                                                                            <h6 className="fs-14 text-muted mt-1">Account Manager</h6>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    )
                                                ) : (
                                                    <div className="row contact-info mt-2 position-relative">
                                                        <div className="col-md-12 other-info-box mt-2">
                                                            <h4 className="fs-18 text-danger">You don’t have access to view this information</h4>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <div className="assig-member p-4 bg-white shadow-lg rounded-4">
                                            <div className="prof-insides">
                                                <div
                                                    className="d-flex align-items-center justify-content-between"
                                                    onClick={() => showContactModal()}
                                                >
                                                    <h6 className="fs-20 fw-semibold">Contact Information</h6>
                                                    <a>
                                                        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                                            <path id="Path_3531" data-name="Path 3531" d="M15,1A14,14,0,1,0,29,15,14,14,0,0,0,15,1Zm6.364,15.273H16.273v5.091a1.273,1.273,0,1,1-2.545,0V16.273H8.636a1.273,1.273,0,1,1,0-2.545h5.091V8.636a1.273,1.273,0,1,1,2.545,0v5.091h5.091a1.273,1.273,0,1,1,0,2.545Z" transform="translate(-1 -1)" fill="#212529" />
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="director-container">
                                                {/* {hasContactViewPermission() ? (
                                                    Array.isArray(showContactList) && showContactList.length === 0 ? (
                                                        <div className="row contact-info mt-2 position-relative">
                                                            <div className="col-md-12 other-info-box mt-2">
                                                                <h4 className="fs-18 c-text">No Data found</h4>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        showContactList &&
                                                        showContactList.map((contact, index) => (
                                                            <div
                                                                className={`director ${activeAccordionIndex === index ? "active" : ""}`}
                                                                key={index}
                                                            >
                                                                <div
                                                                    className="director-head d-flex align-items-center justify-content-between gap-3"
                                                                    onClick={() => handleAccordionContactClick(index)}
                                                                >
                                                                    <div className="d_leftcont d-flex align-items-center gap-2">
                                                                        <img src={require("../../../assets/images/user-pic.png")} alt="User" />
                                                                        <span>
                                                                            <h4 className="fs-18">
                                                                                {contact?.first_name} {contact?.last_name}
                                                                                {contact?.primary_contact === "1" && (
                                                                                    <span className="fs-12 text-blue">
                                                                                        <svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            width="16"
                                                                                            height="16"
                                                                                            viewBox="0 0 16 16"
                                                                                        >
                                                                                            <g id="Group_2567" data-name="Group 2567" transform="translate(4463 12052)">
                                                                                                <path
                                                                                                    id="Path_4095"
                                                                                                    data-name="Path 4095"
                                                                                                    d="M16.081,8.156a2.6,2.6,0,0,1-.643-.553,2.721,2.721,0,0,1,.206-.887,2.146,2.146,0,0,0,.068-1.922c-.393-.562-1.135-.544-1.785-.528a2.44,2.44,0,0,1-.859-.061,2.686,2.686,0,0,1-.316-.826c-.184-.657-.4-1.4-1.042-1.621-.623-.21-1.2.252-1.711.657a2.43,2.43,0,0,1-.753.49,2.429,2.429,0,0,1-.761-.49c-.509-.4-1.084-.87-1.71-.657-.645.219-.856.964-1.042,1.62a2.726,2.726,0,0,1-.313.824,2.483,2.483,0,0,1-.861.062c-.653-.019-1.389-.039-1.785.528a2.13,2.13,0,0,0,.068,1.922,2.687,2.687,0,0,1,.209.887,2.594,2.594,0,0,1-.642.554c-.545.389-1.163.831-1.163,1.549s.618,1.159,1.163,1.549a2.594,2.594,0,0,1,.642.553,2.687,2.687,0,0,1-.2.887,2.149,2.149,0,0,0-.068,1.922c.392.562,1.132.544,1.785.528a2.443,2.443,0,0,1,.86.061,2.711,2.711,0,0,1,.315.826c.185.657.4,1.4,1.042,1.621A1.018,1.018,0,0,0,7.1,17.7a2.476,2.476,0,0,0,1.39-.71,2.43,2.43,0,0,1,.753-.49,2.429,2.429,0,0,1,.761.49c.51.406,1.087.87,1.711.657.645-.219.856-.964,1.042-1.619a2.7,2.7,0,0,1,.315-.825,2.443,2.443,0,0,1,.858-.062c.653.016,1.389.039,1.785-.528a2.132,2.132,0,0,0-.068-1.922,2.722,2.722,0,0,1-.209-.885,2.584,2.584,0,0,1,.643-.554c.545-.389,1.163-.831,1.163-1.549S16.627,8.546,16.081,8.156Z"
                                                                                                    transform="translate(-4464.247 -12053.705)"
                                                                                                    fill="#004fc5"
                                                                                                />
                                                                                                <path
                                                                                                    id="Path_4096"
                                                                                                    data-name="Path 4096"
                                                                                                    d="M11,14.75a.745.745,0,0,1-.53-.22l-2-2a.75.75,0,0,1,1.06-1.06l1.54,1.54,3.48-2.61a.75.75,0,1,1,.9,1.2l-4,3a.751.751,0,0,1-.45.15Z"
                                                                                                    transform="translate(-4467.009 -12056.5)"
                                                                                                    fill="#fff"
                                                                                                />
                                                                                            </g>
                                                                                        </svg>
                                                                                    </span>
                                                                                )}
                                                                            </h4>
                                                                            <h6 className="fs-14 text-muted mt-1">{contact?.designation?.name}</h6>
                                                                        </span>
                                                                    </div>
                                                                    <div>
                                                                        <a onClick={() => handleEditIconClick(contact)}>
                                                                           
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="28.001" height="28" viewBox="0 0 28.001 28">
                                                                               
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                {activeAccordionIndex === index && (
                                                                    <div className="director-content">
                                                                        <div className="row">
                                                                            <div className="col-md-6 other-info-box mt-2 p-2">
                                                                                <p className="fs-14">Full Name</p>
                                                                                <h4 className="fs-18">{contact.first_name} {contact.last_name}</h4>
                                                                            </div>
                                                                            <div className="col-md-6 other-info-box mt-2 p-2">
                                                                                <p className="fs-14">Date of Birth</p>
                                                                                <h4 className="fs-18">{contact.date_of_birth || "N/A"}</h4>
                                                                            </div>
                                                                            <div className="col-md-6 other-info-box mt-2 p-2">
                                                                                <p className="fs-14">Relation With Client</p>
                                                                                <h4 className="fs-18">{contact.relation_with_client || "N/A"}</h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))
                                                    )
                                                ) : (
                                                    <div className="row contact-info mt-2 position-relative">
                                                        <div className="col-md-12 other-info-box mt-2">
                                                            <h4 className="fs-18 text-danger">You don’t have access to view this information</h4>
                                                        </div>
                                                    </div>
                                                )} */}
                                                {hasContactViewPermission() ? (
                                                    Array.isArray(showContactList) && showContactList.length === 0 ? (
                                                        <div className="row contact-info mt-2 position-relative">
                                                            <div className="col-md-12 other-info-box mt-2">
                                                                <h4 className="fs-14 text-danger text-capitalize text-center">No Data found</h4>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        showContactList &&
                                                        showContactList.map((contact, index) => (
                                                            <div
                                                                className="director"
                                                                key={index}
                                                            >
                                                                <div className="director-head d-flex align-items-center justify-content-between gap-3" onClick={() => handleAccordionContactClick(index)}>
                                                                    <div className="d_leftcont d-flex align-items-center gap-2">
                                                                        <img src={require("../../../assets/images/user-pic.png")} alt="" />
                                                                        <span>
                                                                            <h4 className="fs-18">
                                                                                {contact?.first_name}{" "}
                                                                                {contact?.company_name}{" "}
                                                                                {contact?.primary_contact === "1" && (
                                                                                    <span className="fs-12 text-blue">
                                                                                        <svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            width="16"
                                                                                            height="16"
                                                                                            viewBox="0 0 16 16"
                                                                                        >
                                                                                            <g id="Group_2567" data-name="Group 2567" transform="translate(4463 12052)">
                                                                                                <path
                                                                                                    id="Path_4095"
                                                                                                    data-name="Path 4095"
                                                                                                    d="M16.081,8.156a2.6,2.6,0,0,1-.643-.553,2.721,2.721,0,0,1,.206-.887,2.146,2.146,0,0,0,.068-1.922c-.393-.562-1.135-.544-1.785-.528a2.44,2.44,0,0,1-.859-.061,2.686,2.686,0,0,1-.316-.826c-.184-.657-.4-1.4-1.042-1.621-.623-.21-1.2.252-1.711.657a2.43,2.43,0,0,1-.753.49,2.429,2.429,0,0,1-.761-.49c-.509-.4-1.084-.87-1.71-.657-.645.219-.856.964-1.042,1.62a2.726,2.726,0,0,1-.313.824,2.483,2.483,0,0,1-.861.062c-.653-.019-1.389-.039-1.785.528a2.13,2.13,0,0,0,.068,1.922,2.687,2.687,0,0,1,.209.887,2.594,2.594,0,0,1-.642.554c-.545.389-1.163.831-1.163,1.549s.618,1.159,1.163,1.549a2.594,2.594,0,0,1,.642.553,2.687,2.687,0,0,1-.2.887,2.149,2.149,0,0,0-.068,1.922c.392.562,1.132.544,1.785.528a2.443,2.443,0,0,1,.86.061,2.711,2.711,0,0,1,.315.826c.185.657.4,1.4,1.042,1.621A1.018,1.018,0,0,0,7.1,17.7a2.476,2.476,0,0,0,1.39-.71,2.43,2.43,0,0,1,.753-.49,2.429,2.429,0,0,1,.761.49c.51.406,1.087.87,1.711.657.645-.219.856-.964,1.042-1.619a2.7,2.7,0,0,1,.315-.825,2.443,2.443,0,0,1,.858-.062c.653.016,1.389.039,1.785-.528a2.132,2.132,0,0,0-.068-1.922,2.722,2.722,0,0,1-.209-.885,2.584,2.584,0,0,1,.643-.554c.545-.389,1.163-.831,1.163-1.549S16.627,8.546,16.081,8.156Z"
                                                                                                    transform="translate(-4464.247 -12053.705)"
                                                                                                    fill="#004fc5"
                                                                                                />
                                                                                                <path
                                                                                                    id="Path_4096"
                                                                                                    data-name="Path 4096"
                                                                                                    d="M11,14.75a.745.745,0,0,1-.53-.22l-2-2a.75.75,0,0,1,1.06-1.06l1.54,1.54,3.48-2.61a.75.75,0,1,1,.9,1.2l-4,3a.751.751,0,0,1-.45.15Z"
                                                                                                    transform="translate(-4467.009 -12056.5)"
                                                                                                    fill="#fff"
                                                                                                />
                                                                                            </g>
                                                                                        </svg>
                                                                                    </span>
                                                                                )}
                                                                            </h4>
                                                                            <h6 className="fs-14 text-muted mt-1">{contact?.designation?.name}</h6>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    )
                                                ) :
                                                    (
                                                        <div className="row contact-info mt-2 position-relative">
                                                            <div className="col-md-12 other-info-box mt-2">
                                                                <h4 className="fs-18 text-danger">You don’t have access to view this information</h4>
                                                            </div>
                                                        </div>
                                                    )}

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Modal
                show={editProfile}
                onHide={onHideHandler}
                centered
                // size="xl"
                contentClassName="mx-auto"
                className="modal operating-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >


                <div className="modal-header px-4 pt-2 pb-0 border-0 justify-content-between">
                    <h4 className="fs-20">Edit Profile Details</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>
                <div className="modal-body px-4 pb-0">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="operating-form">
                            <div className="">
                                <label className="fs-18">Client Name</label>
                                <input {...register("name")} name="name" id="name" className="form-input mt-2" type="text" placeholder="Name" />
                            </div>
                            <div className="mt-4">
                                <label className="fs-18">Email Address</label>
                                <input {...register("email")} name="email" id="email" className="form-input mt-2" type="email" placeholder="Email" disabled />
                            </div>
                            <div className="mt-4">
                                <label className="fs-18">Address</label>
                                <PlacesAutocomplete
                                    value={address}
                                    onChange={handleChange}  // Handle input change here
                                    onSelect={handleSelect}  // Handle address selection here
                                    searchOptions={{
                                        componentRestrictions: { country: ['us', 'ca'] },
                                    }}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <input
                                                {...getInputProps({
                                                    placeholder: 'Type address here...',
                                                    className: 'form-input mt-2 google-address',
                                                })}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map((suggestion) => {
                                                    const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                    return (
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                            })}
                                                            key={suggestion.placeId}
                                                        >
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            </div>
                            <div className="mt-4">
                                <label className="fs-18">Folder</label>
                                <input {...register("folder_number")} name="folder_number" id="folder_number" type="text" className="form-input mt-2" placeholder="Folder Number" />
                            </div>
                            <div className="mt-4">
                                <label htmlFor="status" className="fs-18">Status</label>
                                <select
                                    name="status"
                                    id="status"
                                    className="form-input mt-2"
                                // {...register("status", {
                                //     onChange: (e) => setShowEditStatusData(prev => ({
                                //         ...prev,
                                //         status: e.target.value,
                                //     }))
                                // })}
                                >
                                    <option value="" disabled>
                                        Select
                                    </option>
                                    {Status_id && Status_id.getList().map((data) => (
                                        <option key={data.key} value={data.key}>
                                            {data.value}
                                        </option>
                                    ))}
                                </select>
                            </div>

                        </div>
                        <div className="modal-footer border-0 px-0 pb-4 mt-4">
                            <a href="business-client-profile.html"><button className="btn-blue">Update</button></a>
                        </div>
                    </form>



                </div>
            </Modal>
            <Modal
                show={editAdvanceModel}
                onHide={hideEditAdvanceModel}
                centered
                size="xl"
                contentClassName="mx-auto"
                className="modal operating-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >


                <div className="modal-header border-0 justify-content-between align-items-center">
                    <h4 className="fs-20 fw-semibold">Edit Advance Details</h4>
                    <Modal.Header closeButton className="close-icon border-0 py-0"></Modal.Header>
                </div>
                <div className="modal-body">
                    <form
                        onSubmit={handleSubmit(onAdvanceSubmit)}
                    >
                        <div className="advance-form row">
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Business Type</label>
                                <select
                                    name="businesstype_id"
                                    id="businesstype_id"
                                    className="form-input mt-2"
                                    {...register("businesstype_id", {

                                    })}

                                >
                                    <option
                                        value=""
                                        selected
                                        disabled="disabled"
                                    >
                                        Select
                                    </option>

                                    {allAdvanceApi.businesstype_id.length > 0 && (
                                        allAdvanceApi.businesstype_id.map((businesstype) => (
                                            <option key={businesstype.id} value={businesstype.id}>
                                                {businesstype.name}
                                            </option>
                                        ))
                                    )}
                                </select>
                            </div>

                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Nature of Business</label>
                                <select
                                    name="businessnature_id"
                                    id="businessnature_id"
                                    className="form-input mt-2"
                                    {...register("businessnature_id", {

                                    })}
                                >
                                    <option value="0" disabled selected>Select</option>
                                    {allAdvanceApi.businessnature_id.length > 0 && (
                                        allAdvanceApi.businessnature_id.map((businessnature) => (
                                            <option key={businessnature.id} value={businessnature.id}>
                                                {businessnature.name}
                                            </option>
                                        ))
                                    )}

                                </select>
                            </div>

                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Category</label>
                                <select
                                    name="businesscategory_id"
                                    id="businesscategory_id"
                                    className="form-input mt-2"
                                    {...register("businesscategory_id", {

                                    })}
                                >
                                    <option value="0" disabled selected>Select</option>
                                    {allAdvanceApi.businesscategory_id.length > 0 && (
                                        allAdvanceApi.businesscategory_id.map((businesscategory) => (
                                            <option key={businesscategory.id} value={businesscategory.id}>
                                                {businesscategory.name}
                                            </option>
                                        ))
                                    )}
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Business No.</label>
                                <input
                                    name="business_no"
                                    id="business_no"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    {...register("business_no", {

                                    })}

                                />
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Authorization Form</label>
                                <div className="position-relative select-text">
                                    <p>RC 59</p>
                                    <select
                                        name="authform_id_59"
                                        id="authform_id_59"
                                        className="form-input mt-2"
                                        {...register("authform_id_59", {

                                        })}
                                    >
                                        <option value="0" disabled="disabled" selected>Select</option>
                                        {Authform_id && Authform_id.getList().map((data) => (
                                            <option key={data.key} value={data.key}>
                                                {data.value}
                                            </option>
                                        ))}

                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Authorization Form</label>
                                <div className="position-relative select-text">
                                    <p>T1013</p>
                                    <select
                                        name="authform_id_1013"
                                        id="authform_id_1013"
                                        className="form-input mt-2"
                                        {...register("authform_id_1013", {

                                        })}
                                    >
                                        <option value="0" disabled="disabled" selected>Select</option>

                                        {Authform_id_1013 && Authform_id_1013.getList().map((data) => (
                                            <option key={data.key} value={data.key}>
                                                {data.value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Incorporation Date</label>
                                <input
                                    className="form-input mt-2"
                                    name="incorporation_date"
                                    id="incorporation_date"
                                    type="date"
                                    {...register("incorporation_date", {


                                    })}
                                />
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Data Entry Style</label>
                                <select
                                    name="data_entry_style_id"
                                    id="data_entry_style_id"
                                    className="form-input mt-2"
                                    {...register("data_entry_style_id", {


                                    })}

                                >
                                    <option value="" disabled selected>Select</option>
                                    {allAdvanceApi.data_entry_style_id.length > 0 && (
                                        allAdvanceApi.data_entry_style_id.map((businessenetryStyle) => (
                                            <option key={businessenetryStyle.id} value={businessenetryStyle.id}>
                                                {businessenetryStyle.name}
                                            </option>
                                        ))
                                    )}
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Business Planning Status</label>
                                <select
                                    name="business_planing_id"
                                    id="business_planing_id"
                                    className="form-input mt-2"
                                    {...register("business_planing_id", {


                                    })}
                                >
                                    <option value="" disabled selected>Select</option>
                                    {allAdvanceApi.business_planing_id.length > 0 && (
                                        allAdvanceApi.business_planing_id.map((businessplaning) => (
                                            <option key={businessplaning.id} value={businessplaning.id}>
                                                {businessplaning.name}
                                            </option>
                                        ))
                                    )}
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Date of Business Planning</label>
                                <input
                                    name="date_planning"
                                    id="date_planning"
                                    className="form-input mt-2"
                                    type="date"
                                    {...register("date_planning", {


                                    })}
                                />
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Corporation Year Ending</label>
                                <input
                                    name="year_ending"
                                    id="year_ending"
                                    className="form-input mt-2"
                                    type="date"
                                    {...register("year_ending", {


                                    })}
                                />
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">HST Year Ending</label>
                                <input
                                    name="hstyear_ending"
                                    id="hstyear_ending"
                                    className="form-input mt-2"
                                    type="date"
                                    {...register("hstyear_ending", {


                                    })}

                                />
                            </div>
                            <div className="col-md-12 mt-4">
                                <label className="fs-18">Business Planning Session Notes</label>
                                <textarea
                                    className="mt-2 fs-18"
                                    name="business_notes"
                                    id="business_notes"
                                    cols="20"
                                    rows="10"
                                    placeholder="Type here..."
                                    {...register("business_notes", {


                                    })}
                                >
                                </textarea>
                            </div>

                            <div className="col-md-12 mt-4 mb-2 text-end">
                            <button type="submit" className="btn-blue">Update</button>
                            </div>

                        </div>
                    </form>
                </div>
            </Modal>

{/* EDIT OPERATION MODAL */}
    <Modal
        show={editOperationModal}
        onHide={hideEditOperationModel}
        centered
        className="modal operating-modal"
        backdrop="static"
        keyboard={false}
    >
        <div className="modal-dialog modal-dialog-centered m-0">
            <div className="modal-content font-poppins ">
                <div className="modal-header border-0 justify-content-between">
                    <h4 className="fs-20 fw-semibold">Edit Operating Details</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>
                <div class="modal-body">
                    <form onSubmit={(e) => {
                        e.preventDefault(); // Prevent the default form submission behavior
                        // Call the function to update operation data
                        updateOperationData(showEditOperationData?.corporate_id, showEditOperationData?.id);
                    }}>
                        <div className="row">
                                    <div className="col-md-12 mt-4">
                                        <label className="fs-18">Operating Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className="form-input mt-2"
                                            placeholder="Type here..."

                                            value={showEditOperationData?.name} // Assuming 'registration_date' is the property to be displayed
                                            // onChange={(e) => setShowEditOperationData({ ...showEditOperationData, name: e.target.value })} // Update editModalData on change
                                            onChange={(e) => {
                                                setShowEditOperationData({ ...showEditOperationData, name: e.target.value });
                                                setErrors({ ...error, name: '' }); // Clear error when the user starts typing
                                            }}
                                        />
                                        {error.name && <p className="text-danger mt-1">{error.name}</p>}
                                    </div>
                                    <div className="col-md-12 mt-4">
                                        <label className="fs-18">Registration Date</label>
                                        <input
                                            type="date"
                                            name="registration_date"
                                            id="registration_date"
                                            className="form-input mt-2"
                                            placeholder="Type here..."

                                            value={showEditOperationData?.registration_date} // Assuming 'registration_date' is the property to be displayed
                                            // onChange={(e) => setShowEditOperationData({ ...showEditOperationData, registration_date: e.target.value })} // Update editModalData on change
                                            onChange={(e) => {
                                                setShowEditOperationData({ ...showEditOperationData, registration_date: e.target.value });
                                                setErrors({ ...error, registration_date: '' }); // Clear error when the user starts typing
                                            }}
                                        />
                                        {error.registration_date && <p className="text-danger mt-1">{error.registration_date}</p>}
                                    </div>
                                    <div className="col-md-12 mt-4 mb-2 text-end">
                                        <button type="submit" className="btn-blue ms-auto">Add</button>
                                    </div>
                                </div>
                    </form>


                </div>


            </div>
        </div>
    </Modal>

{/* EDIT SERVICE MODAL */}
    <Modal
        show={editServiceModal}
        onHide={hideEditServiceModal}
        centered
        size="xl"
        contentClassName="mx-auto"
        className="modal operating-modal font-poppins"
        backdrop="static"
        keyboard={false}
    >


        <div className="modal-header px-4 pt-2 pb-0 border-0 justify-content-between">
            <h4 className="fs-20">Edit Service Details</h4>
            <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
        </div>
        <div className="modal-body px-4 pt-0 pb-0">
            <form
                onSubmit={handleSubmit(onServiceSubmit)}
            >
                <div className="services-form row">
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">HST Open</label>
                        <select
                            name="hst_open_id"
                            className="form-input mt-2"
                            id="hst_open_id"
                            {...register("hst_open_id", {

                            })}
                        >
                            <option value="0" disabled="disabled" selected>Select</option>
                            {hstOpenList && hstOpenList.map((data) => (
                                <option key={data.key} value={data.id}>
                                    {data.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">HST Return</label>
                        <select
                            name="hst_return_id"
                            className="form-input mt-2"
                            id="hst_return_id" {...register("hst_return_id", {

                            })}
                        >
                            <option value="0" disabled="disabled" selected>Select</option>
                            {hstList && hstList.map((data) => (
                                <option key={data.key} value={data.id}>
                                    {data.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">HST Style</label>
                        <select
                            name="hst_style_id"
                            className="form-input mt-2"
                            id="hst_style_id"
                            {...register("hst_style_id", {

                            })}
                        >
                            <option value="0" disabled="disabled" selected>Select</option>
                            {hstStyleList && hstStyleList.map((data) => (
                                <option key={data.key} value={data.id}>
                                    {data.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">Payroll Account</label>
                        <select
                            name="payroll_account_id"
                            className="form-input mt-2"
                            id="payroll_account_id"
                            {...register("payroll_account_id", {

                            })}
                        >
                            <option value="0" disabled="disabled" selected>Select</option>
                            {payRollList && payRollList.map((data) => (
                                <option key={data.key} value={data.id}>
                                    {data.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">ARC Setup</label>
                        <select
                            name="arc_setup_id"
                            className="form-input mt-2"
                            id="arc_setup_id"
                            {...register("arc_setup_id", {

                            })}
                        >
                            <option value="0" disabled="disabled" selected>Select</option>
                            {arcSetupList && arcSetupList.map((data) => (
                                <option key={data.key} value={data.id}>
                                    {data?.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">ARC Access</label>
                        <select
                            name="arc_accesses_id"
                            className="form-input mt-2"
                            id="arc_accesses_id"
                            {...register("arc_accesses_id", {

                            })}
                        >
                            <option value="0" disabled="disabled" selected>Select</option>
                            {ArcAccessList && ArcAccessList.map((data) => (
                                <option key={data.key} value={data.id}>
                                    {data.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-lg-4 col-md-12 mt-4">
                        <label className="fs-18">ARC Grid Saved</label>
                        <select
                            name="arc_grid_id"
                            className="form-input mt-2"
                            id="arc_grid_id"
                            {...register("arc_grid_id", {

                            })}
                        >
                            <option value="0" disabled="disabled" selected>Select</option>
                            {arcGridList && arcGridList.map((data) => (
                                <option key={data.key} value={data.id}>
                                    {data.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-lg-12 col-md-12 mt-4">
                        <label className="fs-18">ARC Details</label>
                        <textarea
                            name="arc_detail"
                            id="arc_detail"
                            className="mt-2"
                            cols="20"
                            rows="10"

                            {...register("arc_detail", {

                            })}
                        ></textarea>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">Agreement Signed</label>
                        <select
                            name="agreement_sign_id"
                            className="form-input mt-2"
                            id="agreement_sign_id"
                            {...register("agreement_sign_id", {

                            })}
                        >
                            <option value="0" disabled="disabled" selected>Select</option>
                            {AgreemetList && AgreemetList.map((data) => (
                                <option key={data.key} value={data.id}>
                                    {data.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">Agreement Reason</label>
                        <select
                            name="agreement_reason"
                            className="form-input mt-2"
                            id="agreement_reason"
                            {...register("agreement_reason", {

                            })}
                        >
                            <option value="0" disabled="disabled" selected>Select</option>
                            {AgreementReasonList && AgreementReasonList.map((data) => (
                                <option key={data.key} value={data.key}>
                                    {data.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">Agreement Fee</label>
                        <select
                            name="advance_fee"
                            className="form-input mt-2"
                            id="advance_fee"
                            {...register("advance_fee", {

                            })}
                        >
                            <option value="0" disabled="disabled" selected>Select</option>
                            {AdvanceFeeList && AdvanceFeeList.map((data) => (
                                <option key={data.key} value={data.key}>
                                    {data.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">Agreement Signing Amount</label>
                        <input
                            className="form-input mt-2"
                            type="text"
                            name="advance_amount"
                            id="advance_amount"
                            placeholder="Type here..."
                            {...register("advance_amount", {

                            })}
                        />
                    </div>
                    <div className="col-md-12 mt-4">
                        <label className="fs-18">Reason If Not Signed</label>
                        <textarea
                            name="advance_reason"
                            id="advance_reason"
                            className="mt-2"
                            cols="20"
                            rows="10"
                            {...register("advance_reason", {

                            })}
                        ></textarea>
                    </div>
                    <div className="modal-footer border-0 px-0 pb-4 mt-4">
                        <button className="btn-blue">Update</button>
                    </div>

                </div>
            </form>
        </div>
    </Modal>

{/* EDIT DIRECTOR MODAL */}
    <Modal
        show={editDirectorModal}
        onHide={hideEditDirectModal}
        centered
        size="xl"
        contentClassName="mx-auto"
        className="modal operating-modal font-poppins"
        backdrop="static"
        keyboard={false}
    >


        <div className="modal-header px-4 pt-2 pb-0 border-0 justify-content-between">
            <h4 className="fs-20">Update Executive Details</h4>
            <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
        </div>
        <div className="modal-body px-4 pt-0 pb-0">
            <form onSubmit={(e) => {
                e.preventDefault(); // Prevent the default form submission behavior
                // Call the function to update operation data
                updateDirectorData(showEditDirData?.corporate_id, showEditDirData?.id);
            }}>

                <div className="row mt-4" >
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">First Name</label>
                        <input
                            type="text"
                            name="first_name"
                            id="first_name"
                            className="form-input mt-2"
                            placeholder="Type here..."
                            value={showEditDirData?.first_name} // Assuming 'registration_date' is the property to be displayed
                            onChange={(e) => {
                                setShowEditDirData({ ...showEditDirData, first_name: e.target.value });
                                setErrorsDir({ ...errorDir, first_name: '' }); // Clear error when the user starts typing
                            }}
                        />
                        {errorDir.first_name && <p className="text-danger mt-1">{errorDir.first_name}</p>}
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">Middle Name</label>
                        <input
                            type="text"
                            name="middle_name"
                            id="middle_name"
                            className="form-input mt-2"
                            placeholder="Type here..."
                            value={showEditDirData?.middle_name} // Assuming 'registration_date' is the property to be displayed
                            onChange={(e) => {
                                setShowEditDirData({ ...showEditDirData, middle_name: e.target.value });
                                setErrorsDir({ ...errorDir, middle_name: '' }); // Clear error when the user starts typing
                            }}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">Last Name</label>

                        <input
                            type="text"
                            name="last_name"
                            id="last_name"
                            className="form-input mt-2"
                            placeholder="Type here..."
                            value={showEditDirData?.last_name} // Assuming 'registration_date' is the property to be displayed
                            onChange={(e) => setShowEditDirData({ ...showEditDirData, last_name: e.target.value })}

                        />

                    </div>
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">SIN</label>
                        <input
                            type="text"
                            name="sin"
                            id="sin"
                            className="form-input mt-2"
                            placeholder="Type here..."
                            value={showEditDirData?.sin} // Assuming 'registration_date' is the property to be displayed
                            onChange={(e) => {
                                setShowEditDirData({ ...showEditDirData, sin: e.target.value });
                                setErrorsDir({ ...errorDir, sin: '' }); // Clear error when the user starts typing
                            }}
                        />
                        {/* {errorDir.last_name && <p className="text-danger mt-1">{errorDir.last_name}</p>} */}
                    </div>
                    <div className="col-lg-4 col-md-6 mt-3">
                        <label className="fs-18">Designation</label>
                        <select
                            className="form-input mt-2"
                            name="designation_id" // Update name to match structure
                            id="designation_id"
                            value={showEditDirData?.designation_id || ""}
                            onChange={(e) => setShowEditDirData({ ...showEditDirData, designation_id: e.target.value })}
                        >
                            <option selected disabled>Select</option>
                            {Array.isArray(designationListing) && designationListing.map((designation) => (

                                <option key={designation.id} value={designation.id}>
                                    {designation.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">Date of Birth</label>
                        <input
                            type="date"
                            name="date_of_birth"
                            id="date_of_birth"
                            className="form-input mt-2"
                            placeholder="Type here..."
                            value={showEditDirData?.date_of_birth} // Assuming 'registration_date' is the property to be displayed
                            onChange={(e) => setShowEditDirData({ ...showEditDirData, date_of_birth: e.target.value })}

                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">Email Address</label>
                        <input
                            type="text"
                            name="email"
                            id="email"
                            className="form-input mt-2"
                            placeholder="Type here..."
                            value={showEditDirData?.email} // Assuming 'registration_date' is the property to be displayed
                            onChange={(e) => {
                                setShowEditDirData({ ...showEditDirData, email: e.target.value });
                                setErrorsDir({ ...errorDir, email: '' }); // Clear error when the user starts typing
                            }}
                        />
                        {errorDir.email && <p className="text-danger mt-1">{errorDir.email}</p>}
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">Phone No.</label>
                        <input
                            type="text"
                            name="phone_number"
                            id="phone_number"
                            className="form-input mt-2"
                            placeholder="Type here..."
                            value={showEditDirData?.phone_number} // Assuming 'registration_date' is the property to be displayed
                            onChange={(e) => setShowEditDirData({ ...showEditDirData, phone_number: e.target.value })}

                        />
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                        <label className="fs-18">Primary Contact</label>
                        <select
                            className="form-input mt-2"
                            value={showEditDirData?.primary_contact || "0"} // Use primary_contact from showEditDirData
                            onChange={(e) =>
                                setShowEditDirData({ ...showEditDirData, primary_contact: e.target.value })
                            }
                        >
                            <option disabled>Select</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </div>
                    <div className="col-lg-8 col-md-12 mt-4">
                        <label className="fs-18">Street Address</label>
                        <PlacesAutocomplete
                            value={editDirAddressContact}
                            onChange={setEditDirContactAddress}
                            onSelect={handleDirectorSelectAddress}
                            searchOptions={{
                                componentRestrictions: { country: ["us", "ca"] },
                            }}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <input
                                        {...getInputProps({
                                            placeholder: "Type address here...",
                                            className: "form-input mt-2 google-address",
                                        })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map((suggestion) => (
                                            <div key={suggestion.placeId} {...getSuggestionItemProps(suggestion)}>
                                                <span>{suggestion.description}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>

                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">City</label>
                        <input
                            type="text"
                            className="form-input mt-2"
                            value={editDirCity}
                            readOnly
                        />
                    </div>

                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">Province</label>
                        <input
                            type="text"
                            className="form-input mt-2"
                            value={editDirProvince}
                            readOnly
                        />
                    </div>

                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">Postal Code</label>
                        <input
                            type="text"
                            className="form-input mt-2"
                            value={editDirPostalCode}
                            readOnly
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">Shareholder(%)</label>
                        <input
                            type="text"
                            name="shareholder_part"
                            id="shareholder_part"
                            className="form-input mt-2"
                            placeholder="Type here..."
                            value={showEditDirData?.shareholder_part} // Assuming 'registration_date' is the property to be displayed
                            onChange={(e) => setShowEditDirData({ ...showEditDirData, shareholder_part: e.target.value })}

                        />
                    </div>


                </div>


                <div className="modal-footer border-0 px-0 pb-4 mt-4">
                    <a ><button className="btn-blue">Update</button></a>
                </div>

            </form>
        </div>
    </Modal>

{/* EDIT SHAREHOLDER MODAL */}
    <Modal
        show={editShareHolderModal}
        onHide={hideEditShareModelModal}
        centered
        size="xl"
        contentClassName="mx-auto"
        className="modal operating-modal font-poppins"
        backdrop="static"
        keyboard={false}
    >


        <div className="modal-header px-4 pt-2 pb-0 border-0 justify-content-between">
            <h4 className="fs-20">Update ShareHolder Details</h4>
            <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
        </div>
        <div className="modal-body px-4 pt-0 pb-0">
            <form
                onSubmit={(e) => {
                    e.preventDefault(); // Prevent the default form submission behavior
                    // Call the function to update operation data
                    updateShareHolderData(showEditShareHolder?.corporate_id, showEditShareHolder?.id);
                }}
            >

                <div className="director-form row mt-3">
                    <div className="col-lg-6 mt-2">
                        <div className="search-input position-relative">
                            <i className="fa fa-search position-absolute top-4"></i>
                            <input type="search" placeholder="Search by SIN number..." className="" />
                        </div>
                    </div>
                </div>

                <div className="row mt-4" >
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">First Name</label>
                        <input
                            type="text"
                            name="first_name"
                            id="first_name"
                            className="form-input mt-2"
                            placeholder="Type here..."
                            value={showEditShareHolder?.first_name} // Assuming 'registration_date' is the property to be displayed
                            onChange={(e) => {
                                setShowEditShareHolderData({ ...showEditShareHolder, first_name: e.target.value });
                                setErrorShareHolder({ ...errorShareHolder, first_name: '' }); // Clear error when the user starts typing
                            }}
                        />
                        {errorShareHolder.first_name && <p className="text-danger mt-1">{errorShareHolder.first_name}</p>}
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">Middle Name</label>

                        <input
                            type="text"
                            name="middle_name"
                            id="middle_name"
                            className="form-input mt-2"
                            placeholder="Type here..."
                            value={showEditShareHolder?.middle_name} // Assuming 'registration_date' is the property to be displayed
                            onChange={(e) => setShowEditShareHolderData({ ...showEditShareHolder, middle_name: e.target.value })}

                        />

                    </div>
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">Last Name</label>
                        <input
                            type="text"
                            name="last_name"
                            id="last_name"
                            className="form-input mt-2"
                            placeholder="Type here..."
                            value={showEditShareHolder?.last_name} // Assuming 'registration_date' is the property to be displayed
                            onChange={(e) => {
                                setShowEditShareHolderData({ ...showEditShareHolder, last_name: e.target.value });
                                setErrorShareHolder({ ...errorShareHolder, last_name: '' }); // Clear error when the user starts typing
                            }}
                        />
                        {errorShareHolder.last_name && <p className="text-danger mt-1">{errorShareHolder.last_name}</p>}
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">SIN</label>
                        <input
                            type="text"
                            name="sin"
                            id="sin"
                            className="form-input mt-2"
                            placeholder="Type here..."
                            value={showEditShareHolder?.sin} // Assuming 'registration_date' is the property to be displayed
                            onChange={(e) => {
                                setShowEditShareHolderData({ ...showEditShareHolder, sin: e.target.value });
                                setErrorShareHolder({ ...errorShareHolder, sin: '' }); // Clear error when the user starts typing
                            }}
                        />

                    </div>
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">Date of Birth</label>
                        <input
                            type="date"
                            name="date_of_birth"
                            id="date_of_birth"
                            className="form-input mt-2"
                            placeholder="Type here..."
                            value={showEditShareHolder?.date_of_birth} // Assuming 'registration_date' is the property to be displayed
                            onChange={(e) => setShowEditShareHolderData({ ...showEditShareHolder, date_of_birth: e.target.value })}

                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">Email Address</label>
                        <input
                            type="text"
                            name="email"
                            id="email"
                            className="form-input mt-2"
                            placeholder="Type here..."
                            value={showEditShareHolder?.email} // Assuming 'registration_date' is the property to be displayed
                            onChange={(e) => {
                                setShowEditShareHolderData({ ...showEditShareHolder, email: e.target.value });
                                setErrorShareHolder({ ...errorShareHolder, email: '' }); // Clear error when the user starts typing
                            }}
                        />
                        {errorShareHolder.email && <p className="text-danger mt-1">{errorShareHolder.email}</p>}
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">Phone No.</label>
                        <input
                            type="text"
                            name="phone_number"
                            id="phone_number"
                            className="form-input mt-2"
                            placeholder="Type here..."
                            value={showEditShareHolder?.phone_number} // Assuming 'registration_date' is the property to be displayed
                            onChange={(e) => setShowEditShareHolderData({ ...showEditShareHolder, phone_number: e.target.value })}

                        />
                    </div>
                    {/* <div className="col-lg-8 col-md-12 mt-4">
                        <label className="fs-18">Street Address</label>
                        <input
                            type="text"
                            name="address"
                            id="address"
                            className="form-input mt-2"
                            placeholder="Type here..."
                            value={showEditShareHolder?.address} // Assuming 'registration_date' is the property to be displayed
                            onChange={(e) => setShowEditShareHolderData({ ...showEditShareHolder, address: e.target.value })}

                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">City</label>
                        <input
                            type="text"
                            name="city"
                            id="city"
                            className="form-input mt-2"
                            placeholder="Type here..."
                            value={showEditShareHolder?.city} // Assuming 'registration_date' is the property to be displayed
                            onChange={(e) => setShowEditShareHolderData({ ...showEditShareHolder, city: e.target.value })}

                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">Province</label>
                        <input
                            type="text"
                            name="province"
                            id="province"
                            className="form-input mt-2"
                            placeholder="Type here..."
                            value={showEditShareHolder?.province} // Assuming 'registration_date' is the property to be displayed
                            onChange={(e) => setShowEditShareHolderData({ ...showEditShareHolder, province: e.target.value })}

                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">Postal Code</label>
                        <input
                            type="text"
                            name="postal_code"
                            id="postal_code"
                            className="form-input mt-2"
                            placeholder="Type here..."
                            value={showEditShareHolder?.postal_code} // Assuming 'registration_date' is the property to be displayed
                            onChange={(e) => setShowEditShareHolderData({ ...showEditShareHolder, postal_code: e.target.value })}

                        />
                    </div> */}
                    <div className="col-lg-8 col-md-12 mt-4">
                        <label className="fs-18">Street Address</label>
                        <PlacesAutocomplete
                            value={editAddressContact}
                            onChange={setEditContactAddress}
                            onSelect={handleShareHolderSelectAddress}
                            searchOptions={{
                                componentRestrictions: { country: ["us", "ca"] },
                            }}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <input
                                        {...getInputProps({
                                            placeholder: "Type address here...",
                                            className: "form-input mt-2 google-address",
                                        })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map((suggestion) => (
                                            <div key={suggestion.placeId} {...getSuggestionItemProps(suggestion)}>
                                                <span>{suggestion.description}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>

                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">City</label>
                        <input
                            type="text"
                            className="form-input mt-2"
                            value={editCity}
                            readOnly
                        />
                    </div>

                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">Province</label>
                        <input
                            type="text"
                            className="form-input mt-2"
                            value={editProvince}
                            readOnly
                        />
                    </div>

                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">Postal Code</label>
                        <input
                            type="text"
                            className="form-input mt-2"
                            value={editPostalCode}
                            readOnly
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4">
                        <label className="fs-18">Shareholder(%)</label>
                        <input
                            type="text"
                            name="shareholder_part"
                            id="shareholder_part"
                            className="form-input mt-2"
                            placeholder="Type here..."
                            value={showEditShareHolder?.shareholder_part} // Assuming 'registration_date' is the property to be displayed
                            onChange={(e) => setShowEditShareHolderData({ ...showEditShareHolder, shareholder_part: e.target.value })}

                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mt-3">
                        <label className="fs-18">Primary Contact Number</label>
                        <select
                            className="form-input mt-2"
                            name="primary_contact"
                            id="primary_contact"
                            // value={showEditShareHolder?.primary_contact || "0"} // Set value from state, default to "0" if undefined
                            // onChange={(e) => setShowEditDirData({ ...showEditShareHolder, primary_contact: e.target.value })}
                            value={showEditShareHolder?.primary_contact || "0"} // Use primary_contact from showEditDirData
                            onChange={(e) =>
                                setShowEditShareHolderData({ ...showEditShareHolder, primary_contact: e.target.value })
                            }
                        >
                            <option disabled>Select</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-3">
                        <label className="fs-18">Designation</label>
                        <select
                            className="form-input mt-2"
                            name="designation_id" // Update name to match structure
                            id="designation_id"
                            value={showEditDirData?.designation_id || ""}
                            onChange={(e) => setShowEditDirData({ ...showEditShareHolder, designation_id: e.target.value })}
                        >
                            <option selected disabled>Select</option>
                            {Array.isArray(designationListing) && designationListing.map((designation) => (

                                <option key={designation.id} value={designation.id}>
                                    {designation.name}
                                </option>
                            ))}
                        </select>
                    </div>


                </div>


                <div className="modal-footer border-0 px-0 pb-4 mt-4">
                    <a ><button className="btn-blue">Update</button></a>
                </div>

            </form>
        </div>
    </Modal>

{/* EDIT ASSIGN MODAL */}
    <Modal
        show={editAssignedModal}
        onHide={hideEditAssigendModal}
        centered
        className="modal operating-modal font-poppins"
        backdrop="static"
        keyboard={false}
    >

        <div className="modal-header p-3 border-0 justify-content-between">
            <h4 className="fs-20">Edit Assigned Member</h4>
            <Modal.Header closeButton className="close-icon border-0 p-0"></Modal.Header>
        </div>
        <div className="modal-body py-0">
            <form
                onSubmit={handleSubmit(updateAssignedSubmit)}
            >
                <div className="services-form row">
                    <div className="col-lg-12 mt-3 p-0">
                        <label className="fs-18">Account Manger</label>
                        <select
                            name="staff_id"
                            className="form-input mt-2"
                            id="staff_id"
                            {...register("staff_id", {

                            })}
                        >
                            <option value="0" disabled="disabled" selected>Select</option>
                            {allStaffApis.staff_id.length > 0 && (
                                allStaffApis.staff_id.map((staff) => (
                                    <option key={staff.id} value={staff.id}>
                                        {staff.name}
                                    </option>
                                ))
                            )}
                        </select>
                    </div>
                    <div className="col-lg-12 mt-3 p-0">
                        <label className="fs-18">Assistant</label>
                        <select
                            name="assistant_id"
                            className="form-input mt-2"
                            id="assistant_id" {...register("assistant_id", {

                            })}
                        >
                            <option value="0" disabled="disabled" selected>Select</option>

                            {allStaffApis.assistant_id.length > 0 && (
                                allStaffApis.assistant_id.map((staff) => (
                                    <option key={staff.id} value={staff.id}>
                                        {staff.name}
                                    </option>
                                ))
                            )}
                        </select>
                    </div>
                    <div className="col-lg-12 mt-3 p-0">
                        <label className="fs-18">Bookkeeper</label>
                        <select
                            name="bookkeeper_id"
                            className="form-input mt-2"
                            id="bookkeeper_id"
                            {...register("bookkeeper_id", {

                            })}
                        >
                            <option value="0" disabled="disabled" selected>Select</option>

                            {allStaffApis.bookkeeper_id.length > 0 && (
                                allStaffApis.bookkeeper_id.map((staff) => (
                                    <option key={staff.id} value={staff.id}>
                                        {staff.name}
                                    </option>
                                ))
                            )}
                        </select>
                    </div>
                    <div className="col-lg-12 mt-3 p-0">
                        <label className="fs-18">Communicator</label>
                        <select
                            name="communicator_id"
                            className="form-input mt-2"
                            id="communicator_id"
                            {...register("communicator_id", {

                            })}
                        >
                            <option value="" disabled="disabled" selected>Select</option>
                            {allStaffApis.communicator_id.length > 0 && (
                                allStaffApis.communicator_id.map((staff) => (
                                    <option key={staff.id} value={staff.id}>
                                        {staff.name}
                                    </option>
                                ))
                            )}
                        </select>
                    </div>

                    <div className="modal-footer border-0 px-0 pb-3 mt-2">
                        <button className="btn-blue">Update</button>
                    </div>

                </div>
            </form>
        </div>
    </Modal>

{/* SHOW CUSTOM FORM MODAL */}
    <Modal
        show={showCustomFormModal}
        onHide={() => setShowCustomFormModal(false)}
        centered
        size="xl"
        contentClassName="mx-auto"
        className="modal modal-lg email-modal"
        backdrop="static"
        keyboard={false}
    >
        <div className="modal-body position-relative">
            <div className="modal-head d-flex align-items-center justify-content-between gap-3 px-3 pt-3">
                <h4 className="fs-20 fw-semibold">Fill Required Forms</h4>
                <svg onClick={() => setShowCustomFormModal(false)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path id="circle-xmark" d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm3.707,14.293a1,1,0,1,1-1.414,1.414L12,13.414,9.707,15.707a1,1,0,0,1-1.414-1.414L10.586,12,8.293,9.707A1,1,0,0,1,9.707,8.293L12,10.586l2.293-2.293a1,1,0,0,1,1.414,1.414L13.414,12l2.293,2.293Z" fill="#212529" />
                </svg>
            </div>
            <form>
                <div className="tabs-section mt-2">
                    <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelect}>
                        <div className="p-2">
                            <TabList>
                                {currentTask?.links?.map((form, index) => (
                                    <Tab key={index}>
                                        {form.form_data?.length > 0 && form.form_data[0]?.title
                                            ? form.form_data[0].title
                                            : form.title || `Form ${index + 1}`}
                                    </Tab>
                                ))}
                            </TabList>
                        </div>


                        <div className="tabs-contentmain pb-3">
                            {currentTask?.links?.map((form, index) => (
                                <TabPanel key={index}>
                                    <div className="row p-0">
                                        {selectedForm?.id === form.id && surveyModels[index] && (
                                            <div className="survey-container">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger mt-3 del-survey"
                                                // onClick={() => deleteCustomForm()}
                                                >
                                                    Delete Survey Form
                                                </button>
                                                <Survey model={surveyModels[index]} onComplete={handleCompleteSurvey} />

                                            </div>
                                        )}
                                    </div>
                                </TabPanel>
                            ))}
                        </div>
                    </Tabs>

                </div>
            </form>
        </div >
    </Modal >

{/* EDIT CONTACT MODAL */}
    <Modal
        show={editContactModal}
        onHide={hideEditContactModal}
        centered
        size="xl"
        contentClassName="mx-auto"
        className="modal operating-modal font-poppins"
        backdrop="static"
        keyboard={false}
    >
        <div className="modal-header border-0 justify-content-between px-4 pt-4 pb-0">
            <h4 className="fs-20">Edit Contact</h4>
            <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
        </div>
        <div className="modal-body px-3 pt-0 pb-3">
            <form onSubmit={handleSubmit(onSubmitContact)}>
                <div className="advance-form row">
                    {selectContact?.client_type === "1" ? (
                        <>
                            <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                                <label className="fs-18">First Name</label>
                                <input
                                    type="text"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    {...register("first_name")}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                                <label className="fs-18">Last Name</label>
                                <input
                                    type="text"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    {...register("last_name")}
                                />
                            </div>
                        </>
                    ) : (
                        <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                            <label className="fs-18">Client Name  <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className="form-input mt-2"
                                placeholder="Type here..."
                                {...register("company_name")}
                            />
                        </div>
                    )}
                    <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                        <label className="fs-18">Designation</label>
                        <select {...register("designation_id")} className="form-input mx-0 mt-1">
                            <option disabled>Select</option>
                            {designationList &&
                                designationList.map((designation) => (
                                    <option key={designation.id} value={designation.id}>
                                        {designation.name}
                                    </option>
                                ))}
                        </select>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                        <label className="fs-18">Phone No.</label>
                        <input
                            type="tel"
                            className="form-input mt-2"
                            placeholder="Phone No."
                            {...register("phone_number", {
                                required: "Required",
                                pattern: {
                                    value: /^[0-9]*$/,
                                    message: "Please enter only numeric characters",
                                },
                                minLength: {
                                    value: 7,
                                    message: "Phone number must be at least 7 digits long",
                                },
                            })}
                        />
                    </div>
                    {selectContact?.client_type === "1" && (
                        <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                            <label className="fs-18">Email Address</label>
                            <input
                                type="email"

                                className="form-input mt-2"
                                {...register("email")}

                                onChange={(e) => setEmail(e.target.value)} // Update email state
                            />
                        </div>
                    )}
                    <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                        <label className="fs-18">Primary Contact</label>
                        <select
                            className="form-input mt-2"
                            {...register("primary_contact")}
                            value={primaryContact || "0"}
                            onChange={(e) => setPrimaryContact(e.target.value)} // Update primaryContact state
                        >
                            <option disabled>Select</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </div>
                    <div className="col-lg-8 col-md-12 col-md-12 mt-4">
                        <label className="fs-18">Street Address</label>
                        <PlacesAutocomplete
                            value={addressContact}
                            onChange={setContactAddress}
                            onSelect={handleSelectAddress}
                            searchOptions={{
                                componentRestrictions: { country: ["us", "ca"] },
                            }}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <input
                                        {...getInputProps({ className: "form-input mt-2 google-address" })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map((suggestion) => (
                                            <div key={suggestion.placeId} {...getSuggestionItemProps(suggestion)}>
                                                <span>{suggestion.description}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                    <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                        <label className="fs-18">Province</label>
                        <input
                            className="form-input mt-2"
                            value={province}
                            readOnly
                            {...register("province")}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                        <label className="fs-18">Postal Code</label>
                        <input
                            type="text"
                            className="form-input mt-2"
                            value={postalCode}
                            readOnly
                            {...register("postal_code")}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                        <label className="fs-18">City</label>
                        <input
                            className="form-input mt-2"
                            value={city}
                            readOnly
                            {...register("city")}
                        />
                    </div>
                    <div className="col-md-12 mt-4">
                        <label className="fs-18">Note</label>
                        <textarea className="mt-2" rows="5" {...register("notes")} />
                    </div>
                </div>
                <div className="modal-footer border-0 px-2 pt-4 pb-2">
                    <button type="submit" className="btn-blue px-5 py-4">Save Changes</button>
                </div>
            </form>
        </div>
    </Modal>
{/* DIRECTOR DETAIL MODAL START */}
    <Modal
        show={linkProfileModal}
        onHide={handleCloseModal}
        centered
        size="lg"
        contentClassName="mx-auto p-4 h-auto"
        className="modal pin-modal font-poppins"
        backdrop="static"
        keyboard={false}
    >
        <div className="modal-header p-0 justify-content-between align-items-center border-0">
            <h5 className="fs-22 fw-bold">Executive Details</h5>
            <div className="d-flex align-items-center gap-3">
                <div className="cursor-pointer" onClick={(e) => {
                    e.stopPropagation(); // Prevents triggering any parent click events
                    handleEditDirectorClick(selectedDirector?.corporate_id, selectedDirector?.id);
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 14 13.999">
                        <g id="write" transform="translate(-7.998 -8)">
                            <path id="Path_3528" data-name="Path 3528" d="M8.3,26.038,8,28.469a.731.731,0,0,0,.728.827.718.718,0,0,0,.093-.006L11.257,29a2.317,2.317,0,0,0,1.35-.663l6.3-6.3a.292.292,0,0,0,0-.412l-3.232-3.235a.292.292,0,0,0-.412,0l-6.3,6.3a2.317,2.317,0,0,0-.663,1.35Z" transform="translate(0 -7.297)" fill="#000000" />
                            <path id="Path_3529" data-name="Path 3529" d="M41.979,9.951,40.472,8.443a1.512,1.512,0,0,0-2.138,0L36.918,9.859a.292.292,0,0,0,0,.412L40.151,13.5a.292.292,0,0,0,.412,0l1.416-1.416A1.512,1.512,0,0,0,41.979,9.951Z" transform="translate(-20.425 0)" fill="#000000" />
                            <path id="Path_3530" data-name="Path 3530" d="M36.875,52.583A.583.583,0,0,0,36.291,52H29.583a.583.583,0,0,0,0,1.167h6.708A.583.583,0,0,0,36.875,52.583Z" transform="translate(-14.877 -31.168)" fill="#000000" />
                        </g>
                    </svg>
                </div>
                <div className="cursor-pointer" onClick={(e) => {
                    e.stopPropagation(); // Prevents triggering any parent click events
                    deleteDirector(); // Call the delete function when the icon is clicked
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 12.598 13.998">
                        <path id="Path_3741" data-name="Path 3741" d="M411.1,1337.268a.46.46,0,0,1,.193.042.5.5,0,0,1,.163.112.517.517,0,0,1,.107.168.5.5,0,0,1,.036.2v.266a.512.512,0,0,1-.144.357.491.491,0,0,1-.355.154H399.5a.49.49,0,0,1-.355-.154.51.51,0,0,1-.145-.357v-.266a.514.514,0,0,1,.143-.364.5.5,0,0,1,.163-.112.461.461,0,0,1,.193-.042h2.041a.865.865,0,0,0,.559-.2.892.892,0,0,0,.309-.5l.106-.476a1.391,1.391,0,0,1,.482-.777,1.412,1.412,0,0,1,.857-.308h2.891a1.418,1.418,0,0,1,.843.3,1.391,1.391,0,0,1,.486.749l.114.511a.9.9,0,0,0,.309.511.948.948,0,0,0,.559.2Zm-1.037,9.722c.21-1.981.586-6.7.586-6.747a.527.527,0,0,0-.023-.21.58.58,0,0,0-.1-.182.528.528,0,0,0-.166-.119.49.49,0,0,0-.2-.042h-9.708a.5.5,0,0,0-.2.042.52.52,0,0,0-.166.119.566.566,0,0,0-.133.392c0,.007.015.175.037.455.1,1.232.376,4.668.555,6.3a2.033,2.033,0,0,0,2.051,1.974c.879.021,1.784.028,2.71.028.872,0,1.757-.007,2.66-.028a2.017,2.017,0,0,0,1.459-.553,2.039,2.039,0,0,0,.64-1.428Z" transform="translate(-399 -1335)" fill="#000" fill-rule="evenodd" />
                    </svg>
                </div>
                <Modal.Header closeButton className="close-icon border-0 p-0"></Modal.Header>
            </div>
        </div>

        <hr className="mt-3 mb-1" />

        <div className="services-form row">
            <div className="col-lg-12 p-0 mt-3">
                <div className="lhead d-flex align-items-center gap-3">
                    <img src={require("../../../assets/images/userman.jpg")} className="rounded-circle" alt="" />
                    <div>
                        <h6 className="fs-18 fw-semibold">{selectedDirector?.first_name} {selectedDirector?.last_name}</h6>
                        <span className="d-flex align-items-center gap-2">
                            <img src={require("../../../assets/images/email.png")} className="emailicon" alt="" />
                            <p className="fs-14 text-muted">{selectedDirector?.email}</p>
                        </span>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 p-0 mt-3">
                <p className="fs-14">Full Name:</p>
                <h4 className="fs-18 fw-semibold">{selectedDirector?.first_name} {selectedDirector?.middle_name} {selectedDirector?.last_name}</h4>
            </div>

            <div className="col-lg-6 p-0 mt-3">
                <p className="fs-14">
                    SIN
                    {isDirectorSinVisible ? (
                        <img
                            src={require("../../../assets/images/eye-hide.png")}
                            className="cursor-pointer ms-2 w-14"
                            alt="Hide SIN"
                            onClick={toggleDirectorSinVisibility} // On click, hide the SIN
                        />
                    ) : (
                        <img
                            src={require("../../../assets/images/eye.png")}
                            className="cursor-pointer ms-2 w-14"
                            alt="Show SIN"
                            onClick={toggleDirectorSinVisibility} // On click, show the SIN
                        />
                    )}
                </p>

                <h4 className="fs-18 fw-semibold">
                    {selectedDirector?.sin
                        ? isDirectorSinVisible
                            ? selectedDirector.sin // Show full SIN if visible
                            : selectedDirector.sin.replace(/\d(?=\d{2})/g, "*") // Mask SIN except last 2 digits
                        : ""}
                </h4>
            </div>
            <div className="col-lg-6 p-0 mt-3">
                <p className="fs-14">Date of Birth:</p>
                <h4 className="fs-18 fw-semibold">{selectedDirector?.date_of_birth}</h4>
            </div>
            <div className="col-lg-6 p-0 mt-3">
                <p className="fs-14">Email:</p>
                <h4 className="fs-18 fw-semibold">{selectedDirector?.email}</h4>
            </div>
            <div className="col-lg-6 p-0 mt-3">
                <p className="fs-14">Phone Number:</p>
                <h4 className="fs-18 fw-semibold">{selectedDirector?.phone_number}</h4>
            </div>
            <div className="col-lg-6 p-0 mt-3">
                <p className="fs-14">Address:</p>
                <h4 className="fs-18 fw-semibold">{selectedDirector?.address}</h4>
            </div>
            <div className="col-lg-6 p-0 mt-3">
                <p className="fs-14">City:</p>
                <h4 className="fs-18 fw-semibold">{selectedDirector?.city}</h4>
            </div>
            <div className="col-lg-6 p-0 mt-3">
                <p className="fs-14">Province:</p>
                <h4 className="fs-18 fw-semibold">{selectedDirector?.province}</h4>
            </div>
            <div className="col-lg-6 p-0 mt-3">
                <p className="fs-14">Postal Code:</p>
                <h4 className="fs-18 fw-semibold">{selectedDirector?.postal_code}</h4>
            </div>
            <div className="col-lg-6 p-0 mt-3">
                <p className="fs-14">Shareholder(%): </p>
                <h4 className="fs-18 fw-semibold">{selectedDirector?.shareholder_part}%</h4>
            </div>
            <div className="d-flex align-items-center justify-content-between gap-3 flex-wrap p-0 mt-3">
                <button type="button" className="btn btn-gray mt-2">Go To Profile</button>
                <button type="button" className="add-new mt-2" onClick={() => updatePrimaryData(selectedDirector.corporate_id, selectedDirector.id)}>Set as Contact</button>
            </div>
        </div>
    </Modal>
{/* DIRECTOR DETAIL MODAL END */}

{/* LINK SHAREHOLDER MODAL */}
    <Modal
        show={linkShareHolderModal}
        onHide={handleCloseShareHolderModal}
        centered
        size="lg"
        contentClassName="mx-auto p-4 h-auto"
        className="modal pin-modal font-poppins"
        backdrop="static"
        keyboard={false}
    >
        <div className="modal-header p-0 justify-content-between align-items-center border-0">
            <h5 className="fs-22 fw-bold">ShareHolder Details</h5>
            <div className="d-flex align-items-center gap-2">
                <div className="editicon cursor-pointer"
                    onClick={(e) => {
                        e.stopPropagation(); // Prevents triggering any parent click events
                        handleEditShareHolderClick(selectedShareHolder?.corporate_id, selectedShareHolder?.id);
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.999" viewBox="0 0 14 13.999">
                        <g id="write" transform="translate(-7.998 -8)">
                            <path id="Path_3528" data-name="Path 3528" d="M8.3,26.038,8,28.469a.731.731,0,0,0,.728.827.718.718,0,0,0,.093-.006L11.257,29a2.317,2.317,0,0,0,1.35-.663l6.3-6.3a.292.292,0,0,0,0-.412l-3.232-3.235a.292.292,0,0,0-.412,0l-6.3,6.3a2.317,2.317,0,0,0-.663,1.35Z" transform="translate(0 -7.297)" fill="green" />
                            <path id="Path_3529" data-name="Path 3529" d="M41.979,9.951,40.472,8.443a1.512,1.512,0,0,0-2.138,0L36.918,9.859a.292.292,0,0,0,0,.412L40.151,13.5a.292.292,0,0,0,.412,0l1.416-1.416A1.512,1.512,0,0,0,41.979,9.951Z" transform="translate(-20.425 0)" fill="green" />
                            <path id="Path_3530" data-name="Path 3530" d="M36.875,52.583A.583.583,0,0,0,36.291,52H29.583a.583.583,0,0,0,0,1.167h6.708A.583.583,0,0,0,36.875,52.583Z" transform="translate(-14.877 -31.168)" fill="green" />
                        </g>
                    </svg>
                </div>
                <div className="deticon cursor-pointer two"
                    onClick={(e) => {
                        e.stopPropagation();
                        deleteShareHolder();
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="12.598" height="13.998" viewBox="0 0 12.598 13.998">
                        <path id="Path_3741" data-name="Path 3741" d="M411.1,1337.268a.46.46,0,0,1,.193.042.5.5,0,0,1,.163.112.517.517,0,0,1,.107.168.5.5,0,0,1,.036.2v.266a.512.512,0,0,1-.144.357.491.491,0,0,1-.355.154H399.5a.49.49,0,0,1-.355-.154.51.51,0,0,1-.145-.357v-.266a.514.514,0,0,1,.143-.364.5.5,0,0,1,.163-.112.461.461,0,0,1,.193-.042h2.041a.865.865,0,0,0,.559-.2.892.892,0,0,0,.309-.5l.106-.476a1.391,1.391,0,0,1,.482-.777,1.412,1.412,0,0,1,.857-.308h2.891a1.418,1.418,0,0,1,.843.3,1.391,1.391,0,0,1,.486.749l.114.511a.9.9,0,0,0,.309.511.948.948,0,0,0,.559.2Zm-1.037,9.722c.21-1.981.586-6.7.586-6.747a.527.527,0,0,0-.023-.21.58.58,0,0,0-.1-.182.528.528,0,0,0-.166-.119.49.49,0,0,0-.2-.042h-9.708a.5.5,0,0,0-.2.042.52.52,0,0,0-.166.119.566.566,0,0,0-.133.392c0,.007.015.175.037.455.1,1.232.376,4.668.555,6.3a2.033,2.033,0,0,0,2.051,1.974c.879.021,1.784.028,2.71.028.872,0,1.757-.007,2.66-.028a2.017,2.017,0,0,0,1.459-.553,2.039,2.039,0,0,0,.64-1.428Z" transform="translate(-399 -1335)" fill="red" fill-rule="evenodd" />
                    </svg>
                </div>
                <Modal.Header closeButton className="close-icon border-0 p-0"></Modal.Header>
            </div>
        </div>

        <hr className="mt-3 mb-1" />

        <div className="services-form row">
            <div className="col-lg-12 p-0 mt-3">
                <div className="lhead d-flex align-items-center gap-3">
                    <img src={require("../../../assets/images/userman.jpg")} className="rounded-circle" alt="" />
                    <div>
                        <h6 className="fs-18 fw-semibold">{selectedShareHolder?.first_name} {selectedShareHolder?.last_name}</h6>
                        <span className="d-flex align-items-center gap-2">
                            <img src={require("../../../assets/images/email.png")} className="emailicon" alt="" />
                            <p className="fs-14 text-muted">{selectedShareHolder?.email}</p>
                        </span>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 p-0 mt-3">
                <p className="fs-14">Full Name:</p>
                <h4 className="fs-18 fw-semibold">{selectedShareHolder?.first_name} {selectedShareHolder?.middle_name} {selectedShareHolder?.last_name}</h4>
            </div>

            <div className="col-lg-6 p-0 mt-3">
                <p className="fs-14">
                    SIN
                    {isShareHolderSinVisiable ? (
                        <img
                            src={require("../../../assets/images/eye-hide.png")}
                            className="cursor-pointer ms-2 w-14"
                            alt="Hide SIN"
                            onClick={toggleShareHolderSinVisibility} // On click, hide the SIN
                        />
                    ) : (
                        <img
                            src={require("../../../assets/images/eye.png")}
                            className="cursor-pointer ms-2 w-14"
                            alt="Show SIN"
                            onClick={toggleShareHolderSinVisibility} // On click, show the SIN
                        />
                    )}
                </p>

                <h4 className="fs-18 fw-semibold">
                    {selectedShareHolder?.sin
                        ? isShareHolderSinVisiable
                            ? selectedShareHolder.sin // Show full SIN if visible
                            : selectedShareHolder.sin.replace(/\d(?=\d{2})/g, "*") // Mask SIN except last 2 digits
                        : ""}
                </h4>
            </div>
            <div className="col-lg-6 p-0 mt-3">
                <p className="fs-14">Date of Birth:</p>
                <h4 className="fs-18 fw-semibold">{selectedShareHolder?.date_of_birth}</h4>
            </div>
            <div className="col-lg-6 p-0 mt-3">
                <p className="fs-14">Email:</p>
                <h4 className="fs-18 fw-semibold">{selectedShareHolder?.email}</h4>
            </div>
            <div className="col-lg-6 p-0 mt-3">
                <p className="fs-14">Phone Number:</p>
                <h4 className="fs-18 fw-semibold">{selectedShareHolder?.phone_number}</h4>
            </div>
            <div className="col-lg-6 p-0 mt-3">
                <p className="fs-14">Address:</p>
                <h4 className="fs-18 fw-semibold">{selectedShareHolder?.address}</h4>
            </div>
            <div className="col-lg-6 p-0 mt-3">
                <p className="fs-14">City:</p>
                <h4 className="fs-18 fw-semibold">{selectedShareHolder?.city}</h4>
            </div>
            <div className="col-lg-6 p-0 mt-3">
                <p className="fs-14">Province:</p>
                <h4 className="fs-18 fw-semibold">{selectedShareHolder?.province}</h4>
            </div>
            <div className="col-lg-6 p-0 mt-3">
                <p className="fs-14">Postal Code:</p>
                <h4 className="fs-18 fw-semibold">{selectedShareHolder?.postal_code}</h4>
            </div>
            <div className="col-lg-6 p-0 mt-3">
                <p className="fs-14">Shareholder(%): </p>
                <h4 className="fs-18 fw-semibold">{selectedShareHolder?.shareholder_part}%</h4>
            </div>
            <div className="d-flex align-items-center justify-content-between gap-3 flex-wrap p-0 mt-3">
                <button type="button" className="btn btn-gray mt-2">Go To Profile</button>
                <button type="button" className="add-new mt-2"
                    onClick={() => updateShareHolderPrimaryData(selectedShareHolder.corporate_id, selectedShareHolder.id)}
                >Set as Contact</button>
            </div>
        </div>
    </Modal>

{/* LINK CONTACT MODAL */}
    <Modal
        show={linkContactModal}
        onHide={handleCloseContactModal}
        centered
        size="lg"
        contentClassName="mx-auto p-4 h-auto"
        className="modal pin-modal font-poppins"
        backdrop="static"
        keyboard={false}
    >
        <div className="modal-header p-0 justify-content-between align-items-center border-0">
            <div className="lhead d-flex align-items-center gap-3">
                <img src={require("../../../assets/images/userman.jpg")} className="rounded-circle" alt="" />
                <div>
                    <h6 className="fs-18 fw-semibold">
                        {selectContact?.first_name} {selectContact?.last_name}
                        {selectContact?.primary_contact === "1" && (
                            <span className="fs-12 text-blue"> Primary</span>
                        )}
                    </h6>

                    <span className="d-flex align-items-center gap-2">
                        <p className="fs-14 text-muted">{selectContact?.designation?.name}</p>
                    </span>
                </div>
            </div>
            <div className="d-flex align-items-center gap-3">
                <div className="cursor-pointer"
                    onClick={() => handleContactClick(selectContact?.id)}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="23.999" height="23.998" viewBox="0 0 23.999 23.998">
                        <g id="write" transform="translate(-7.998 -8)">
                            <path id="Path_3528" data-name="Path 3528" d="M8.515,31.625l-.506,4.187a1.259,1.259,0,0,0,1.254,1.424,1.237,1.237,0,0,0,.16-.01l4.187-.506a3.99,3.99,0,0,0,2.325-1.142L26.784,24.73a.5.5,0,0,0,0-.71l-5.566-5.571a.5.5,0,0,0-.71,0L9.657,29.3a3.99,3.99,0,0,0-1.142,2.325Z" transform="translate(0 -5.238)" fill="#202529" />
                            <path id="Path_3529" data-name="Path 3529" d="M45.7,11.36l-2.6-2.6a2.6,2.6,0,0,0-3.683,0L36.979,11.2a.5.5,0,0,0,0,.71l5.569,5.569a.5.5,0,0,0,.71,0L45.7,15.042A2.6,2.6,0,0,0,45.7,11.36Z" transform="translate(-14.463 0)" fill="#202529" />
                            <path id="Path_3530" data-name="Path 3530" d="M42.561,53a1,1,0,0,0-1-1H30a1,1,0,0,0,0,2.009H41.557A1,1,0,0,0,42.561,53Z" transform="translate(-10.564 -22.011)" fill="#202529" />
                        </g>
                    </svg>

                </div>
                <div className="deticon cursor-pointer three"
                    onClick={(e) => {
                        e.stopPropagation();
                        deleteContact(selectContact?.id);
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="12.598" height="13.998" viewBox="0 0 12.598 13.998">
                        <path id="Path_3741" data-name="Path 3741" d="M411.1,1337.268a.46.46,0,0,1,.193.042.5.5,0,0,1,.163.112.517.517,0,0,1,.107.168.5.5,0,0,1,.036.2v.266a.512.512,0,0,1-.144.357.491.491,0,0,1-.355.154H399.5a.49.49,0,0,1-.355-.154.51.51,0,0,1-.145-.357v-.266a.514.514,0,0,1,.143-.364.5.5,0,0,1,.163-.112.461.461,0,0,1,.193-.042h2.041a.865.865,0,0,0,.559-.2.892.892,0,0,0,.309-.5l.106-.476a1.391,1.391,0,0,1,.482-.777,1.412,1.412,0,0,1,.857-.308h2.891a1.418,1.418,0,0,1,.843.3,1.391,1.391,0,0,1,.486.749l.114.511a.9.9,0,0,0,.309.511.948.948,0,0,0,.559.2Zm-1.037,9.722c.21-1.981.586-6.7.586-6.747a.527.527,0,0,0-.023-.21.58.58,0,0,0-.1-.182.528.528,0,0,0-.166-.119.49.49,0,0,0-.2-.042h-9.708a.5.5,0,0,0-.2.042.52.52,0,0,0-.166.119.566.566,0,0,0-.133.392c0,.007.015.175.037.455.1,1.232.376,4.668.555,6.3a2.033,2.033,0,0,0,2.051,1.974c.879.021,1.784.028,2.71.028.872,0,1.757-.007,2.66-.028a2.017,2.017,0,0,0,1.459-.553,2.039,2.039,0,0,0,.64-1.428Z" transform="translate(-399 -1335)" fill="red" fill-rule="evenodd" />
                    </svg>
                </div>
                <Modal.Header closeButton className="close-icon border-0 p-0"></Modal.Header>
            </div>
        </div>

        <hr className="mt-3 mb-1" />

        <div className="services-form row">

            <div className="col-lg-4 p-0 mt-3">
                <p className="fs-14">First Name</p>
                <h4 className="fs-18 fw-semibold">{selectContact?.first_name}</h4>
            </div>
            <div className="col-lg-4 p-0 mt-3">
                <p className="fs-14">Last Name</p>
                <h4 className="fs-18 fw-semibold">{selectContact?.last_name}</h4>
            </div>
            <div className="col-lg-4 p-0 mt-3">
                <p className="fs-14">Email</p>
                <h4 className="fs-18 fw-semibold">{selectContact?.email}</h4>
            </div>
            <div className="col-lg-4 p-0 mt-3">
                <p className="fs-14">Phone No.</p>
                <h4 className="fs-18 fw-semibold">{selectContact?.phone_number}</h4>
            </div>
            <div className="col-lg-4 p-0 mt-3">
                <p className="fs-14">Primary Contact</p>
                <h4 className="fs-18 fw-semibold">{selectContact?.primary_contact === "1" ? "Yes" : "No"}</h4>
            </div>
            <div className="col-lg-12 p-0 mt-3">
                <p className="fs-14">Note</p>
                <h4 className="fs-18 fw-semibold">{selectContact?.notes} </h4>
            </div>
            <hr className="mt-3" />
            <div className="col-lg-4 p-0 mt-3">
                <p className="fs-14">Street Address </p>
                <h4 className="fs-18 fw-semibold">{selectContact?.address}</h4>
            </div>
            <div className="col-lg-4 p-0 mt-3">
                <p className="fs-14">City </p>
                <h4 className="fs-18 fw-semibold">{selectContact?.city}</h4>
            </div>
            <div className="col-lg-4 p-0 mt-3">
                <p className="fs-14">Province </p>
                <h4 className="fs-18 fw-semibold">{selectContact?.province}</h4>
            </div>
            <div className="col-lg-4 p-0 mt-3">
                <p className="fs-14">Postal Code </p>
                <h4 className="fs-18 fw-semibold">{selectContact?.postal_code}</h4>
            </div>

        </div>
    </Modal>
            <Operation_Detail
                operationModal={operationModal}
                setOperationModal={setOperationModal}
                fetchData={fetchData}
            />
            <Advance_Details
                advanceModal={advanceModal}
                setAdvanceModal={setAdvanceModal}
                showHsBusinessTypeList={showHsBusinessTypeList}
                businessList={businessList}
                setBusinessList={setBusinessList}
                businessNatureList={businessNatureList}
                setBusinessNatureList={setBusinessNatureList}
                showHsBusinessNatureList={showHsBusinessNatureList}
                businesCateList={businesCateList}
                setBusinessCateList={setBusinessCateList}
                showBusinessCateList={showBusinessCateList}
                showBusinessEnteryList={showBusinessEnteryList}
                businessEnterStyleList={businessEnterStyleList}
                setBusinessEnteryStyleList={setBusinessEnteryStyleList}
                setBusinessPlaningList={setBusinessPlaningList}
                businessplaningList={businessplaningList}
                showBusinessPlaningList={showBusinessPlaningList}
                fetchAdvData={fetchAdvData}
            />
            <Directors_Details
                directorModal={directorModal}
                setDirectorModal={setDirectorModal}
                fetchDirData={fetchDirData}
            />
            <Add_ShareHolder
                shareHolderModal={shareHolderModal}
                setShareHolderModal={setShareHolderModal}
                fetchShareHolderData={fetchShareHolderData}
            />
            <Add_Contact
                contactModal={contactModal}
                setContactModal={setContactModal}
                fetchContactData={fetchContactData}
            />
            <Services
                servicesModal={servicesModal}
                setServicesModal={setServicesModal}
                hstList={hstList}
                setHstList={setHstList}
                showHstList={showHstList}
                hstStyleList={hstStyleList}
                setHstStyleList={setHstStyleList}
                showHstStyle={showHstStyle}
                showArsetupList={showArsetupList}
                arcSetupList={arcSetupList}
                showHstOpenList={showHstOpenList}
                hstOpenList={hstOpenList}
                showPayRollList={showPayRollList}
                payRollList={payRollList}
                showArcAccessList={showArcAccessList}
                ArcAccessList={ArcAccessList}
                showAdvanceList={showAdvanceList}
                AdvanceFeeList={AdvanceFeeList}
                showAgreementList={showAgreementList}
                AgreemetList={AgreemetList}
                showArcGridList={showArcGridList}
                arcGridList={arcGridList}
                showAgreementReasonList={showAgreementReasonList}
                AgreementReasonList={AgreementReasonList}
                fetchSerData={fetchSerData}
            />
            <Assigent_Member
                assigentModal={assigentModal}
                setAssigentModal={setAssigentModal}
                allStaffApis={allStaffApis}
                setAllStaffApi={setAllStaffApi}
                setStaffList={setStaffList}
                staffList={staffList}
                fetchStaffData={fetchStaffData}
                assignedListNew={assignedListNew}
                setAssignedListNew={setAssignedListNew}
                fetchAssisgnedData={fetchAssisgnedData}
                setBookeeperList={setBookeeperList}
                bookeeperList={bookeeperList}
                fetchBookeeperData={fetchBookeeperData}
                communiList={communiList}
                setCommuniList={setCommuniList}
                fetchCommuniData={fetchCommuniData}
                fetchAsiData={fetchAsiData}
            />
            <Upload_Files
                uploadModal={uploadModal}
                setUploadModal={setUploadModal}
            />
        </>
    )
}
export default BusClientProfie;